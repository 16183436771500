import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns"; // Import date-fns for date formatting
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import Popup from "./GlobalAiUpdatePopup"; // Import the Popup component
import "../styles/globalAiNewsDetail.css"; // Ensure this file contains the required styles
import { ClipLoader, DotLoader } from "react-spinners"; // Import the loader spinner
import { FaEdit } from "react-icons/fa"; // Import the edit icon from react-icons
import { useSelector } from "react-redux";

const ArticleDetail = () => {
  const { id } = useParams(); // Get the article ID from the URL
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImagePreloaded, setIsImagePreloaded] = useState(false); // State to track image preloading
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to handle popup visibility
  const [authorizedUser, setAuthorizedUser] = useState(false);
  const username = useSelector((state) => state.user.userName);

  useEffect(() => {
    checkUserAuthorization();
  }, []);

  const checkUserAuthorization = () => {
    const authorizedUsers = ["hanishols"]; // List of authorized users
    if (authorizedUsers.includes(username)) {
      setAuthorizedUser(true); // Set authorized flag if the username is in the list
    }
  };

  useEffect(() => {
    const fetchArticle = async () => {
      setIsLoading(true);
      try {
        const response = await apiService.get(
          `${baseUrl}/ai/getSpecificAiNews/${id}`
        );
        console.log("response news: ", response);
        setArticle(response);

        // Preload the image
        const img = new Image();
        img.src = response.image;
        img.onload = () => {
          setIsImagePreloaded(true); // Set state to true when the image is fully loaded
        };
      } catch (error) {
        console.error("Failed to fetch article details", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleEdit = () => {
    setIsPopupOpen(true); // Open the popup for editing
  };

  const handleSave = (updatedArticle) => {
    setArticle(updatedArticle); // Update the article with new data
  };

  return (
    <div className="news-container">
      <header className="news-header">
        <h1 className="header-title">Global AI News</h1>
        <p className="header-subtitle">Model for generating text to image</p>
      </header>

      {isLoading ? (
        <div className="loader-container1">
          <ClipLoader size={50} color={"#6cd97e"} loading={isLoading} />
        </div>
      ) : !article ? (
        <div className="article-not-found">Article not found.</div>
      ) : (
        <>
          {authorizedUser && (
            <div className="admin-buttons">
              <button onClick={handleEdit} className="admin-button add-button">
                <FaEdit style={{ marginRight: "5px" }} />
                Edit
              </button>
            </div>
          )}
          {/* Edit button with icon */}
          <div className="article-content-container">
            {/* Format and display the article creation date without the time */}
            <div className="imhh">
              {!isImagePreloaded ? (
                <div className="image-loader1">
                  <DotLoader size={50} color={"#6cd97e"} />
                </div>
              ) : (
                <img
                  src={article.image}
                  alt={article.title}
                  className="article-image"
                />
              )}
            </div>
            <h1 className="article-title">{article.title}</h1>

            <div
              className="article-content"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </div>
        </>
      )}
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSave={handleSave}
        article={article} // Pass the article data to the popup
      />
    </div>
  );
};

export default ArticleDetail;
