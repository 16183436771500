import React, { useEffect, useState } from "react";
import "../styles/interviewAI.css";
import axios from "axios";
import baseUrl from "../baseUrl";
import { PropagateLoader } from "react-spinners";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import apiService from "../services/apiService";

const LoadingSpinner = () => (
  <div className="modal-overlay">
    <div className="modal">
      <p className="juhyj">Generating</p>
      <div className="spinner-container">
        <PropagateLoader color="#4caf50" />
      </div>
      <p className="ngvfgc">Preparing your interview</p>
    </div>
  </div>
);

const InterviewAi = () => {
  const [selectedJob, setSelectedJob] = useState("Custom Job Description"); // State to store the selected job
  const [customDescription, setCustomDescription] = useState(""); // State to store the custom description
  const [responseFromServer, setResponseFromServer] = useState(""); // State to store the response from the server
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const navigate = useNavigate(); // Access navigate function for navigation

  useEffect(() => {
    // Set initial custom description when component mounts
    generateCustomDescription("Custom Job Description");
  }, []);

  // Function to generate custom description based on selected job
  const generateCustomDescription = (job) => {
    // Use switch statement to generate custom description based on selected job
    switch (job) {
      case "Custom Job Description":
        setCustomDescription(`This is a custom job description.`);
        break;
      case "Business Analyst":
        setCustomDescription(
          `Job Title: Business Analyst\n\nRole Summary: As a Business Analyst, you will be responsible for analyzing business processes and identifying opportunities for improvement. You will work closely with stakeholders to gather requirements and translate them into functional specifications.\n\nResponsibilities:\n- Gather and document business requirements.\n- Analyze and document business processes.\n- Identify areas for process improvement.\n- Work with stakeholders to define project scope and objectives.\n- Create functional specifications.\n- Facilitate communication between technical and non-technical stakeholders.\n\nRequirements:\n- Bachelor's degree in Business Administration, Computer Science, or related field.\n- 2+ years of experience as a Business Analyst.\n- Strong analytical and problem-solving skills.\n- Excellent communication and interpersonal skills.`
        );
        break;
      case "Product Manager":
        setCustomDescription(
          `Job Title: Product Manager\n\nRole Summary: We are seeking an enthusiastic Product Manager to join our team. This is an excellent opportunity for individuals at the early stage of their career to contribute to the full product life cycle and work on products that reach users worldwide.\n\nResponsibilities:\n- Manage the entire product line life cycle from strategic planning to tactical activities.\n- Work closely with multiple teams to define product requirements.\n- Develop product roadmaps to meet business targets.\n- Analyze market trends and competition.\n- Conduct usability studies and perform user research to understand user needs.\n- Prioritize features and tasks for product development based on business and customer impact.\n- Work with the sales and marketing teams to define the go-to-market strategy.\n\nRequirements:\n- Bachelor's degree in Business, Engineering, Computer Science, or related field.\n- 0-3 years of experience in product management or a related field.\n- Strong problem-solving skills and willingness to roll up one's sleeves to get the job done.\n- Excellent written and verbal communication skills.\n- Skilled at working effectively with cross-functional teams in a matrix organization.\n- Proficiency in web analytics tools and experience in data-driven decision making.`
        );
        break;
      case "Software Engineer":
        setCustomDescription(
          `Job Title: Software Engineer\n\nRole Summary: As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with the development team to deliver high-quality software solutions.\n\nResponsibilities:\n- Design, develop, and maintain software applications.\n- Write clean, efficient, and maintainable code.\n- Collaborate with cross-functional teams to define and implement software requirements.\n- Perform code reviews and provide feedback to other team members.\n- Troubleshoot and debug software issues.\n- Stay up-to-date with the latest technologies and best practices.\n\nRequirements:\n- Bachelor's degree in Computer Science, Engineering, or related field.\n- 2+ years of experience in software development.\n- Proficiency in one or more programming languages (e.g., Java, Python, JavaScript).\n- Strong problem-solving skills.\n- Excellent communication and teamwork abilities.\n- Experience with version control systems (e.g., Git).`
        );
        break;
      case "Marketing Specialist":
        setCustomDescription(
          `Job Title: Marketing Specialist\n\nRole Summary: We are looking for a Marketing Specialist to join our team and help drive our marketing efforts. The ideal candidate will have a strong understanding of digital marketing channels and proven experience in creating and executing marketing campaigns.\n\nResponsibilities:\n- Develop and implement marketing strategies to promote products or services.\n- Create marketing materials, such as brochures, flyers, and online content.\n- Manage social media accounts and engage with followers.\n- Analyze marketing data (e.g., website traffic, campaign performance) and make recommendations for improvement.\n- Collaborate with cross-functional teams to execute marketing initiatives.\n- Monitor industry trends and competitor activities.\n\nRequirements:\n- Bachelor's degree in Marketing, Business, or related field.\n- 2+ years of experience in marketing.\n- Strong written and verbal communication skills.\n- Proficiency in digital marketing tools and platforms (e.g., Google Analytics, Facebook Ads Manager).\n- Creative thinking and problem-solving abilities.\n- Ability to work effectively in a fast-paced environment.`
        );
        break;
      case "Customer Service Representative":
        setCustomDescription(
          `Job Title: Customer Service Representative\n\nRole Summary: As a Customer Service Representative, you will be the first point of contact for customers seeking assistance with products or services. You will handle inquiries, resolve complaints, and provide information to ensure customer satisfaction.\n\nResponsibilities:\n- Respond to customer inquiries via phone, email, or chat.\n- Resolve customer complaints and escalate issues as needed.\n- Provide information about products, services, and company policies.\n- Process orders, returns, and exchanges.\n- Maintain accurate records of customer interactions and transactions.\n- Follow up with customers to ensure their needs are met.\n\nRequirements:\n- High school diploma or equivalent.\n- 1+ years of experience in customer service.\n- Excellent communication and interpersonal skills.\n- Strong problem-solving abilities.\n- Ability to multitask and work in a fast-paced environment.\n- Proficiency in computer systems and software (e.g., CRM software, Microsoft Office).`
        );
        break;
      case "Sales Representative":
        setCustomDescription(
          `Job Title: Sales Representative\n\nRole Summary: We are seeking a motivated Sales Representative to join our sales team. The ideal candidate will have a passion for sales and a proven track record of meeting and exceeding sales targets.\n\nResponsibilities:\n- Prospect and qualify new leads.\n- Present and demonstrate products or services to potential customers.\n- Negotiate and close sales deals.\n- Build and maintain relationships with customers.\n- Provide ongoing support and assistance to customers.\n- Monitor market trends and competitor activities.\n\nRequirements:\n- Bachelor's degree in Business, Marketing, or related field (preferred).\n- 2+ years of experience in sales.\n- Strong negotiation and persuasion skills.\n- Excellent communication and interpersonal abilities.\n- Ability to work independently and as part of a team.\n- Goal-oriented mindset and drive to succeed.`
        );
        break;
      case "Human Resources Specialist":
        setCustomDescription(
          `Job Title: Human Resources Specialist\n\nRole Summary: We are looking for a Human Resources Specialist to join our HR team and support various HR functions, including recruitment, onboarding, and employee relations. The ideal candidate will have a strong understanding of HR principles and regulations.\n\nResponsibilities:\n- Assist with recruitment processes, including job postings, resume screening, and interviews.\n- Coordinate new employee onboarding and orientation programs.\n- Maintain employee records and ensure compliance with HR policies and procedures.\n- Handle employee inquiries and provide support on HR-related matters.\n- Assist with performance management processes, including goal setting and performance evaluations.\n\nRequirements:\n- Bachelor's degree in Human Resources, Business Administration, or related field.\n- 2+ years of experience in HR.\n- Knowledge of HR laws and regulations.\n- Strong organizational and time management skills.\n- Excellent interpersonal and communication abilities.\n- Ability to maintain confidentiality and exercise discretion.`
        );
        break;
      case "UX/UI Designer":
        setCustomDescription(
          `Job Title: UX/UI Designer\n\nRole Summary: We are seeking a talented UX/UI Designer to join our design team and help create intuitive and visually appealing user experiences for our products. The ideal candidate will have a strong portfolio showcasing their design skills and creative thinking.\n\nResponsibilities:\n- Collaborate with product managers and developers to understand user needs and project requirements.\n- Create wireframes, prototypes, and user interface designs.\n- Conduct usability testing and gather feedback from users.\n- Iterate on designs based on feedback and usability testing results.\n- Stay up-to-date with design trends and best practices.\n\nRequirements:\n- Bachelor's degree in Design, Human-Computer Interaction, or related field.\n- 2+ years of experience in UX/UI design.\n- Proficiency in design tools such as Sketch, Adobe XD, or Figma.\n- Strong problem-solving and analytical skills.\n- Excellent communication and collaboration abilities.\n- Portfolio showcasing a range of design projects.`
        );
        break;
      case "Data Analyst":
        setCustomDescription(
          `Job Title: Data Analyst\n\nRole Summary: As a Data Analyst, you will be responsible for collecting, analyzing, and interpreting data to inform business decisions. You will work closely with stakeholders to identify data requirements and deliver actionable insights.\n\nResponsibilities:\n- Collect and analyze data from multiple sources.\n- Interpret data and identify trends and patterns.\n- Create and maintain dashboards and reports.\n- Communicate findings and insights to stakeholders.\n- Collaborate with cross-functional teams to define data requirements.\n- Identify opportunities for process improvement and optimization.\n\nRequirements:\n- Bachelor's degree in Mathematics, Statistics, Computer Science, or related field.\n- 2+ years of experience in data analysis.\n- Proficiency in SQL and data visualization tools (e.g., Tableau, Power BI).\n- Strong analytical and problem-solving skills.\n- Excellent communication and presentation abilities.\n- Experience with programming languages such as Python or R (preferred).`
        );
        break;
      case "QA Engineer":
        setCustomDescription(
          `Job Title: QA Engineer\n\nRole Summary: We are looking for a QA Engineer to join our QA team and ensure the quality and reliability of our software products. The ideal candidate will have experience in manual and automated testing and a strong attention to detail.\n\nResponsibilities:\n- Develop test plans, test cases, and test scripts.\n- Execute manual and automated tests.\n- Identify and report defects and issues.\n- Collaborate with developers to reproduce and resolve issues.\n- Perform regression testing and ensure backward compatibility.\n- Participate in agile development processes.\n\nRequirements:\n- Bachelor's degree in Computer Science, Engineering, or related field.\n- 2+ years of experience in software testing.\n- Familiarity with testing methodologies and tools.\n- Experience with test automation tools such as Selenium or Cypress.\n- Strong analytical and problem-solving skills.\n- Excellent communication and teamwork abilities.`
        );
        break;
      default:
        setCustomDescription("");
        break;
    }
  };

  // Function to handle job selection
  const handleJobSelection = (job) => {
    setSelectedJob(job); // Update selected job
    generateCustomDescription(job); // Generate custom description based on selected job
  };

  // Function to handle textarea changes
  const handleTextareaChange = (event) => {
    setCustomDescription(event.target.value); // Update custom description based on textarea input
  };

  const handleGenerateQuestions = async () => {
    if (customDescription.trim() === "") {
      setErrorMessage(
        "Please enter a job description before generating questions."
      );
      return; // Return early if textarea is empty
    }

    setShowLoadingPopup(true);
    try {
      const response = await apiService.post(`${baseUrl}/ai/interviewAi`, {
        customDescription: customDescription,
      });
      console.log(response);
      navigate("/tools/interviewQuestion", {
        state: { interviewQuestions: response },
      }); // Use navigate for navigation
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setShowLoadingPopup(false);
    }
  };

  return (
    <div className="flashh">
      <h1 className="flashCardname3">Interview AI</h1>
      <div className="kjsdhhcs">
        <div className="jkkhgv">
          <h1 className="jobDesc">Select a job description</h1>
          <div className="jobitmes">
            <div className="hjghhj">
              <p
                className={`jobuuyf ${selectedJob === "Custom Job Description" ? "active" : ""}`}
                onClick={() => handleJobSelection("Custom Job Description")}
              >
                Custom Job Description
              </p>

              <p
                className={`jobuuyf ${selectedJob === "Business Analyst" ? "active" : ""}`}
                onClick={() => handleJobSelection("Business Analyst")}
              >
                Business Analyst
              </p>
              <p
                className={`jobuuyf ${selectedJob === "Product Manager" ? "active" : ""}`}
                onClick={() => handleJobSelection("Product Manager")}
              >
                Product Manager
              </p>
            </div>
            <div className="hjghhj">
              <p
                className={`jobuuyf ${selectedJob === "Software Engineer" ? "active" : ""}`}
                onClick={() => handleJobSelection("Software Engineer")}
              >
                Software Engineer
              </p>
              <p
                className={`jobuuyf ${selectedJob === "Marketing Specialist" ? "active" : ""}`}
                onClick={() => handleJobSelection("Marketing Specialist")}
              >
                Marketing Specialist
              </p>
              <p
                className={`jobuuyf ${selectedJob === "Customer Service Representative" ? "active" : ""}`}
                onClick={() =>
                  handleJobSelection("Customer Service Representative")
                }
              >
                Customer Service Representative
              </p>
            </div>
            <div className="hjghhj">
              <p
                className={`jobuuyf ${selectedJob === "Sales Representative" ? "active" : ""}`}
                onClick={() => handleJobSelection("Sales Representative")}
              >
                Sales Representative
              </p>
              <p
                className={`jobuuyf ${selectedJob === "Human Resources Specialist" ? "active" : ""}`}
                onClick={() => handleJobSelection("Human Resources Specialist")}
              >
                Human Resources Specialist
              </p>
              <p
                className={`jobuuyf ${selectedJob === "UX/UI Designer" ? "active" : ""}`}
                onClick={() => handleJobSelection("UX/UI Designer")}
              >
                UX/UI Designer
              </p>
            </div>
            <div className="hjghhj">
              <p
                className={`jobuuyf ${selectedJob === "Data Analyst" ? "active" : ""}`}
                onClick={() => handleJobSelection("Data Analyst")}
              >
                Data Analyst
              </p>
              <p
                className={`jobuuyf ${selectedJob === "QA Engineer" ? "active" : ""}`}
                onClick={() => handleJobSelection("QA Engineer")}
              >
                QA Engineer
              </p>
            </div>
          </div>
          {errorMessage && <p className="error-mebssage">{errorMessage}</p>}

          <div className="jhjhtt">
            <textarea
              value={customDescription}
              onChange={handleTextareaChange}
              className="tetgxt"
              rows="10"
              maxLength="5000"
              placeholder="Enter your custom job description here..."
            />
          </div>
          <div className="geereya">
            <button className="juyt" onClick={handleGenerateQuestions}>
              Generate Questions
            </button>{" "}
          </div>
        </div>
      </div>
      {showLoadingPopup && <LoadingSpinner />}
    </div>
  );
};

export default InterviewAi;
