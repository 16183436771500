import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../styles/toolcard.css";

const iconMapping = {
  "AI Paraphrasing Tool": "pencil",
  "Novel-writer": "book",
  "Business Plan Generator": "handbag",
  "Flash Card Generator": "envelope",
  "Hex Code Generator": "router",
  "Business AI": "building",
  "Interview AI": "display",
  "Caption Generator": "keyboard",
  "Logo Generator": "palette",
  "Workout Generator": "lightningCharge",
  "Tutor AI": "eyeglasses",
};

const ToolCard = ({ heading, link, backgroundColor }) => {
  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    const fetchAvatar = () => {
      const icon = iconMapping[heading] || "question-circle"; // Fallback icon
      const avatarApiUrl = `https://api.dicebear.com/9.x/icons/svg?icon=${icon}&seed=${encodeURIComponent(heading)}&backgroundColor=1b2333`;
      setAvatarUrl(avatarApiUrl);
    };

    fetchAvatar();
  }, [heading, backgroundColor]);

  return (
    <div className="tool-card1">
      <a href={link} className="tool-card-link1">
        <div className="tool-card-icon1">
          {avatarUrl && <img src={avatarUrl} alt={`${heading} avatar`} />}
        </div>
      </a>
      <h3 className="tool-card-heading1">{heading}</h3>
    </div>
  );
};

ToolCard.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string, // Add this line
};

export default ToolCard;
