import React, { useState } from "react";
import apiService from "../../services/apiService";
import { BeatLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReportDisplay from "./ReportDisplay";

const PlanGenerator = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({
    companyOverview: "",
    marketingDetails: "",
    competitionInfo: "",
    financialOverview: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState("");

  const questions = [
    { key: "companyOverview", label: "Add company overview" },
    { key: "marketingDetails", label: "Add marketing details" },
    { key: "competitionInfo", label: "Add competition info" },
    { key: "financialOverview", label: "Add financial overview" },
  ];

  const handleReset = () => {
    setAnswers({
      companyOverview: "",
      marketingDetails: "",
      competitionInfo: "",
      financialOverview: "",
    });
    setCurrentStep(0);
    setReport("");
  };

  const handleNext = async () => {
    if (answers[questions[currentStep].key].trim() === "") {
      toast.error("Please fill in the current section before proceeding.");
      return;
    }

    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsLoading(true);
      const response = await apiService.post("/ai/generate-business-report", {
        businessObj: answers,
      });
      setIsLoading(false);
      if (response.status === "success") {
        setReport(response.report);
      } else {
        console.error("Failed to generate report:", response);
      }
    }
  };

  const handleChange = (e) => {
    setAnswers({
      ...answers,
      [questions[currentStep].key]: e.target.value,
    });
  };

  const progress = ((currentStep + 1) / questions.length) * 100;

  return (
    <div className="font-helvetica">
      <h1 className="text-white text-2xl font-bold my-9 mx-10 tracking-tight">
        Business Plan Generator
      </h1>

      <div className="flex flex-col lg:flex-row w-full h-full">
        {report ? (
          <ReportDisplay report={report} onReset={handleReset} />
        ) : (
          <div className="flex-grow flex items-center justify-center p-5">
            <div className="p-8 rounded-lg shadow-lg max-w-lg w-full mx-auto">
              {isLoading ? (
                <BeatLoader color="#37c640" />
              ) : (
                <>
                  <div className="pb-5">
                    <div className="w-full h-2 bg-gray-300 rounded-full">
                      <div
                        className="h-2 bg-green-500 rounded-full"
                        style={{
                          width: `${progress}%`,
                          transition: "width 0.5s ease-in-out",
                        }}
                      ></div>
                    </div>
                  </div>
                  <p className="text-gray-400 mb-2">
                    {questions[currentStep].label}
                  </p>
                  <textarea
                    value={answers[questions[currentStep].key]}
                    onChange={handleChange}
                    placeholder=""
                    className="w-full p-4 bg-transparent border border-gray-400 bg-dark-800 rounded-lg focus:ring-2 text-white"
                    rows="4"
                  ></textarea>
                  <button
                    onClick={handleNext}
                    className="w-full py-3 bg-[#6CD97E] rounded-full transition duration-300 hover:bg-[#58D86D] text-[18px]"
                  >
                    {currentStep < questions.length - 1 ? "Next" : "Submit"}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
};

export default PlanGenerator;
