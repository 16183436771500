import React, { useEffect, useState } from "react";
import { SocialLeft } from "./SocialLeft";
import SocialRight from "./SocialRight";
import "../styles/social_feature.css";
import boy from "../assets/boy1.png"; // Default profile image
import baseUrl from "../baseUrl";
import { useSelector } from "react-redux";
import apiService from "../services/apiService";

const Notifications = () => {
  const [followingMessages, setFollowingMessages] = useState([]);
  const [otherMessages, setOtherMessages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("following");
  const username = useSelector((state) => state.user.userName);

  useEffect(() => {
    fetchMessages();
  }, [username]);

  const fetchMessages = async () => {
    console.log("Fetching messages for user:", username);
    try {
      const response = await apiService.get(
        `${baseUrl}/social/sendNotification`
      );

      const data = response;

      // Separate messages into categories
      const followingMessages = data.filter((message) =>
        message.notificationMessage.includes("followed you")
      );
      const otherMessages = data.filter(
        (message) => !message.notificationMessage.includes("followed you")
      );

      // Format messages
      const formatMessage = (message) => {
        const words = message.notificationMessage.split(" ");
        words[0] = `<span style="font-weight: bold; color: #fff;">${words[0]}</span>`;
        const imageUrl = message.imageUrl; // Assuming imageUrl is included in the notification data
        const profileImage = imageUrl
          ? `<img src="${imageUrl}" alt="Profile" className="bshjxa" style="width: 42px; height: 42px; border-radius: 50%; margin-top:9px;">`
          : `<img src="${boy}" alt="Default Profile" className="bshjxa" style="width: 42px; height: 42px; border-radius: 50%;margin-top:9px;">`; // Display profile image if imageUrl is available, otherwise display default profile image
        let notificationMessage = words.join(" ");
        // Check if the message contains "liked your post:"
        const indexOfPost = notificationMessage.indexOf("liked your post:");
        if (indexOfPost !== -1) {
          const beforePost = notificationMessage.slice(0, indexOfPost + 16);
          const afterPost = notificationMessage.slice(indexOfPost + 17);
          notificationMessage = `${beforePost}<div style="border-radius:8px; padding: 10px; margin-top:12px; box-shadow:0 0 7px #cdcdcd;">${afterPost}</div>`;
        }
        // Limiting the message length to 380 characters and adding "..." if it exceeds
        notificationMessage =
          notificationMessage.length > 380
            ? notificationMessage.slice(0, 380) + "..."
            : notificationMessage;

        // Format the createdAt timestamp
        const now = new Date();
        const messageTime = new Date(message.createdAt);
        const isToday =
          messageTime.getDate() === now.getDate() &&
          messageTime.getMonth() === now.getMonth() &&
          messageTime.getFullYear() === now.getFullYear();
        const formattedTime = isToday
          ? messageTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          : messageTime.toLocaleDateString([], {
              day: "numeric",
              month: "short",
            });

        return {
          ...message,
          formattedMessage: (
            <div key={message._id} className="kjsnxhabh">
              <div className="kjjjhh">
                <div dangerouslySetInnerHTML={{ __html: profileImage }} />{" "}
                {/* Render profile image */}
                <div className="mjksdxhj">
                  <p
                    className="bhjb"
                    dangerouslySetInnerHTML={{ __html: notificationMessage }}
                  ></p>
                </div>
              </div>
              <p className={`notification-time ${isToday ? "time" : "date"}`}>
                {formattedTime}
              </p>
            </div>
          ),
        };
      };

      setFollowingMessages(followingMessages.map(formatMessage).reverse());
      setOtherMessages(otherMessages.map(formatMessage).reverse());
    } catch (error) {
      console.error("Error fetching messages:", error.message);
    }
  };

  return (
    <>
      <div className={`containe`}>
        <div className="social_feature_container">
          <SocialLeft />
          <div className="social_center_trending">
            <h1 className="mndccgcb">Notifications</h1>
            <div className="buttonjn">
              <button
                className={`buttobn ${
                  selectedCategory === "following" ? "active3" : "inactive"
                }`}
                onClick={() => setSelectedCategory("following")}
              >
                Following
              </button>
              <button
                className={`button1 ${
                  selectedCategory === "others" ? "active3" : "inactive"
                }`}
                onClick={() => setSelectedCategory("others")}
              >
                Others
              </button>
            </div>
            <hr className="kjnsdjk" />
            <div className="janzhj">
              {selectedCategory === "following"
                ? followingMessages.map((message) => message.formattedMessage)
                : otherMessages.map((message) => message.formattedMessage)}
            </div>
          </div>
          <SocialRight />
        </div>
      </div>
    </>
  );
};

export default Notifications;
