import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/MobileNavbar.css"; // Import the CSS file for styling
import homeIcon from "../assets/Images/home.svg";
import aiChatIcon from "../assets/Images/aichat.svg";
import socialIcon from "../assets/Images/social.svg";
import collectionIcon from "../assets/Images/collection.svg";
import aiToolsIcon from "../assets/Images/aitool.svg";
import { FaHome } from "react-icons/fa";
import { AiFillWechat } from "react-icons/ai";
import { SlSocialDribbble } from "react-icons/sl";
import { MdOutlineCollectionsBookmark } from "react-icons/md";
import { TbTools } from "react-icons/tb";

const MobileNavBar = () => {
  const location = useLocation();

  return (
    <div className="mobile-nav-bar">
      <Link
        to="/"
        className={`nav-item ${location.pathname === "/" ? "active" : ""}`}
      >
        <FaHome size={22} />
        <span className="homespan">Home</span>
      </Link>
      <Link
        to="/aichat/youai"
        className={`nav-item ${location.pathname === "/aichat/youai" ? "active" : ""}`}
      >
        <AiFillWechat size={22} />
        <span className="chatspan">Chat AI</span>
      </Link>
      <Link
        to="/social"
        className={`nav-item ${location.pathname === "/social" ? "active social-active" : ""}`}
      >
        <div className="social-icon-wrapper">
          <SlSocialDribbble size={28} className="socialop" />
        </div>
        <span className="socialspan">Social</span>
      </Link>
      <Link
        to="/collection"
        className={`nav-item ${location.pathname === "/collection" ? "active" : ""}`}
      >
        <MdOutlineCollectionsBookmark size={22} />
        <span className="collectionspan">Collection</span>
      </Link>
      <Link
        to="/tools"
        className={`nav-item ${location.pathname === "/tools" ? "active" : ""}`}
      >
        <TbTools size={22} />
        <span className="aitoolspan">AI Tools</span>
      </Link>
    </div>
  );
};

export default MobileNavBar;
