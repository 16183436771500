import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "../styles/LandingPage.css";
import close from "../assets/close_small.png";
import baseUrl from "../baseUrl";
import axios from "axios";
import icon4 from "../assets/icon4.png";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import apiService from "../services/apiService";

const BlogCreatePost = ({ onClose, onProfileUpdate }) => {
  const fileInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const username = useSelector((state) => state.user.username);
  const [title, setTitle] = useState("");
  const [blogContent, setBlogContent] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result); // Set the URL of the selected image
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handlePostButtonClick = async () => {
    try {
      // Validate inputs
      if (!title || !blogContent) {
        alert("Please fill in both title and blog content.");
        return;
      }

      // Check if an image is selected
      if (!selectedImage) {
        alert("Please select an image.");
        return;
      }

      const data = {
        title,
        blogImageUrl: selectedImage, // Use the URL directly
        blogContent,
      };

      const response = await apiService.post(`${baseUrl}/social/blog`, data);

      // Handle the server response as needed
      console.log("Server response:", response);
      if (response.status === 200) {
        toast.success("Your post has been created!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="post_popup1">
        <div className="social_popup_box1">
          <img src={close} alt="" className="close_small" onClick={onClose} />
          <h1 className="hcjbbh">Create Blog</h1>
          <p className="nshbcj">
            Elevate Your Narrative, Share Your AI Stories Through Engaging
            Blogs!{" "}
          </p>
          <div className="hbjgj">
            <form action="" className="kahbj">
              <div className="kjndh">
                <label htmlFor="" className="jnjb">
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  className="jnhjb"
                  placeholder="Blog Title"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="kjndh">
                <label htmlFor="" className="jnjb">
                  Blog
                </label>
                <ReactQuill
                  className="post_textarea1"
                  value={blogContent}
                  onChange={(value) => setBlogContent(value)}
                  modules={{ toolbar: true }}
                  style={{ border: "none" }}
                  placeholder="Write blog here..."
                />
              </div>
              <div className="btns_cobine">
                <div className="bhggh">
                  <button className="disocard" onClick={onClose}>
                    Discard
                  </button>
                  <button className="postpost" onClick={handlePostButtonClick}>
                    Post
                  </button>
                </div>
                <label className="post_media1" htmlFor="postMediaInput">
                  <img src={icon4} alt="icon4" className="iconik" />
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="postMediaInput"
                    style={{ display: "none" }}
                  />
                  <p className="media_text">Media</p>
                </label>
              </div>
            </form>
            {selectedImage && (
              <div className="selected-image-preview">
                <img
                  src={selectedImage}
                  alt="Selected Image Preview"
                  className="selected-image"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCreatePost;
