import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import socialImage from "../assets/Images/socialBoard.svg";
import socialResponsive from "../assets/Images/socialSmall.svg";
import CollectionCardHome from "../components/CollectionCardHome";
import MobileNavBar from "../components/MobileNavbar";
import { ClipLoader } from "react-spinners";
import "../styles/HomeMainPage.css";
import { collections } from "../constants/collections";
import aitool from "../assets/globalAI.png";
import ainews from "../assets/AIChat.png";
import AvatarCard from "./AvatarCard";
import ToolCard from "./HomePageToolCard";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import ainewsMobile from "../assets/global1.png";
import aitoolMobile from "../assets/tools.png";
import { Link } from "react-router-dom";
import { getCredits, getNotifications } from "../services/auth";
import { setCredits, updateNotification } from "../features/userSlice";
import { useDispatch } from "react-redux";

const HomeMainPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 437);
  const [loading, setLoading] = useState(true);
  const [tools, setTools] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const notificationsPromise = getNotifications()
        .then((response) => {
          if (response && typeof response.count === "number") {
            dispatch(updateNotification(response.count));
          } else {
            console.error("Invalid count in response:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error.message);
        });

      const creditsPromise = getCredits()
        .then((response) => {
          if (response?.data !== undefined) {
            dispatch(setCredits(response.data));
          } else {
            console.error("Invalid credits data:", response);
          }
        })
        .catch((error) => {
          console.error("Error fetching user credits:", error.message);
        });

      await Promise.all([notificationsPromise, creditsPromise]);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchAvatarsAndTools = async () => {
    try {
      const [avatarsResponse, toolsResponse] = await Promise.all([
        apiService.get(`${baseUrl}/avatar/getAllDataAvatar`),
        apiService.get(`${baseUrl}/ai/get-tool`),
      ]);

      if (Array.isArray(avatarsResponse)) {
        // Sort avatars by timestamp or any other criteria to display the latest first
        const sortedAvatars = avatarsResponse.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log("avatar: ", sortedAvatars);
        setAvatars(sortedAvatars);
      } else {
        console.error("Unexpected avatars response format:", avatarsResponse);
      }

      if (Array.isArray(toolsResponse)) {
        setTools(toolsResponse);
      } else {
        console.error("Unexpected tools response format:", toolsResponse);
      }
    } catch (error) {
      console.error("Error fetching avatars or tools:", error);
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = isMobile ? socialResponsive : socialImage;
    img.onload = () => setLoading(false);
    img.onerror = () => setLoading(false);
  }, [isMobile, socialResponsive, socialImage]);

  useEffect(() => {
    fetchAvatarsAndTools();
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 437);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    customPaging: (i) => <div className="slick-dot"></div>,
    appendDots: (dots) => (
      <div>
        <ul className="slick-dots">{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 437,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 417,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 407,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 377,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 365,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings_tools = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false, // Ensure center mode is off
    customPaging: (i) => <div className="slick-dot"></div>,
    appendDots: (dots) => (
      <div>
        <ul className="slick-dots">{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings_tools1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    customPaging: (i) => <div className="slick-dot"></div>,
    appendDots: (dots) => (
      <div>
        <ul className="slick-dots">{dots}</ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="main-content">
        <div className="main-cook">
          <section className="social-section">
            <div className="social-content">
              {loading ? (
                <div className="loader-container1">
                  <ClipLoader color="#6cd97e" size={50} />
                </div>
              ) : (
                <>
                  <div className="popuil">
                    <>
                      <h2 className="social-title">Social</h2>
                      <Link to="/social" className="explore-link">
                        Explore
                      </Link>
                    </>
                  </div>
                  <Link to="/social">
                    <img
                      src={isMobile ? socialResponsive : socialImage}
                      alt="Social"
                      className="social-image"
                      loading={"lazy"}
                      onLoad={() => setLoading(false)}
                    />
                  </Link>
                  <div className="social-overlay">
                    {!loading && (
                      <h3 className="social-overlay-title">
                        Connect to world with AI
                      </h3>
                    )}
                  </div>
                </>
              )}
            </div>
          </section>
          <section className="explore-collection-section">
            <div className="exolo">
              <div className="explore-header">
                <h2 className="explore-title">Explore Collection</h2>
                <Link to="/collection" className="view-all-link">
                  View all
                </Link>
              </div>
              <Slider {...settings} className="collection-slider">
                {collections.map(
                  ({ title, description, type, image }, index) => (
                    <div key={index} className="card">
                      <CollectionCardHome
                        title={title}
                        description={description}
                        type={type}
                        image={image}
                      />
                    </div>
                  )
                )}
              </Slider>
            </div>
          </section>
          <section className="social-section">
            <div className="social-content">
              {loading ? (
                <div className="loader-container1">
                  <ClipLoader color="#6cd97e" size={50} />
                </div>
              ) : (
                <>
                  <div className="popuil">
                    {!loading && (
                      <>
                        <h2 className="social-title">Chat with AI</h2>
                        <Link to="/aichat/youai" className="explore-link">
                          Explore
                        </Link>
                      </>
                    )}
                  </div>
                  <Link to="/aichat/youai">
                    <img
                      src={isMobile ? aitoolMobile : aitool}
                      alt="Social"
                      className="social-image"
                      loading="lazy"
                    />
                  </Link>
                  <div className="social-overlay">
                    {!loading && (
                      <h3 className="social-overlay-title">
                        Let AI make your life easy.
                      </h3>
                    )}
                  </div>
                </>
              )}
            </div>
          </section>
          <section className="explore-collection-section">
            <div className="exolo">
              <div className="explore-header">
                <h2 className="explore-title">AI Tools</h2>
                <Link to="/tools" className="view-all-link">
                  View all
                </Link>
              </div>
              <Slider {...settings_tools1} className="collection-slider">
                {tools.map((tool, index) => (
                  <div key={index} className="card1">
                    <ToolCard
                      heading={tool.heading}
                      iconSvg={tool.iconSvg}
                      link={tool.link}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </section>
          <section className="social-section">
            <div className="social-content">
              {loading ? (
                <div className="loader-container1">
                  <ClipLoader color="#6cd97e" size={50} />
                </div>
              ) : (
                <>
                  <div className="popuil">
                    {!loading && (
                      <>
                        <h2 className="social-title">AI News</h2>
                        <Link to="/social" className="explore-link">
                          Explore
                        </Link>
                      </>
                    )}
                  </div>
                  <Link to="/globalAiNews">
                    <img
                      src={isMobile ? ainewsMobile : ainews}
                      alt="Social"
                      className="social-image"
                      loading="lazy"
                    />
                  </Link>
                  <div className="social-overlay">
                    {!loading && (
                      <h3 className="social-overlay-title">
                        Connect to world with AI
                      </h3>
                    )}
                  </div>
                </>
              )}
            </div>
          </section>
          {avatars.length > 0 && (
            <section className="explore-collection-section">
              <div className="exolo">
                <div className="explore-header">
                  <h2 className="explore-title">Generated Avatars</h2>
                  <a href="/avatarStore" className="view-all-link">
                    View all
                  </a>
                </div>
                <Slider
                  {...settings_tools}
                  className={`collection-slider1 ${
                    avatars.length <= 3 ? "few-avatars" : ""
                  }`}
                >
                  {avatars.map((avatar, index) => (
                    <div key={index} className="card1">
                      <AvatarCard
                        name={avatar.name}
                        id={avatar._id} // Pass the avatar ID
                        image={avatar.avatarImage}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </section>
          )}
        </div>
      </div>
      {isMobile && <MobileNavBar />}
    </>
  );
};

export default HomeMainPage;
