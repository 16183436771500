import React, { useEffect, useState } from 'react'
import Card from '../tutor/Card'
import { Search } from 'react-feather'
import { tutorTopics } from '../../constants/aiTools'
import apiService from '../../services/apiService'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom'


const TutorHome = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [subTopics, setSubTopics] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    // Filter topics based on search term
    const filteredTopics = tutorTopics.filter(topic =>
        topic.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSubmit = async (e, topicFromCard) => {
        e?.preventDefault();
        setIsLoading(true);

        const topic = topicFromCard || searchTerm;

        if (!topic) {
            toast.error('Please enter a topic to search.');
            setIsLoading(false);
            return;
        }
        try {
            const response = await apiService.post('/ai/generate-tutor', { topic, flag: 'topic' });
            if (response.status === 'success') {
                setSubTopics(response.subTopics);
                navigate('/tools/tutor-study', { state: { subTopics: response.subTopics } });
            } else {
                console.log('Failed to fetch sub-topics.');
            }
        } catch (error) {
            console.error('Error fetching sub-topics:', error);
        }
        setIsLoading(false);
    }


    useEffect(() => {
        if (subTopics) {
            console.log("SubTopics updated:", subTopics);
        }
    }, [subTopics]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='flex flex-col min-h-screen w-full'>
            {isLoading && (
                <div className="absolute lg:h-[1000px] md:h-[1200px] h-[2500px] inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
                    <ClipLoader color='#6cd97e' />
                </div>
            )}
            <div className='flex-grow p-12'>
                <h1 className="text-lg text-white font-bold flex justify-center mb-2">Search a topic you want to study</h1>

                {/* Input Bar */}
                <div className='lg:px-20 lg:py-3 md:px-20 md:py-3 py-3'>
                    <form onSubmit={(e) => handleSubmit(e)} className="flex justify-between bg-gray-800 rounded-full py-3 lg:px-8 md:px-8 px-3 border border-white">
                        <input
                            type="text"
                            placeholder='Search topic, start studying..'
                            className="bg-gray-800 w-full focus:outline-none text-white"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <button type="submit">
                            <Search className='text-white cursor-pointer' />
                        </button>
                    </form>
                </div>

                {/* Static topics given */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-6 p-4">
                    {filteredTopics.length > 0 ? (
                        filteredTopics.map((topic, index) => (
                            <Card
                                key={index}
                                title={topic.title}
                                content={topic.content}
                                onClickCall={() => handleSubmit(null, topic.title)}
                            />
                        ))
                    ) : (
                        <h1 className=" text-white w-full lg:ml-20 md:ml-14 font-bold text-xl">
                            Click on search, let AI create a session for you.
                        </h1>
                    )}
                </div>
            </div>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )

}

export default TutorHome
