import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import baseUrl from "../../baseUrl";
import { setFriends } from "../../features/postSlice";
import { IoPersonAdd, IoPersonRemove } from "react-icons/io5";
import { VscLoading } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import { FaMessage } from "react-icons/fa6";
import { selectChat } from "../../features/chatSlice";
import apiService from "../../services/apiService";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";

export const UserWidget = ({ user }) => {
  const dispatch = useDispatch();
  const following = useSelector((state) => state.posts.userFollowing);
  const username = useSelector((state) => state.user.userName);
  const isFriend = following?.find(
    (friend) => friend.username === user.username
  );
  const isItself = user.username === username;
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const hash = CryptoJS.MD5(username).toString();
  const navigate = useNavigate();
  const handleClick = async () => {
    try {
      setLoading(true);
      const response = await apiService.patch(
        `${baseUrl}/social/follow/${user.username}`
      );
      if (response) {
        let updatedFriends;
        if (response && response.message === "User followed successfully") {
          updatedFriends = [...following, user];
        } else {
          updatedFriends = following.filter(
            (friend) => friend.username !== user.username
          );
        }
        dispatch(setFriends({ friends: updatedFriends }));
      } else {
        console.log("Failed to follow user");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMessageClick = () => {
    dispatch(selectChat(user));
    navigate("/messaging");
  };

  return (
    <div
      className="one_pro relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="name_pio ">
        {user.imageUrl ? (
          <img src={user.imageUrl} alt="boy" className="boy_prof" />
        ) : (
          <Gravatar
            email={user.username}
            size={150}
            default="identicon"
            className="user_frien3"
            style={{ width: "2rem", height: "2rem" }}
            hash={hash}
          />
        )}
        <Link
          to={`/social_profile/${user.username}`}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "10px",
            overflow: "hidden",
          }}
        >
          <p
            className="pro_name"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {user.username}
          </p>
        </Link>

        {isHovered && (
          <div
            className=" animate-bounce message-icon"
            style={{ opacity: isHovered ? 1 : 0 }}
            onClick={() => handleMessageClick()}
          >
            <FaMessage color="#11858e" />
          </div>
        )}
      </div>
      {!isItself && (
        <div
          className={`add_foll ${isFriend && "added"}`}
          onClick={handleClick}
        >
          {loading ? (
            <VscLoading className="loading-animation" color="white" />
          ) : isFriend ? (
            <IoPersonRemove color="white" />
          ) : (
            <IoPersonAdd color="gray" />
          )}
        </div>
      )}
    </div>
  );
};
