import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAvatarId } from "../features/avatarSlice"; // Import the setAvatarId action
import "../styles/avatarCard.css";

const AvatarCard = ({ name, id, image }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setAvatarId(id)); // Store the avatar ID in Redux
    navigate(`/aichat/avatar`); // Navigate to avatar route without session ID
  };

  return (
    <div className="avatar-card" onClick={handleClick}>
      <div className="kol">
        <img src={image} alt={`${name} Avatar`} className="avatar-icon" />
      </div>
      <h3 className="avatar-name">{name}</h3>
    </div>
  );
};

export default AvatarCard;
