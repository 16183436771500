import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ThreeJsAnimation from "./ThreeJsAnimation";
import BlogPopup from "./blogCreatePost";
import { RingLoader } from "react-spinners"; // Import RingLoader
import { Link } from "react-router-dom";
import "../styles/LandingPage.css";
import hi5 from "../assets/hi5.jpg";
import baseUrl from "../baseUrl";
import { Helmet } from "react-helmet-async";
import CustomCursor from "./CursorAnimation";
import { useSelector } from "react-redux";
import apiService from "../services/apiService";

const Blog = () => {
  const [isPostLikeVisible, setPostLikeVisible] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authorizedUser, setAuthorizedUser] = useState(false);
  const username = useSelector((state) => state.user.userName);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    fetchBlogs();
    checkUserAuthorization();
  }, []);

  // Function to check if the current user is authorized
  const checkUserAuthorization = () => {
    const authorizedUsers = ["guest_ltzid8fa_nxqs", "user2"]; // List of authorized users
    if (authorizedUsers.includes(username)) {
      setAuthorizedUser(true); // Set authorized flag if the username is in the list
    }
  };

  // Function to truncate text to a specified character limit
  const truncateText = (text, limit) => {
    if (!text) {
      return ""; // Return an empty string if the text is undefined or null
    }

    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }

    return text;
  };

  const fetchBlogs = async () => {
    try {
      const response = await apiService.get(`${baseUrl}/social/fetchBlog`);
      console.log("Server response:", response);
      setBlogs(response);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleStartPostClick = () => {
    if (authorizedUser) {
      setPostLikeVisible(true);
    } else {
      alert("You are not authorized to create a blog.");
    }
  };

  const handleClosePopupClick = () => {
    setPostLikeVisible(false);
  };

  const handleProfileUpdate = () => {
    fetchBlogs();
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  return (
    <>
      <Helmet>
        <title>Ideaverse | Blogs</title>
        <meta
          name="description"
          content="Ideaverse unlocks tomorrow's potential, shaping the future through innovative AI models."
        />
        <meta
          name="keywords"
          content="AI, ChatGPT, Blogs, AI Latest, ideaverse, AI Voice Assistant"
        />
      </Helmet>
      <CustomCursor />
      <div className="maing">
        <Navbar data-aos="fade-up" />
        <div className="animatedcontainer1">
          <ThreeJsAnimation />
          <div className="home1">
            <img src={hi5} alt="men" className="jhsghj1" data-aos="fade-up" />
            <h1 className="sit" data-aos="fade-up">
              Be up to date with the latest released in Artificial Intelligence,
              Machine Learning and Robotics.
            </h1>
            <p className="kdlj" data-aos="fade-up">
              We curate and explain the most cutting edge papers in the AI, ML
              and Robotics space. You can find the summaries and industry
              perspectives on the latest papers. Our blog is updated regularly
              with many research and technical papers every week. No need to
              read the whole paper instead read our blogs to stay ahead of the
              field.
            </p>
          </div>
          <div className="seari" data-aos="fade-up">
            <input type="text" className="bhjgq" placeholder="Search" />
            {authorizedUser && (
              <button className="pubjjk" onClick={handleStartPostClick}>
                Create Blog
              </button>
            )}
          </div>
          <div className="grwe">
            {loading ? (
              <div className="janjmajhn">
                <div className="bhnbhn1">
                  <RingLoader color="#6cd97e" loading={loading} size={100} />
                </div>
              </div>
            ) : (
              blogs.map((blog) => (
                <Link
                  to={`/blogPage/${blog._id}`}
                  key={blog._id}
                  className="blog-link"
                >
                  <div className="ashjj" data-aos="fade-up">
                    <img src={blog.blogImageUrl} alt="img" className="lion" />
                    <button className="topib">Topic</button>
                    <h1 className="lookj">
                      {truncateText(blog.blogTitle, 22)}
                    </h1>
                    {/* Use stripHtmlTags function here */}
                    <p className="ajhnj">
                      {truncateText(stripHtmlTags(blog.blogContent), 100)}
                    </p>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
        {isPostLikeVisible && (
          <BlogPopup
            onClose={handleClosePopupClick}
            onProfileUpdate={handleProfileUpdate}
          />
        )}
        <Footer />
      </div>
    </>
  );
};

export default Blog;
