import React from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/mainLogo-transformed.webp";
import user_icon from "../../assets/boy.png";
import "../../styles/main_chatbot.css";
import { PulseLoader } from "react-spinners";

export const YouAiMessage = ({ message }) => {
  const sender = message.sender;
  const { imageUrl } = useSelector((state) => state.user);
  return (
    <div className="chat_contain pb-4">
      <div className="contain_prompt_div">
        {sender === "YouAI" ? (
          <img src={logo} alt="boy" className="user_prompt_icon2" />
        ) : (
          <img
            src={imageUrl ? imageUrl : user_icon}
            alt="boy"
            className="user_prompt_icon2"
          />
        )}
        {sender === "YouAI" && message.content === "Loading..." ? (
          <div className="prompt_about_person">
            <div className="w-full flex items-center justify-between">
              <p className="you_prompt">
                {sender === "YouAI" ? "YouAI" : "You"}
              </p>
            </div>
            <PulseLoader color="white" className="pt-2" />
          </div>
        ) : (
          <div className="prompt_about_person">
            <div className="w-full flex items-center justify-between">
              <p className="you_prompt">
                {sender === "YouAI" ? "YouAI" : "You"}
              </p>
            </div>
            <p className="prompt_chatss">{message.content}</p>
          </div>
        )}
      </div>
    </div>
  );
};
