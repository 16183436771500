import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { Message } from "./Message";
import ScrollToBottom from "react-scroll-to-bottom";
import { io } from "socket.io-client";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import apiService from "../../../services/apiService";
import baseUrl, { socketUrl } from "../../../baseUrl";
import { useSelector, useDispatch } from "react-redux";
import { addChat, addMessage } from "../../../features/chatSlice";
import { debounce } from "lodash";
let selectedChatCompare;

export const MainScreen = () => {
  const selectedUser = useSelector((state) => state.socialChat.selectedChat);
  const hash = CryptoJS.MD5(selectedUser?.username).toString();
  const [activeChat, setActiveChat] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const user = useSelector((state) => state.user.userId);
  const socketRef = useRef(null);
  const dispatch = useDispatch();
  const messages = useSelector((state) => {
    if (!activeChat) return [];
    const selectedChat = state.socialChat.chats.find(
      (chat) => chat.id === activeChat
    );
    return selectedChat ? selectedChat.messages : [];
  });

  useEffect(() => {
    socketRef.current = io(`${socketUrl}`);
    socketRef.current.emit("setup", user);
    socketRef.current.on("connected", () =>
      console.log("Connection successful")
    );
  }, []);

  useEffect(() => {
    const handleMessageReceived = (newMessageRecieved) => {
      if (
        !selectedChatCompare ||
        selectedChatCompare !== newMessageRecieved.chat._id
      ) {
        // Will have to implement it
        console.log("notify");
      } else {
        dispatch(
          addMessage({
            chatId: newMessageRecieved.chat._id,
            message: newMessageRecieved,
          })
        );
      }
    };

    socketRef.current.on("message-received", handleMessageReceived);

    return () => {
      socketRef.current.off("message-received", handleMessageReceived);
    };
  }, []);

  const handleMessageSend = async () => {
    if (inputValue.trim() === "") return;
    try {
      const response = await apiService.post(
        `${baseUrl}/messaging/send-message`,
        {
          content: inputValue,
          chatId: activeChat,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.error) {
        throw new Error(response.error);
      }

      setInputValue("");
      socketRef.current.emit("new-message", response);
      dispatch(addMessage({ chatId: activeChat, message: response }));
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchConversation = async () => {
    try {
      const response = await apiService.post(
        `${baseUrl}/messaging/`,
        {
          userId: selectedUser.id,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      if (response && response[0]) {
        setActiveChat(response[0]._id);
        fetchChats(response[0]._id);
        selectedChatCompare = response[0]._id;
        socketRef.current.emit("join-chat", response[0]._id);
      } else {
        throw new Error(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchChats = async (activeId) => {
    try {
      if (!activeId) return;
      const response = await apiService.get(`${baseUrl}/messaging/${activeId}`);
      if (response) {
        dispatch(addChat({ id: activeId, messages: response }));
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const debouncedFetchConversation = useCallback(
    debounce(fetchConversation, 300),
    [selectedUser]
  );

  useEffect(() => {
    if (!selectedUser) return;
    debouncedFetchConversation();
  }, [selectedUser]);

  return selectedUser?.username ? (
    <div className="flex w-full md:w-[70%] gap-2 md:pt-4 min-h-[70vh] flex-col items-center">
      <div className="flex w-full md:w-[90%] gap-3 justify-start items-center bg-[#1B2333] rounded-lg p-3">
        <div>
          {selectedUser.imageUrl ? (
            <img
              src={selectedUser.imageUrl}
              alt="logo.png"
              className="boy_prof"
            />
          ) : (
            <Gravatar
              email={selectedUser.username}
              size={200}
              default="identicon"
              className="boy_prof"
              hash={hash}
            />
          )}
        </div>
        <p className="text-[#CDCDCD]">{selectedUser.username}</p>
      </div>

      <ScrollToBottom className="flex flex-col h-[65vh] md:h-[62vh] w-[90%] overflow-y-auto">
        {messages.map((message, id) => (
          <Message message={message} key={id} />
        ))}
      </ScrollToBottom>

      <div className="flex w-[90%] border-2 p-2 gap-3 px-4 rounded-md items-center">
        <input
          type="text"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleMessageSend();
            }
          }}
          className="text-sm text-[#CDCDCD] bg-transparent w-[95%] outline-none"
          placeholder="Send a message"
        />
        <IoMdSend
          onClick={() => handleMessageSend()}
          className="text-[#6CD97E] text-[18px] cursor-pointer"
        />
      </div>
    </div>
  ) : (
    <div className="flex w-full md:w-[70%] gap-2 pt-4 min-h-[70vh] flex-col">
      <div className="flex w-full md:w-[90%] border-dashed h-[80vh] justify-center items-center border-2 border-[#878585] rounded-lg">
        <h2 className=" text-[#878585] text-base ">
          Select a Chat to Start Chatting...
        </h2>
      </div>
    </div>
  );
};
