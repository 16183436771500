import React, { useState, useEffect, Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  Environment,
  Float,
  Loader,
  OrbitControls,
  useGLTF,
} from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faStop,
  faPaperPlane,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import baseUrl from "../../baseUrl"; // Ensure the path is correct
import "../../styles/avatar.css"; // Adjust the path accordingly
import { useSelector } from "react-redux";
import { SyncLoader } from "react-spinners"; // Import the loader

const Avatar3D = () => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [voiceId, setVoiceId] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false); // State to handle loading
  const [selectedModel, setSelectedModel] = useState("model1"); // Default model
  const [showMessage, setShowMessage] = useState(false); // State to handle empty transcript message
  const username = useSelector((state) => state.user.userName);

  useEffect(() => {
    let recognition;
    if (isListening) {
      recognition =
        new window.webkitSpeechRecognition() || new window.SpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcriptPart = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            setTranscript((prev) => prev + transcriptPart);
          } else {
            interimTranscript += transcriptPart;
          }
        }
      };

      recognition.onend = () => setIsListening(false);
      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
        setIsListening(false);
      };

      recognition.start();
    }

    return () => {
      if (recognition) {
        recognition.stop();
        recognition.onend = null;
      }
    };
  }, [isListening]);

  const startListening = () => {
    setTranscript("");
    setResponseData(null); // Clear the response when starting to listen
    setIsListening(true);
  };

  const stopListening = () => {
    setIsListening(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const readImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleGenerateClick = async () => {
    if (!transcript.trim()) {
      setShowMessage(true); // Show message if transcript is empty
      return;
    }

    setShowMessage(false);
    setTranscript("");
    setResponseData(null);
    setLoading(true); // Show loader when sending the prompt
    const responseDisplay = document.getElementById("response-display");
    while (responseDisplay.firstChild) {
      responseDisplay.removeChild(responseDisplay.firstChild);
    }

    let imageUrl = null;
    if (selectedImage) {
      imageUrl = await readImage(selectedImage);
      console.log("Image URL:", imageUrl);
    }

    const payload = {
      content: transcript,
      imageUrl,
      voiceName: "Rachel",
      username,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/avatar/generate-avatar`,
        payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      console.log("Generated Avatar:", response.data);
      setResponseData(response.data);
    } catch (error) {
      console.error("Error generating avatar:", error);
    } finally {
      setLoading(false); // Hide loader after receiving response
    }
  };

  useEffect(() => {
    if (responseData) {
      const synth = window.speechSynthesis;
      synth.cancel();

      if (responseData.llm) {
        const utterance = new SpeechSynthesisUtterance(responseData.llm);
        synth.speak(utterance);
      }

      if (responseData.imageGeneration) {
        const imgElement = document.createElement("img");
        imgElement.src = responseData.imageGeneration[0].url;
        imgElement.alt = responseData.imageGeneration[0].revised_prompt;
        document.getElementById("response-display").appendChild(imgElement);
      }

      if (responseData.speech) {
        if (responseData.speech.url) {
          console.log("Speech : ", responseData.speech.url);
          const audioBlob = base64ToBlob(
            responseData.speech.url.split(",")[1],
            "audio/wav"
          );
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.play();
        } else {
          console.error("No valid speech URL found in response");
        }
      }

      if (responseData.vision) {
        const visionUtterance = new SpeechSynthesisUtterance(
          responseData.vision
        );
        synth.speak(visionUtterance);
      }
    }
  }, [responseData]);

  const base64ToBlob = (base64, type) => {
    const binary = atob(base64.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    return new Blob([buffer], { type });
  };

  const models = ["model1", "model2", "model3", "model4"];

  const itemPlacement = {
    avatar: {
      position: [0, -1.5, 0],
      rotation: [0, degToRad(180), 0],
      scale: 1.5,
    },
  };

  const AvatarModel = () => {
    const { scene } = useGLTF(`/${selectedModel}.glb`);
    return <primitive object={scene} {...itemPlacement.avatar} />;
  };

  return (
    <>
      <div style={{ width: "100vw", height: "94vh", position: "relative" }}>
        <Loader />
        <Canvas camera={{ position: [0, 0, 5], fov: 60 }}>
          <Suspense fallback={null}>
            <Environment preset="sunset" background />
            <ambientLight intensity={0.8} />
            <Float speed={0.5} floatIntensity={0.2} rotationIntensity={0.1}>
              <AvatarModel />
            </Float>
          </Suspense>
          <OrbitControls
            enableZoom={true}
            enablePan={false}
            rotateSpeed={0.5}
            minZoom={0.5}
            maxZoom={10}
          />
        </Canvas>
      </div>
      <div className="avatarContainer">
        {loading && (
          <div className="loader">
            <SyncLoader size={30} color={"#6cd97e"} loading={loading} />
          </div>
        )}
        {showMessage && (
          <div className="error-message">Please ask something.</div>
        )}
        <div className="hjgh">
          {" "}
          <div id="response-display" className="response-display"></div>
        </div>
        <div className="controlss">
          <div className="transcript">{transcript}</div>
          <div className="controls">
            <label htmlFor="file-upload" className="file-upload-label">
              <FontAwesomeIcon icon={faUpload} />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            {isListening ? (
              <button onClick={stopListening} className="icon-button">
                <FontAwesomeIcon icon={faStop} />
              </button>
            ) : (
              <button onClick={startListening} className="icon-button">
                <FontAwesomeIcon icon={faMicrophone} />
              </button>
            )}
            <button
              onClick={handleGenerateClick}
              className="icon-button"
              disabled={!transcript.trim()}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
            <select
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              className="model-select"
            >
              {models.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Avatar3D;
