const users = [
    { id: 1, display: "Hanish Tharwani", username: "john_doe" },
    { id: 2, display: "Jane Doe", username: "jane_doe" },
    { id: 3, display: "John Doe1", username: "john_doe" },
    { id: 4, display: "Jane Doe2", username: "jane_doe" },
    { id: 5, display: "John Doe3", username: "john_doe" },
    { id: 6, display: "Jane Doe4", username: "jane_doe" },
    // Add more user objects as needed
  ];
  
  export default users;
  