import React, { useEffect, useState } from "react";
import { ArrowLeft, Check } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import BeatLoader from "react-spinners/BeatLoader";

const Study = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const subTopics = location.state?.subTopics;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [content, setContent] = useState("Let's start studying");
  const [loading, setLoading] = useState(false);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [completedSubTopics, setCompletedSubTopics] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubTopicSelect = async (topic) => {
    setSelectedSubTopic(topic);
    setLoading(true);
    try {
      const response = await apiService.post("/ai/generate-tutor", {
        topic,
        flag: "subTopic",
      });
      setContent(response.text);
    } catch (error) {
      console.error("Error fetching subTopic content:", error);
      setContent("Failed to load content. Please try again.");
    }
    setLoading(false);
  };

  const markAsComplete = () => {
    setCompletedSubTopics((prev) => ({ ...prev, [selectedSubTopic]: true }));
  };

  return (
    <div className="flex w-full">
      <div className="flex-grow p-10">
        <div className="block">
          <button
            onClick={() => navigate("/tools/tutor")}
            className="text-white bg-[#6cd97e] flex items-center justify-center pl-2 py-2 rounded-lg mb-3"
          >
            <ArrowLeft className="mr-2" />
          </button>
        </div>

        <div className="lg:flex md:flex">
          <div className="w-full lg:w-1/5 md:w-1/4 overflow-auto">
            <ul className="list-none p-4 bg-gray-800 text-white rounded-lg border border-gray-400">
              {subTopics &&
                subTopics.map((topic, index) => (
                  <li
                    key={index}
                    className={`mb-2 p-2 cursor-pointer hover-item rounded-lg flex justify-between ${selectedSubTopic === topic ? "bg-gray-500" : ""}`}
                    onClick={() => handleSubTopicSelect(topic)}
                  >
                    {topic}
                    {completedSubTopics[topic] && (
                      <Check
                        className="text-green-400 mt-1 rounded-full border border-green-400"
                        size={16}
                      />
                    )}
                  </li>
                ))}
            </ul>
          </div>

          <div className="lg:w-3/4 md:w-3/4 lg:p-4 md:p-4 flex flex-col lg:ml-10 md:ml-10 bg-gray-800 rounded-lg">
            <div className="p-4 text-white min-h-[200px]">
              {loading ? <BeatLoader color="#37c640" size={20} /> : content}
            </div>
            <div className="mt-4 px-4 flex justify-start">
              <div className="flex gap-4 bg-gray-800 rounded-lg text-white py-2 px-4 border border-white">
                <button
                  onClick={markAsComplete}
                  className="px-4 py-2 flex items-center text-green-400 hover:bg-gray-700 rounded-lg"
                >
                  Mark as complete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Study;
