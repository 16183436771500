import React, { useState } from "react";
import { MdOutlineModeComment } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp as solidThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { BsFillSaveFill, BsSave } from "react-icons/bs";
import { FaShare } from "react-icons/fa";
import apiService from "../../services/apiService";
import baseUrl from "../../baseUrl";
import { useSelector } from "react-redux";
import SharePostPopup from "../Widgets/SharePostPopup";
import CommentBox from "../CommentBox";
import SocialLikes from "../SocialLikes";

export const PostFooter = ({ post, index, saved = false }) => {
  const [isLiked, setIsLiked] = useState(post.likedByUser);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  const [commentCount, setCommentCount] = useState(post.commentCount);
  const [openCommentIndex, setOpenCommentIndex] = useState(null);
  const [likeAnimation, setLikeAnimation] = useState(false);
  const [isSaved, setIsSaved] = useState(saved ? saved : post.saved);
  const [isShared, setIsShared] = useState(false);
  const [sharePostWithThought, setSharePostWithThought] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const username = useSelector((state) => state.user.username);
  const isReshared = post.isReshared;

  const handleLikeClick = async () => {
    try {
      setTimeout(() => setLikeAnimation(false), 1000);
      setIsLiked(!isLiked);
      const response = await apiService.patch(
        `${baseUrl}/social/toggleLike/${post._id}`
      );
      if (!response) {
        throw new Error("Failed to toggle like");
      }
      if (isLiked) {
        setLikeCount((likeCount) => likeCount - 1);
      } else {
        setLikeCount((likeCount) => likeCount + 1);
      }
      setLikeAnimation(true);
    } catch (e) {
      console.log("Something went wrong!", e.message);
    }
  };

  const handleCommentClick = () => {
    setOpenCommentIndex(index === openCommentIndex ? null : index);
  };

  const handleSharePost = async (text) => {
    try {
      setLoading(true);
      const response = await apiService.post(`${baseUrl}/social/publish-post`, {
        postId: post._id,
        username: username,
        caption: text,
        isReshared: true,
      });
      if (response) {
        setIsShared(true);
        setSharePostWithThought(false);
        alert("🎉Post Shared Successfully!");
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleShare = () => {
    if (isReshared) {
      return;
    } else {
      setSharePostWithThought(true);
    }
  };
  const handleSave = async () => {
    try {
      setIsSaved(true);
      const response = await apiService.post(
        `${baseUrl}/social/savePost/${post._id}`,
        {
          username: username,
        }
      );
      if (response?.success) {
        if (response.message !== "Post saved successfully!") {
          setIsSaved(false);
        }
      }
    } catch (e) {
      setIsSaved(false);
      console.log(e.message);
    }
  };

  const handleShowLikes = () => {
    setSelectedPost(post._id);
  };
  const handleCloseLikes = () => {
    setSelectedPost(null);
  };

  return (
    <>
      <div className="like_comment">
        <p className="like_post" onClick={handleShowLikes}>
          {likeCount} Likes
        </p>
        <p className="comment_post" onClick={() => handleCommentClick()}>
          {" "}
          {commentCount} Comments
        </p>
      </div>
      <hr className="social_line" />
      <div className="somanyicon">
        <div className="likesjh" onClick={() => handleLikeClick()}>
          <FontAwesomeIcon
            icon={isLiked ? solidThumbsUp : faThumbsUp}
            className={`fontIvon ${isLiked ? "liked" : ""} ${likeAnimation ? "like-animation" : ""}`}
          />
          <p className="like_text hidden md:block">Like</p>
        </div>
        <div className="likesjh" onClick={() => handleCommentClick(index)}>
          <MdOutlineModeComment size={"1.25rem"} color="#ababab" />
          <p className="like_text hidden md:block">Comment</p>
        </div>

        <div className="likesjh" onClick={() => handleSave()}>
          {isSaved ? (
            <BsFillSaveFill color="#ababab" />
          ) : (
            <BsSave color="#ababab" />
          )}
          <p className="like_text hidden md:block">
            {isSaved ? "Saved" : "Save"}
          </p>
        </div>

        <div
          className={`likesjh relative`}
          style={{ cursor: isReshared ? "not-allowed" : "pointer" }}
          onClick={handleShare}
          title={isReshared ? "You can't share a shared post" : ""}
        >
          <FaShare color={isReshared ? "#545050" : "#ababab"} />
          <p
            className="like_text hidden md:block"
            style={{ color: isReshared ? "#545050" : "#ababab" }}
          >
            {isShared ? "Shared " : "Share "} ({post.shareCount})
          </p>
        </div>
      </div>
      {selectedPost ? (
        <SocialLikes selectedPost={selectedPost} onClose={handleCloseLikes} />
      ) : null}
      {openCommentIndex === index ? (
        <CommentBox
          postId={post._id}
          handleCommentCount={() =>
            setCommentCount((prevCount) => prevCount + 1)
          }
        />
      ) : null}
      {sharePostWithThought && (
        <SharePostPopup
          onClose={() => setSharePostWithThought(false)}
          handleSharePost={handleSharePost}
          sharingPost={post}
          loading={loading}
        />
      )}
    </>
  );
};
