import React, { useState, useEffect } from "react";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import "../styles/AvatarStore.css";
import { FaSearch } from "react-icons/fa"; // Import the search icon from react-icons

const AvatarStore = () => {
  const [avatars, setAvatars] = useState({
    trending: [],
    featured: [],
    productivity: [],
    category: [],
  });
  const [selectedCategory, setSelectedCategory] = useState("Trending");

  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await apiService.get(`${baseUrl}/avatar/getAllAvatar`); // Fetch avatars from the API
        console.log("Response: ", response);
        setAvatars(response); // Set avatars state with the fetched data
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars(); // Fetch avatars on component mount
  }, []);

  // Function to render content based on the selected category
  const renderCategoryContent = () => {
    const categoryAvatars = avatars[selectedCategory.toLowerCase()];

    return (
      <section
        className={`avatar-store-section avatar-store-${selectedCategory.toLowerCase()}-section`}
      >
        <h2 className="avatar-store-section-title">{selectedCategory}</h2>
        <p className="avatar-store-section-description">
          Lorem ipsum dolor sit amet.
        </p>
        <div className="avatar-store-avatar-list">
          {categoryAvatars.map((avatar, idx) => (
            <div className="avatar-store-avatar-card" key={idx}>
              <div className="avatar-store-avatar-image">
                {/* Generate and display the avatar image using DiceBear API */}
                <img src={avatar.avatarImage} alt={avatar.name} />
              </div>
              <div className="avatar-store-avatar-info">
                <h3 className="avatar-store-avatar-name">{avatar.name}</h3>
                <p className="avatar-store-avatar-description">
                  {avatar.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  };

  return (
    <div className="avatar-store-container">
      <div className="avatar-store-header">
        <h1 className="avatar-store-title">Avatar Store</h1>
        <div className="desc">
          <p className="avatar-store-description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad placeat
            aspernatur sint qui illum suscipit culpa.
          </p>
        </div>
        <div className="avatar-store-search-bar">
          <input
            type="text"
            placeholder="Search anything..."
            className="avatar-store-search-input"
          />
          <button className="avatar-store-search-button">
            <FaSearch />
          </button>
        </div>
        <div className="avatar-store-categories">
          {["Trending", "Featured", "Productivity", "Category"].map(
            (category) => (
              <div
                key={category}
                className={`avatar-store-category-button ${
                  selectedCategory === category ? "active" : ""
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </div>
            )
          )}
        </div>
      </div>

      {/* Render content based on the selected category */}
      {renderCategoryContent()}
    </div>
  );
};

export default AvatarStore;
