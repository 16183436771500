import { createSlice } from "@reduxjs/toolkit";

const postInitialState = {
  posts: [],
  userFollowing: [],
  postsPageNumber: 1,
  postsFetched: false,
};

export const postSlice = createSlice({
  name: "post",
  initialState: postInitialState,
  reducers: {
    setPosts: (state, action) => {
      state.posts = [...state.posts, ...action.payload.posts];
    },
    incrementPageNumber: (state, action) => {
      state.postsPageNumber += 1;
    },
    setPostsFetched: (state, action) => {
      state.postsFetched = action.payload.postsFetched;
    },
    addComments: (state, action) => {
      const { postId, comment } = action.payload;
      const updatedPosts = state.posts.map((post) => {
        if (post._id === postId) {
          return {
            ...post,
            comments: [...post.comments, comment],
            commentCount: post.commentCount + 1,
          };
        }
        return post;
      });
      state.posts = updatedPosts;
    },
    setPost: (state, action) => {
      state.posts = [action.payload.post, ...state.posts];
    },
    setFriends: (state, action) => {
      state.userFollowing = action.payload.friends;
    },
  },
});

export const {
  setPosts,
  setPost,
  addReply,
  setFriends,
  incrementPageNumber,
  setPostsFetched,
  addComments,
} = postSlice.actions;

export default postSlice.reducer;
