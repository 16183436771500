import React, { useState, useRef, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import apiService from "../services/apiService";
import "../styles/avatarYouAiChat.css";
import { IoMdClose, IoMdSend } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { RiChatNewLine } from "react-icons/ri";
import baseUrl from "../baseUrl";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import MainLogo from "../assets/Images/logo1.png";
import { setCredits } from "../features/userSlice";
import logoShare from "../assets/logoShare.png";
import boyShare from "../assets/boyShare.png";

// Helper function to determine the type of response
const getResponseType = (responseText) => {
  if (responseText.match(/https?:\/\/.*\.(jpg|jpeg|png|gif)/i)) {
    return "image";
  }
  if (responseText.match(/https?:\/\/.*\.(mp3|wav)/i)) {
    return "audio";
  }
  if (responseText.match(/https?:\/\/.*\.(mp4|webm)/i)) {
    return "video";
  }
  return "text";
};

const AvatarYouAiChat = () => {
  const [prompt, setPrompt] = useState("");
  const [responses, setResponses] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const chatContainerRef = useRef(null);
  const { id, sessionId: routeSessionId } = useParams();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState(null);
  const [avatarName, setAvatarName] = useState("");
  const dispatch = useDispatch();
  const [sessionId, setSessionId] = useState(routeSessionId);

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await apiService.get(
          `${baseUrl}/avatar/getSelectedDataAvatar?id=${id}`
        );
        setAvatar(response);
        setAvatarName(response.name);
      } catch (error) {
        console.error("Error fetching avatar data:", error);
      }
    };

    fetchAvatar();
  }, [id]);

  useEffect(() => {
    if (routeSessionId) {
      fetchSessionData(routeSessionId);
    }
  }, [routeSessionId]);

  const fetchSessionData = async (currentSessionId) => {
    try {
      if (!currentSessionId) return;

      setIsSubmitting(true);
      const response = await apiService.get(
        `${baseUrl}/avatar/getAvatar/${currentSessionId}`
      );
      const sessionData = response;
      console.log("Session Data:", sessionData);

      if (sessionData && sessionData.length > 0) {
        const formattedResponses = sessionData.map((message) =>
          mapResponse(message)
        );
        setResponses(formattedResponses);
      }
    } catch (error) {
      console.error("Error fetching session data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const mapResponse = (message) => {
    let responseType = "text";
    let content = message.response;

    if (message.DalleImageUrl) {
      responseType = "image";
      content = message.DalleImageUrl;
    } else if (message.audioUrl) {
      responseType = "audio";
      content = message.audioUrl;
    } else if (message.videoUrl) {
      responseType = "video";
      content = message.videoUrl;
    } else if (message.imageUrl) {
      responseType = "image";
      content = message.imageUrl;
    }

    return {
      prompt: message.prompt,
      response: content,
      type: responseType,
      isLoading: false,
    };
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setFileUrl("");
    setFile(null);
  };

  const handleSubmit = async () => {
    if (!prompt && !file) {
      return;
    }

    let currentSessionId = sessionId;

    // If there's no sessionId, create one first and update currentSessionId
    if (!currentSessionId) {
      currentSessionId = await handleCreateSession();
    }

    setIsSubmitting(true);

    const newPrompt = prompt;
    const newResponse = {
      prompt: newPrompt,
      imageUrl: fileUrl,
      response: "",
      type: "text",
      isLoading: true,
    };

    setResponses((prevResponses) => [...prevResponses, newResponse]);
    setPrompt("");
    setFileUrl("");
    setFile(null);

    const data = {
      id,
      prompt: newPrompt,
      file: fileUrl,
      sessionId: currentSessionId,
    };

    apiService
      .post(`${baseUrl}/avatar/sendPromptAndImageForYouAi`, data)
      .then((response) => {
        const responseText = response.responseText || JSON.stringify(response);
        console.log("responsetext: ", responseText);

        const responseType = getResponseType(responseText);

        setResponses((prevResponses) =>
          prevResponses.map((resp) =>
            resp.prompt === newPrompt
              ? {
                  ...resp,
                  response: responseText,
                  type: responseType,
                  isLoading: false,
                }
              : resp
          )
        );
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setResponses((prevResponses) =>
          prevResponses.map((resp) =>
            resp.prompt === newPrompt
              ? {
                  ...resp,
                  response: "Error occurred",
                  type: "text",
                  isLoading: false,
                }
              : resp
          )
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleCreateSession = async () => {
    try {
      const response = await apiService.post(`${baseUrl}/ai/createSession`);
      const newSessionId = response.session.id;

      setSessionId(newSessionId);
      setResponses([]);

      const credits = response.credits;
      dispatch(setCredits(credits));

      navigate(`/avatarYouAi/${id}/${newSessionId}`, { replace: true });

      return newSessionId;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          onClose: () => navigate("/subscription"),
        });
      } else {
        console.error("Error creating a new session:", error.message);
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [responses]);

  return (
    <div className="avataryouai">
      <h1 className="lloggoo">{avatarName}</h1>
      <div className="avatarpanel">
        <div className="chat-container" ref={chatContainerRef}>
          {responses.length === 0 ? (
            <div className="main-logo-container">
              <img src={MainLogo} alt="Main Logo" className="main-logo" />
            </div>
          ) : (
            responses.map((resp, index) => (
              <div key={index} className="chat-message">
                <div className="onecontain11">
                  {resp.prompt && (
                    <div className="chat-prompt1">{resp.prompt}</div>
                  )}
                  <img src={boyShare} alt="boy" className="loop1" />
                </div>
                {resp.imageUrl && (
                  <div className="selected-image1">
                    <img
                      src={resp.imageUrl}
                      alt="Selected"
                      className="uimahesele"
                    />
                  </div>
                )}
                <div className="onecontain22">
                  <img src={logoShare} alt="logo" className="loop2" />
                  {resp.isLoading ? (
                    <div className="chat-response1">
                      <PulseLoader size={10} color={"#6cd97e"} />
                    </div>
                  ) : (
                    <div className="chat-response1">
                      {resp.type === "text" ? (
                        <p>{resp.response}</p>
                      ) : resp.type === "audio" ? (
                        <audio controls>
                          <source src={resp.response} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                      ) : resp.type === "image" ? (
                        <img
                          src={resp.response}
                          alt="Generated Image"
                          className="chatimgg"
                        />
                      ) : resp.type === "video" ? (
                        <video controls>
                          <source src={resp.response} type="video/mp4" />
                          Your browser does not support the video element.
                        </video>
                      ) : (
                        <p>Unknown response type</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
        <div className="input-bar1">
          <RiChatNewLine
            className="newjcuj"
            size={22}
            onClick={handleCreateSession}
          />
          <input
            type="text"
            placeholder="Enter your prompt..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          {file ? (
            <div className="image-preview-container1">
              <img src={fileUrl} alt="Selected" className="image-preview1" />
              <IoMdClose
                className="remove-image-icon1"
                onClick={handleRemoveImage}
                size={19}
              />
            </div>
          ) : (
            <label htmlFor="fileInput" className="upload-icon">
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <MdAttachFile size={22} className="uploadiconn" />
            </label>
          )}
          <button
            onClick={async () => {
              if (!sessionId) {
                await handleCreateSession();
              }

              handleSubmit();
            }}
          >
            <IoMdSend size={22} className="sendicok" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarYouAiChat;
