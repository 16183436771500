import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import BeatLoader from 'react-spinners/BeatLoader';

const WorkoutForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [weight, setWeight] = useState('');
  const [weightUnit, setWeightUnit] = useState('kg');
  const [height, setHeight] = useState('');
  const [heightUnit, setHeightUnit] = useState('cm');
  const [fitnessLevel, setFitnessLevel] = useState('');
  const [fitnessGoal, setFitnessGoal] = useState('');
  const [workoutDays, setWorkoutDays] = useState('');
  const [trainingEnvironment, setTrainingEnvironment] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    if (!gender || !age || !weight || !height || !fitnessLevel || !fitnessGoal || !workoutDays || !trainingEnvironment) {
      toast.error('Please fill all the fields');
      return;
    }

    setLoading(true);
    try {
      const response = await apiService.post('/ai/generate-workoutplan', {
        gender, age, weight, height, heightUnit, weightUnit, fitnessLevel, fitnessGoal, trainingEnvironment, workoutDays
      });

      if (response.status == 'success') {
        console.log(response.obj)
        navigate('/tools/workout-list', { state: { workoutPlan: response.obj } });
      }
    } catch (error) {
      console.log('Erorr:', error);
    } finally {
      setLoading(false);
    }

  };

  return (
    <div className="flex w-full">
      {loading && (
        <div className="fixed inset-0 bg-gray-300 bg-opacity-50 flex justify-center items-center">
          <BeatLoader color="#ffffff" />
        </div>
      )}
      <div className='flex flex-grow flex-col items-center p-7'>
        <h1 className='text-white font-bold text-2xl mb-2'>Workout Plan Generator</h1>
        <p className='flex justify-center text-white mb-6'>Answer the questions below to get started</p>
        <form className="block w-full max-w-md">

          {/* Row 1 */}
          <div className='flex flex-wrap justify-between lg:gap-16 md:gap-16'>
            {/* Gender */}
            <div className="mb-6">
              <label htmlFor="gender" className="block text-sm font-medium leading-5 text-white">
                Gender
              </label>
              <select
                id="gender"
                name="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="lg:px-8 px-1 py-[10px] text-base text-white border border-gray-500 leading-6 rounded-md shadow-sm sm:text-sm sm:leading-5 bg-transparent"
              >
                <option value="" disabled>Select gender</option>
                <option value="male" className='text-black'>Male</option>
                <option value="female" className='text-black'>Female</option>
                <option value="other" className='text-black'>Other</option>
              </select>
            </div>

            {/* Age */}
            <div className="mb-6">
              <label htmlFor="age" className="block text-sm font-medium leading-5 text-white">
                Age
              </label>
              <input
                id="age"
                name="age"
                type="number"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                className="bg-transparent text-white border border-gray-500 lg:px-3 px-1 py-[8px] w-full text-base leading-6 rounded-md shadow-sm focus:outline-none sm:text-sm sm:leading-5"
                placeholder="Enter your age"
              />
            </div>
          </div>

          {/* Row 2 */}
          <div className='flex justify-center gap-10'>
            {/* Weight */}
            <div className="mb-6 flex-grow">
              <label htmlFor="weight" className="text-sm font-medium leading-5 text-white">
                Body Weight
              </label>
              <div className="mt-1 flex gap-2">
                <input
                  id="weight"
                  name="weight"
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  className="bg-transparent text-white border border-gray-500 w-full pl-3 py-[10px] text-base leading-6 rounded-md shadow-sm sm:text-sm sm:leading-5"
                  placeholder="Weight"
                />
                <select
                  id="weightUnit"
                  name="weightUnit"
                  value={weightUnit}
                  onChange={(e) => setWeightUnit(e.target.value)}
                  className="bg-transparent text-white border border-gray-500 w-full pl-3 py-[10px] text-base leading-6 rounded-md shadow-sm sm:text-sm sm:leading-5"
                >
                  <option value="kg" className='text-black'>kg</option>
                  <option value="lbs" className='text-black'>lbs</option>
                </select>
              </div>
            </div>

            {/* Height */}
            <div className="mb-6 flex-grow">
              <label htmlFor="height" className="block text-sm font-medium leading-5 text-white">
                Height
              </label>
              <div className="mt-1 flex gap-2">
                <input
                  id="height"
                  name="height"
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  className="bg-transparent text-white border border-gray-500 w-full pl-3 py-[10px] text-base leading-6 rounded-md sm:text-sm sm:leading-5"
                  placeholder="Height"
                />
                <select
                  id="heightUnit"
                  name="heightUnit"
                  value={heightUnit}
                  onChange={(e) => setHeightUnit(e.target.value)}
                  className="bg-transparent text-white border border-gray-500 w-full pl-3 py-[10px] text-base leading-6 rounded-md sm:text-sm sm:leading-5"
                >
                  <option value="cm" className='text-black'>cm</option>
                  <option value="ft" className='text-black'>ft</option>
                </select>
              </div>
            </div>
          </div>

          {/* Row 3 */}
          <div className="w-full mb-6">
            <label htmlFor="fitness-level" className="text-sm font-medium leading-5 text-white">
              Fitness Level
            </label>
            <select
              id="fitness-level"
              name="fitnessLevel"
              value={fitnessLevel}
              onChange={(e) => setFitnessLevel(e.target.value)}
              className="mt-1 bg-transparent text-white border border-gray-500 w-full pl-3 pr-10 py-[10px] text-base leading-6 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            >
              <option value="" disabled>Select your fitness level</option>
              <option value="beginner" className='text-black'>Beginner - New to exercise</option>
              <option value="intermediate" className='text-black'>Intermediate - Exercise regularly</option>
              <option value="advanced" className='text-black'>Advanced - Exercise daily</option>
              <option value="athlete" className='text-black'>Athlete - Training for competitions</option>
            </select>
          </div>

          {/* Row 4 */}
          <div className="w-full mb-6">
            <label htmlFor="fitness-goal" className="text-sm font-medium leading-5 text-white">
              Fitness Goal
            </label>
            <select
              id="fitness-goal"
              name="fitnessGoal"
              value={fitnessGoal}
              onChange={(e) => setFitnessGoal(e.target.value)}
              className="mt-1 bg-transparent text-white border border-gray-500 w-full pl-3 pr-10 py-[10px] text-base leading-6 rounded-md sm:text-sm sm:leading-5"
            >
              <option value="" disabled>Select your fitness goal</option>
              <option value="lose-weight" className='text-black'>Lose Weight - Reduce body weight</option>
              <option value="build-muscle" className='text-black'>Build Muscle - Increase muscle mass</option>
              <option value="improve-stamina" className='text-black'>Improve Stamina - Boost endurance and cardio</option>
              <option value="flexibility" className='text-black'>Increase Flexibility - Enhance range of motion</option>
            </select>
          </div>

          {/* Row 5 */}
          <div className="w-full mb-6">
            <label htmlFor="workout-days" className="text-sm font-medium leading-5 text-white">
              Workout Days per Week
            </label>
            <select
              id="workout-days"
              name="workoutDays"
              value={workoutDays}
              onChange={(e) => setWorkoutDays(e.target.value)}
              className="mt-1 bg-transparent text-white border border-gray-500 w-full pl-3 pr-10 py-[10px] text-base leading-6 rounded-md sm:text-sm sm:leading-5"
            >
              <option value="" disabled>Select days per week</option>
              <option value="1" className='text-black'>1 Day</option>
              <option value="2" className='text-black'>2 Days</option>
              <option value="3" className='text-black'>3 Days</option>
              <option value="4" className='text-black'>4 Days</option>
              <option value="5" className='text-black'>5 Days</option>
              <option value="6" className='text-black'>6 Days</option>
              <option value="7" className='text-black'>7 Days - Everyday</option>
            </select>
          </div>

          {/* Row 6 */}
          <div className="w-full mb-6">
            <label htmlFor="equipment-available" className="block text-sm font-medium leading-5 text-white">
              Training environment
            </label>
            <select
              id="training-environment"
              name="trainingEnvironment"
              value={trainingEnvironment}
              onChange={(e) => setTrainingEnvironment(e.target.value)}
              className="mt-1 bg-transparent text-white border border-gray-500 w-full pl-3 pr-10 py-[10px] text-base leading-6 rounded-md sm:text-sm sm:leading-5"
            >
              <option value="">Select Training Environment</option>
              <option value="indoor" className='text-black'>Indoor</option>
              <option value="outdoor" className='text-black'>Outdoor</option>
              <option value="gym" className='text-black'>Gym</option>
              <option value="home" className='text-black'>Home</option>
            </select>
          </div>

          <div className='bg-[#6cd97e] rounded-full py-2 mt-6'>
            <input
              type="submit"
              value="Next"
              className='flex justify-center items-center w-full rounded-xl  cursor-pointer'
              onClick={handleSubmit}
            />
          </div>

        </form>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default WorkoutForm;
