import { createSlice } from "@reduxjs/toolkit";

const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    avatarId: null,
  },
  reducers: {
    setAvatarId: (state, action) => {
      state.avatarId = action.payload;
    },
    clearAvatarId: (state) => {
      state.avatarId = null;
    },
  },
});

export const { setAvatarId, clearAvatarId } = avatarSlice.actions;

export default avatarSlice.reducer;
