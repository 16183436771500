import React, { useState } from 'react';
import { ArrowRightCircle, Save, Share2, Download, Delete, Trash, Flag } from 'react-feather';
import apiService from '../../services/apiService';
import { BeatLoader } from 'react-spinners';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const types = ["Minimalist", "Futuristic", "Modern", "Timeless", "Classic", "Bold"];

const LogoMaker = () => {
  const [selectedType, setSelectedType] = useState('Minimalist');
  const [companyName, setCompanyName] = useState('');
  const [isNameLoading, setIsNameLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const generateRandomCompanyName = async () => {
    try {
      setIsNameLoading(true);

      const response = await apiService.post("/ai/generate-logo", {
        flag: 'random'
      })
      if (response.status == 'success') {
        setCompanyName(response.result);
      }
      setIsNameLoading(false);
    } catch (error) {
      console.log('Error:', error);
      setIsNameLoading(false);
    }
  }

  const generateCompanyLogo = async () => {
    try {
      setIsImageLoading(true);

      if (!companyName.trim()) {
        toast.error("Please enter a company name.");
        setIsImageLoading(false);
        return;
      }
      
      const response = await apiService.post("/ai/generate-logo", {
        companyName,
        flag: 'image',
        type: selectedType,
      })
      if (response.status == 'success') {
        setImageUrl(response.result);
      }

      setIsImageLoading(false);
    } catch (error) {
      console.log('Error:', error);
      setIsImageLoading(false);
    } finally {
      setIsImageLoading(false); 
    }
  }

  const handleDeleteImage = () => {
    setImageUrl("");
  }

  const handleDownload = () => {

  };

  return (
    <div className='flex flex-col sm:flex-row p-6 h-screen'>
      <div className='flex-1 sm:w-1/2 p-3'>

        {/* First component for the logo */}
        <h1 className='text-white font-bold text-xl my-2'>Logo Generator</h1>

        {/* Generate or input header */}
        <div className='py-2 my-4'>
          <div className='flex justify-between items-center text-white'>
            <p className='font-bold'>Input Prompt</p>
            <p
              className='underline cursor-pointer text-gray-300 hover:text-green-300 lg:text-base md:text-base text-sm'
              onClick={generateRandomCompanyName}
            >
              Generate a random prompt?
            </p>
          </div>
        </div>

        {/* Text area for text */}
        <div>
          {/* Loader for generating random company name  */}
          {isNameLoading &&
            <div className='flex justify-center items-center'>
              <BeatLoader color="#6cd97e" />
            </div>
          }

          {/* Text Area */}
          <textarea
            type="text"
            placeholder='Name of your company i.e. TensorBlue AI'
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className='w-full bg-gray-800 px-5 py-5 rounded-lg text-white focus:outline-none'
            disabled={isNameLoading}
          />
        </div>

        {/* Different types of button */}
        <div className='flex flex-wrap gap-5 mt-6'>
          {types.map(type => (
            <button
              key={type}
              className={`py-2 px-4 rounded-xl text-sm font-semibold ${selectedType === type ? 'border border-green-400 text-white' : 'border border-gray-500 text-white'
                }`}
              onClick={() => setSelectedType(type)}
            >
              {type}
            </button>
          ))}
        </div>

        {/* Submit button */}
        <button
          onClick={generateCompanyLogo}
          className={`flex items-center gap-1 mt-8 py-2 px-4 bg-[#6cd97e] hover:bg-green-300 text-blue rounded-full font-bold ${isImageLoading ? 'cursor-not-allowed': ''}`}
        >
          Submit <ArrowRightCircle />
        </button>
      </div>

      <div className='sm:w-1/2 flex flex-col lg:p-10 md:p-10 p-3  mt-10'>

        {/* Heading for the right-side output */}
        {/* <h2 className='text-white font-bold text-base p-5'>Output</h2> */}

        {/* Generated content */}
        <div className='flex-grow bg-gray-800 rounded-lg p-4 mb-4 lg:h-auto lg:w-auto md:h-auto md:w-auto h-[310px]'>
          {isImageLoading ?
            <div className='flex flex-col justify-center items-center'>
              <BeatLoader color="#6cd97e" />
              <p className='text-white text-sm font-bold'>Generating logo....</p>
            </div>
            :
            imageUrl ?
              <img src={imageUrl} alt="Generated Logo" className='max-w-full h-auto' />
              :
              <p className='text-gray-400 text-center'>Your generated logo will appear here</p>
          }
        </div>

        {/* Different buttons for the content */}
        <div className='flex justify-start gap-5'>
          <button
            onClick={handleDownload}
            className='text-green-500 hover:text-green-300 bg-gray-800 py-2 px-4 rounded-full'>
            <Download size={24} />
          </button>
          <button className='text-green-500 hover:text-green-300 bg-gray-800 py-2 px-4 rounded-full'>
            <Share2 size={24} />
          </button>
          <button
            className='text-green-500 hover:text-green-300 bg-gray-800 py-2 px-4 rounded-full'
            onClick={handleDeleteImage}>
            <Trash size={24} />
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default LogoMaker;
