import React, { useState, useEffect } from "react";
import "../styles/social_feature.css";
import like from "../assets/thumb_up.png";
import close from "../assets/close_small.png";
import { UserWidget } from "./Widgets/UserWidget";
import baseUrl from "../baseUrl";
import { VscLoading } from "react-icons/vsc";
import apiService from "../services/apiService";

const SocialLikes = ({ selectedPost, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [postLikes, setPostLikes] = useState([]);

  const fetchLikes = async () => {
    if (!selectedPost) return;
    setLoading(true);
    try {
      const response = await apiService.get(
        `${baseUrl}/social/get-likes/${selectedPost}`
      );
      if (response) {
        setPostLikes(response.data);
      } else {
        throw new Error("Success not found!");
      }
    } catch (e) {
      console.log("Something went wrong", e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLikes();
  }, [selectedPost]);

  return (
    <>
      <div className="post_popup">
        <div className="like_popup">
          <div className="like_cancel">
            <div className="likesjh">
              <img src={like} alt="mypost" className="postimgdhbl" />
              <p className="like_text1">Likes</p>
            </div>
            <img
              src={close}
              alt="close"
              className="like_close"
              onClick={onClose}
            />
          </div>
          <hr className="social_line" />
          <div className="follow_people1">
            {loading ? (
              <div className="w-full flex justify-center">
                <VscLoading
                  className="loading-animation"
                  size={30}
                  color="white"
                />
              </div>
            ) : postLikes?.length === 0 ? (
              <p className="search-msg">This post has no likes</p>
            ) : (
              postLikes.map((user, id) => <UserWidget user={user} key={id} />)
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialLikes;
