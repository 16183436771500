import React, { useState, useRef, useEffect } from "react";
import { FaPaperPlane, FaUpload } from "react-icons/fa";
import { PulseLoader } from "react-spinners";
import apiService from "../services/apiService";
import "../styles/rightPanelAvatar.css";
import { AiOutlineLock } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import SyntaxHighlighter from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from "react-copy-to-clipboard";
import { LuCopy } from "react-icons/lu";
import baseUrl from "../baseUrl";
import AvatarViewer from "./AvatarViewer"; // Import the new component
import MainLogo from "../assets/Images/logo1.png"; // Import the MainLogo image
import { getCookie } from "../utils/methods";

// Helper function to determine the type of response
const getResponseType = (responseText) => {
  if (responseText.match(/https?:\/\/.*\.(jpg|jpeg|png|gif)/i)) {
    return "image";
  }
  if (responseText.match(/https?:\/\/.*\.(mp3|wav)/i)) {
    return "audio";
  }
  if (responseText.match(/https?:\/\/.*\.(mp4|webm)/i)) {
    return "video";
  }
  return "text";
};

const RightPanel = ({ rightPanelData, disabled }) => {
  const [prompt, setPrompt] = useState("");
  const [responses, setResponses] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChatMode, setIsChatMode] = useState(true); // State to toggle between chat and avatar mode
  const [isAvatarLoading, setIsAvatarLoading] = useState(false); // State to manage avatar loading
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const chatContainerRef = useRef(null);
  const token = getCookie("token");

  const [dotCount, setDotCount] = useState(0); // State for dot animation
  // Dot animation effect

  useEffect(() => {
    if (isAvatarLoading) {
      const interval = setInterval(() => {
        setDotCount((prevDotCount) => (prevDotCount + 1) % 4); // Cycle between 0 and 3 dots
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isAvatarLoading]);

  // Client-side code to handle SSE
  const establishSSEConnection = () => {
    // Ensure the base URL is set correctly based on your environment
    const eventSource = new EventSource(
      `${baseUrl}/avatar/events?token=${encodeURIComponent(token)}`
    );

    eventSource.onmessage = (event) => {
      const message = JSON.parse(event.data);

      if (message.message === "Lip-sync data generated successfully") {
        setIsAvatarLoading(false); // Stop loading when lip-sync data is received
        setErrorMessage(""); // Clear any previous error message
        console.log("Lip-sync data:", message.data);
      } else if (message.errorMessage) {
        setIsAvatarLoading(false);
        setErrorMessage(message.errorMessage); // Set the error message
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE error: ", error);
      // Handle reconnection if needed
      setTimeout(() => {
        console.log("Attempting to reconnect to SSE...");
        establishSSEConnection(); // Reconnect after delay
      }, 5000);
    };

    return eventSource;
  };

  // Establish the SSE connection on component load
  useEffect(() => {
    const sseConnection = establishSSEConnection();

    return () => {
      sseConnection.close(); // Close SSE connection on component unmount
    };
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  function formatChatResponse(response) {
    if (!response) {
      return "";
    }

    const boldTextRegex = /\*\*(.*?)\*\*/g;
    response = response.replace(boldTextRegex, "<strong>$1</strong>");

    const codeBlockRegex = /```([\s\S]*?)```/g;
    let match;
    let lastIndex = 0;
    const contentBlocks = [];

    while ((match = codeBlockRegex.exec(response)) !== null) {
      const codeBlockStart = match.index;
      const codeBlockEnd = codeBlockStart + match[0].length;
      const nonCodeBlockText = response.substring(lastIndex, codeBlockStart);

      if (nonCodeBlockText.trim().length > 0) {
        const paragraphs = nonCodeBlockText.split(/\n\s*\n/);
        const mergedParagraphs = paragraphs
          .filter((p) => p.trim().length > 0)
          .join("<br><br>");
        if (mergedParagraphs.trim().length > 0) {
          contentBlocks.push(
            <p
              key={contentBlocks.length}
              className="text-base font-normal"
              dangerouslySetInnerHTML={{ __html: mergedParagraphs }}
            />
          );
        }
      }

      contentBlocks.push(
        <div
          key={contentBlocks.length}
          className="code-block-container1"
          style={{
            maxWidth: "475px",
            marginLeft: "49px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <CopyToClipboard text={match[1]}>
            <span className="copy_icon3">
              <LuCopy className="kklp" /> Copy Code
            </span>
          </CopyToClipboard>
          <SyntaxHighlighter language="python" style={prism}>
            {match[1]}
          </SyntaxHighlighter>
        </div>
      );

      lastIndex = codeBlockEnd;
    }

    const remainingText = response.substring(lastIndex);
    if (remainingText.trim().length > 0) {
      const paragraphs = remainingText.split(/\n\s*\n/);
      const formattedParagraphs = paragraphs
        .map((paragraph) => {
          return paragraph
            .split("\n")
            .map((line, index) =>
              line.trim().startsWith("-") && index > 0
                ? `- ${line.trim()}`
                : line.trim()
            )
            .join("<br>");
        })
        .join("<br><br>");

      if (formattedParagraphs.trim().length > 0) {
        contentBlocks.push(
          <p
            key={contentBlocks.length}
            className="text-base font-normal"
            dangerouslySetInnerHTML={{ __html: formattedParagraphs }}
          />
        );
      }
    }

    return <div>{contentBlocks}</div>;
  }

  const handleRemoveImage = () => {
    setFileUrl("");
    setFile(null);
  };

  const handleSubmit = () => {
    if (!prompt && !file) {
      return;
    }

    setIsSubmitting(true);

    const newPrompt = prompt;
    const newResponse = {
      prompt: newPrompt,
      imageUrl: fileUrl, // Include the file URL here
      response: "",
      type: "text",
      isLoading: true,
    };

    setResponses((prevResponses) => [...prevResponses, newResponse]);
    setPrompt("");
    setFileUrl(""); // Clear the file URL
    setFile(null); // Clear the file

    const data = {
      id: rightPanelData._id,
      prompt: newPrompt,
      file: fileUrl,
    };

    setIsAvatarLoading(true); // Start loading avatar when submitting

    apiService
      .post(`${baseUrl}/avatar/sendPromptAndImage`, data)
      .then((response) => {
        console.log("response: ", response);
        let responseText;

        // Check if the response contains an error
        if (response.error) {
          responseText = response.error; // Extract the error message
        } else {
          responseText = response.responseText || JSON.stringify(response);
        }
        const responseType = getResponseType(responseText);

        setResponses((prevResponses) =>
          prevResponses.map((resp) =>
            resp.prompt === newPrompt
              ? {
                  ...resp,
                  response: responseText,
                  type: responseType,
                  isLoading: false,
                }
              : resp
          )
        );
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setResponses((prevResponses) =>
          prevResponses.map((resp) =>
            resp.prompt === newPrompt
              ? {
                  ...resp,
                  response: "Error occurred",
                  type: "text",
                  isLoading: false,
                }
              : resp
          )
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [responses]);

  return (
    <div className="right-panel">
      {disabled && (
        <div className="center-icon">
          <AiOutlineLock size={120} color={"#fff"} />
        </div>
      )}

      {/* Mode Switcher */}
      {!disabled && (
        <div className="mode-switcher">
          <button
            onClick={() => setIsChatMode(true)}
            className={isChatMode ? "active" : ""}
          >
            Chat
          </button>
          <button
            onClick={() => setIsChatMode(false)}
            className={!isChatMode ? "active" : ""}
          >
            Avatar
          </button>
        </div>
      )}
      <div className={`right-panels ${disabled ? "blurred" : ""}`}>
        {isChatMode ? (
          <>
            <div className="chat-container" ref={chatContainerRef}>
              {responses.length === 0 ? (
                <div className="main-logo-container">
                  <img src={MainLogo} alt="Main Logo" className="main-logo" />
                </div>
              ) : (
                responses.map((resp, index) => (
                  <div key={index} className="chat-message">
                    <div className="onecontain"></div>
                    {resp.prompt && (
                      <div className="chat-prompt">{resp.prompt}</div>
                    )}

                    {/* Display selected image immediately below the prompt */}
                    {resp.imageUrl && (
                      <div className="selected-image1">
                        <img
                          src={resp.imageUrl}
                          alt="Selected"
                          className="uimahesele"
                        />
                      </div>
                    )}

                    {/* Conditionally render loader or chat response */}
                    {resp.isLoading ? (
                      <div className="loader-contnnainer">
                        <PulseLoader size={12} color={"#6cd97e"} />
                      </div>
                    ) : (
                      <div className="chat-response">
                        {resp.type === "text" ? (
                          <p>{formatChatResponse(resp.response)}</p>
                        ) : resp.type === "audio" ? (
                          <audio controls>
                            <source src={resp.response} type="audio/mp3" />
                            Your browser does not support the audio element.
                          </audio>
                        ) : resp.type === "image" ? (
                          <img src={resp.response} alt="Response" />
                        ) : resp.type === "video" ? (
                          <video controls>
                            <source src={resp.response} type="video/mp4" />
                            Your browser does not support the video element.
                          </video>
                        ) : (
                          <p>Unknown response type</p>
                        )}
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
            <div className="input-flext">
              <div className="input-bar">
                <input
                  type="text"
                  placeholder="Enter your prompt..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyPress={handleKeyPress}
                  disabled={disabled}
                />
                {file ? (
                  <div className="image-preview-container1">
                    <img
                      src={fileUrl}
                      alt="Selected"
                      className="image-preview1"
                    />
                    <IoMdClose
                      className="remove-image-icon1"
                      onClick={handleRemoveImage}
                      size={19}
                    />
                  </div>
                ) : (
                  <label htmlFor="fileInput" className="upload-icon">
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      disabled={disabled}
                    />
                    <FaUpload size={19} />
                  </label>
                )}
                <button
                  onClick={handleSubmit}
                  disabled={(!prompt && !file) || disabled || isSubmitting}
                >
                  <FaPaperPlane size={19} />
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            {isAvatarLoading ? (
              <div className="loaderaaa">
                <p className="loadfindhghd">
                  Generating Avatar{".".repeat(dotCount)}
                </p>
              </div>
            ) : errorMessage ? (
              <div className="mesgh">
                <div className="error-message1">{errorMessage}</div>
              </div>
            ) : (
              <div className="avatr-viewwer">
                <AvatarViewer />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RightPanel;
