import React, { useEffect, useRef, useState } from "react";
import apiService from "../../services/apiService";
import baseUrl from "../../baseUrl";
import { useLocation } from "react-router-dom";
import { SocialLeft } from "../SocialLeft";
import SocialRight from "../SocialRight";
import { ClipLoader } from "react-spinners";
import MobileNavBar from "../../components/MobileNavbar";
import { SharedPost } from "../Widgets/SharedPost";
import { PostWidgetAI } from "../Widgets/PostWidgetAI";
import { PostWidgetConversation } from "../Widgets/PostWidgetConversation";
import { PostWidget } from "../Widgets/PostWidget";
import { UserWidget } from "../Widgets/UserWidget";
import { IoSearch } from "react-icons/io5";

export const SearchResults = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchq = queryParams.get("q");
  const trendingRef = useRef();
  const [searchRes, setSearchRes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showMobileNavBar, setShowMobileNavBar] = useState(false);
  const lastScrollTopRef = useRef(0);
  const [searchQuery, setSearchQuery] = useState(searchq);

  const handleKeywordSearch = async (query) => {
    try {
      setIsLoading(true);
      const response = await apiService.get(
        `${baseUrl}/social/search-keyword?q=${query}`
      );
      if (response) {
        console.log(response);
        setSearchRes(response.searchRes);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    const handleScroll = () => {
      if (trendingRef.current) {
        const currentScrollTop = trendingRef.current.scrollTop;

        if (currentScrollTop < lastScrollTopRef.current) {
          setShowMobileNavBar(window.innerWidth < 465);
        } else {
          setShowMobileNavBar(false);
        }

        lastScrollTopRef.current = currentScrollTop;
      }
    };

    const handleResize = () => {
      setShowMobileNavBar(window.innerWidth < 465);
    };

    const trendingDiv = trendingRef.current;
    if (trendingDiv) {
      const debounceScroll = debounce(handleScroll, 50);
      trendingDiv.addEventListener("scroll", debounceScroll);

      window.addEventListener("resize", handleResize);

      return () => {
        trendingDiv.removeEventListener("scroll", debounceScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (searchQuery) {
        await handleKeywordSearch(searchQuery);
      }
    };

    fetchData();
  }, [searchQuery]);

  return (
    <div className="social-container">
      <div className="social_feature_container">
        <SocialLeft />
        <div className={`social_center_trending md:flex`} ref={trendingRef}>
          <div className="posting_sectioh">
            <div
              className="modal-search-box mb-4 "
              style={{ justifyContent: "start", gap: "1rem" }}
            >
              <IoSearch size={"1.25rem"} color="#a2a2a2" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search any keyword like 'genai'"
              />
            </div>
            <hr />

            {isLoading && (
              <div className="mt-6 w-full flex justify-center items-center">
                <ClipLoader color="white" />
              </div>
            )}

            {searchRes?.userResults?.length !== 0 && (
              <div className="p-4 mt-4 rounded-lg bg-[#1b2333]">
                <p className="text-[18px] font-bold text-white ">People</p>
                <p className="text-xs font-normal text-white ">
                  related to word "{searchQuery}"
                </p>

                {searchRes?.userResults?.map((user, index) => (
                  <div className="p-4">
                    <UserWidget key={index} user={user} />
                  </div>
                ))}
              </div>
            )}

            {searchRes?.postResults?.map((post, index) => {
              const PostComponent = post.isReshared
                ? SharedPost
                : post?.type === "ai"
                  ? PostWidgetAI
                  : post?.type === "conversation"
                    ? PostWidgetConversation
                    : PostWidget;

              return (
                <PostComponent
                  key={post._id || `${post.type}-${index}`}
                  index={index}
                  post={post}
                  //   setModalIsOpen={setModalIsOpen}
                  //   setSelectedImage={setSelectedImage}
                />
              );
            })}

            {searchRes?.userResults?.length === 0 &&
              searchRes?.postResults?.length === 0 && (
                <div className="flex mt-4 justify-center items-center w-full">
                  <p className="text-[18px] font-bold text-[#424e67] ">
                    No results found with "{searchQuery}" keyword
                  </p>
                </div>
              )}

            {showMobileNavBar && <MobileNavBar />}
          </div>
        </div>
        <SocialRight />
      </div>
    </div>
  );
};
