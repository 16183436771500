import { createSlice } from "@reduxjs/toolkit";

const chatInitialState = {
  selectedChat: {},
  chats: [],
};

export const chatSlice = createSlice({
  name: "socialChat",
  initialState: chatInitialState,
  reducers: {
    selectChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    addMessage: (state, action) => {
      const { chatId, message } = action.payload;
      const chat = state.chats.find((chat) => chat.id === chatId);
      if (chat) {
        chat.messages.push(message);
      }
    },
    addChat: (state, action) => {
      const chat = action.payload;
      const isExists = state.chats.find((item) => item.id === chat.id);
      if (!isExists) {
        state.chats.push(action.payload);
      }
    },
  },
});

export const { selectChat, addMessage, addChat } = chatSlice.actions;

export default chatSlice.reducer;
