import React, { useState, useEffect } from 'react';
import { RefreshCcw, PlusCircle, Trash2 } from 'react-feather';
import { useLocation } from 'react-router-dom';

const WorkoutList = () => {
    const location = useLocation();
    const workoutPlan = location.state?.workoutPlan || {};

    const [days, setDays] = useState(Object.keys(workoutPlan));
    const [selectedDay, setSelectedDay] = useState(days[0] || 'Day 1');
    const [exercises, setExercises] = useState([]);

    // Assuming the incoming data needs parsing to fit the expected structure
    const parseExercises = (exerciseList) => {
        return exerciseList.map((description) => {
            // Example parse: "Jogging for 20 minutes" -> {name: "Jogging", sets: 1, reps: "20 minutes"}
            const parts = description.split(' for ');
            return {
                name: parts[0],
                sets: 1, // Default sets to 1 if not specified
                reps: parts[1] || '1 set' // Use the part after 'for' or default text
            };
        });
    };

    useEffect(() => {
        // Update exercises when selectedDay changes
        if (workoutPlan[selectedDay]) {
            setExercises(parseExercises(workoutPlan[selectedDay]));
        } else {
            setExercises([]);
        }
    }, [selectedDay, workoutPlan]);

    const addExercise = () => {
        setExercises([...exercises, { name: '', sets: 5, reps: '6 reps' }]);
    };

    const deleteExercise = (index) => {
        setExercises(exercises.filter((_, idx) => idx !== index));
    };

    return (
        <div className='flex w-full'>
            <div className='flex-grow'>
                <div className="min-h-screen p-4 lg:p-8">
                    <h1 className="text-xl font-bold mb-4 text-white">Workout Plan Generator</h1>
                    <div className="flex flex-wrap gap-4 mb-4">
                        {days.map((day) => (
                            <button
                                key={day}
                                className={`py-2 px-4 rounded-lg text-gray-300 hover:text-green-400 hover:underline ${selectedDay === day ? 'text-green-400 underline font-bold' : ''}`}
                                onClick={() => setSelectedDay(day)}
                            >
                                {day}
                            </button>
                        ))}
                    </div>

                    <div className="p-4 rounded-lg">
                        <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
                            <h2 className="text-md font-bold text-white">
                                {selectedDay} - Workout Details
                            </h2>
                            {/* Need a figma design for adding more workouts */}
                            {/* <button
                                onClick={addExercise}
                                className="flex items-center mt-4 lg:mt-0 text-green-400 border border-green-400 font-bold py-2 px-4 rounded hover:bg-green-400 hover:text-black"
                            >
                                <PlusCircle className="mr-2" size={20} />
                                Add more
                            </button> */}
                        </div>

                        {exercises.map((exercise, index) => (
                            <div key={index} className="flex items-center justify-between mb-4 bg-gray-800 p-4 rounded-full w-full lg:w-1/2 mx-auto">
                                <div className="flex items-center flex-grow">
                                    <span className="text-white flex-grow w-1/2">{exercise.name}</span>
                                    <span className="text-white">5 sets</span>
                                    <span className="text-white">• 6 reps</span>
                                </div>
                                <div className="flex items-center">
                                    <button onClick={() => { }} className="text-white mx-3">
                                        <RefreshCcw size={16} />
                                    </button>
                                    <button onClick={() => deleteExercise(index)} className="text-red-500 hover:text-red-600">
                                        <Trash2 size={16} />
                                    </button>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkoutList;
