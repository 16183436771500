import React, { useEffect, useRef, useState } from "react";
import apiService from "../../services/apiService";
import baseUrl from "../../baseUrl";
import logo_imgs from "../../assets/mainLogo-transformed.webp";
import { IoMdSend } from "react-icons/io";
import "../../styles/input_bar.css";
import ScrollToBottom from "react-scroll-to-bottom";
import { YouAiMessage } from "./YouAIMessage";

export const InteractScreen = ({ post }) => {
  const [initializing, setInitializing] = useState(false);
  const [threadId, setThreadId] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [chats, setChats] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [typingInputStyle, setTypingInputStyle] = useState({
    width: "100%",
    padding: "7px",
  });
  const shouldDisableSend = inputValue.length === 0;
  const initializedRef = useRef(false);

  // Handle responsive width for input
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setTypingInputStyle({
          width: "90%",
          padding: "14px",
        });
      } else {
        setTypingInputStyle({
          width: "100%",
          padding: "7px",
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const initializeAI = async () => {
    try {
      if (initializedRef.current) return;
      setInitializing(true);
      initializedRef.current = true;
      if (
        !post ||
        threadId?.length !== 0 ||
        assistantId?.length !== 0 ||
        initializing
      ) {
        return;
      }
      const response = await apiService.post(
        `${baseUrl}/social/post-assistant`,
        {
          data: post,
        }
      );

      if (response) {
        setThreadId(response.threadId);
        setAssistantId(response.assistantId);
        setChats((prevChats) => [
          ...prevChats,
          {
            sender: "YouAI",
            content: response.content,
          },
        ]);
      }
      setInitializing(false);
    } catch (error) {
      setInitializing(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!threadId && !assistantId && !initializing && post) {
      initializeAI();
    }
  }, [threadId, assistantId, post]);

  const handleMessageSend = async (e) => {
    e.preventDefault();
    if (inputValue === "") return;

    // Add user message to chat
    setChats((prevChats) => [
      ...prevChats,
      {
        sender: "user",
        content: inputValue,
      },
    ]);

    setChats((prevChats) => [
      ...prevChats,
      {
        sender: "YouAI",
        content: "Loading...",
        id: prevChats.length + 1,
      },
    ]);

    let message = inputValue;
    setInputValue("");

    try {
      const response = await apiService.post(`${baseUrl}/social/send-message`, {
        threadId,
        assistantId,
        message,
      });

      // Replace the loading message with the actual response
      setChats((prevChats) => {
        // Find the index of the loading message
        const loadingIndex = prevChats.findIndex(
          (chat) => chat.sender === "YouAI" && chat.content === "Loading..."
        );

        if (loadingIndex !== -1) {
          // Create a new chat array with the updated content
          const updatedChats = [...prevChats];
          updatedChats[loadingIndex] = {
            sender: "YouAI",
            content: response.content,
          };
          return updatedChats;
        }

        // Return the original array if loading message wasn't found
        return prevChats;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleMessageSend(e);
    }
  };

  return (
    <div className="w-full h-full flex flex-col mt-2 ">
      {initializing ? (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <img width={100} src={logo_imgs} alt="" />
          <p className="text-base text-center font-semibold ml-4 text-white">
            Initializing...
          </p>
        </div>
      ) : (
        <div className="relative w-full h-full">
          <ScrollToBottom className="flex flex-col h-[78vh] md:h-[75vh] w-[90%] overflow-y-auto">
            {chats.map((message, id) => (
              <YouAiMessage message={message} key={id} />
            ))}
          </ScrollToBottom>

          {/* INPUT BAR */}
          <div className="main-bar" style={{ width: "100%" }}>
            <form
              action=""
              className="input_bars"
              onSubmit={handleMessageSend}
              onKeyDown={handleKeyDown}
            >
              <textarea
                className="typing_input1"
                placeholder="Message YouAI..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                  width: typingInputStyle.width, // Dynamically adjust width
                  overflowY: "auto",
                  maxHeight: "300px",
                  whiteSpace: "pre-wrap",
                  padding: typingInputStyle.padding,
                  borderRadius: "10px",
                }}
              />

              <div className="attach">
                <div className="flex gap-4">
                  <button
                    type="submit"
                    className="disabled:cursor-not-allowed"
                    disabled={shouldDisableSend}
                  >
                    <IoMdSend
                      size={"1.25rem"}
                      className={`${
                        shouldDisableSend ? "text-gray-400" : "text-green-500 "
                      }`}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
