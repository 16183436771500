import React from 'react'
import { PostWidget } from '../Widgets/PostWidget'
import { useLocation } from 'react-router-dom';

export const ViewPost = () => {
  const location = useLocation();
  console.log(location.state.post);
  const post = location.state.post || {};
  console.log(post);
  return (
    <PostWidget post={post} />
  )
}
