import React, { useState, useRef, useEffect } from 'react';
import { Send } from 'react-feather';
import apiService from '../../services/apiService';
import BeatLoader from 'react-spinners/BeatLoader';
import { IoMdSend } from "react-icons/io";

const Writer = () => {
    const [displayText, setDisplayText] = useState([]);
    const [prompt, setPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState('File Name');
    const [isTyping, setIsTyping] = useState(false);
    const displayTextRef = useRef(null);

    const handleChange = (e) => {
        setPrompt(e.target.value);
        setIsTyping(e.target.value.length > 0);
    };

    const setHeader = async (text) => {
        try {
            const body = {
                text: text,
            }
            const response = await apiService.post('/ai/generate-writing-heading', body);

            if (response.status === 'success') {
                const header = response.header;
                setFileName(header);
            } else {
                console.log('Error generating header', response);
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleFileNameChange = (e) => {
        setFileName(e.target.value);
    }

    const sendStoryPart = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const requestBody = {
            text: prompt ? `${displayText.map(item => item.text).join('\n')}\n${prompt}` : displayText.map(item => item.text).join('\n'),
            tone: 'Standard',
            flag: 'novel',
        };

        try {
            const response = await apiService.post('/ai/generate-writing', requestBody);
            if (response.status === 'success') {
                if (displayText.length === 0) {
                    setHeader(response.response)
                }

                setDisplayText(prevText => [
                    ...prevText,
                    { text: prompt, type: 'user' },
                    { text: response.response, type: 'ai' }
                ]);
                setPrompt('');
            } else {
                alert('Generation failed!');
            }
        } catch (error) {
            console.error('There was an error sending the request', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (displayTextRef.current) {
            displayTextRef.current.scrollTop = displayTextRef.current.scrollHeight;
        }
    }, [displayText]);

    return (
        <div className="flex flex-col lg:flex-row w-full h-full font-helvetica">
            <div className='flex flex-col w-full' style={{ height: 'calc(100vh - 150px)' }}>
                <div className='text-white px-4 py-6 flex-none lg:px-10 lg:py-6 lg:mx-40'>
                    <h1 className="font-bold text-2xl lg:text-4xl mb-4 lg:mb-8 text-white">Novel Writer</h1>
                    <div className='font-bold text-xl mb-4 lg:text-2xl'>
                        <input
                            type="text"
                            className='bg-transparent text-white outline-none w-full text-md'
                            value={fileName}
                            onChange={handleFileNameChange}
                            placeholder='File Name'
                        />
                    </div>
                    {displayText.length === 0 && <p className='text-green-500 text-sm lg:text-lg'>
                        Your creative journey begins here. Generate a novel with the help of AI.
                    </p>}
                </div>
                <div className='text-white px-4 flex-grow overflow-auto lg:px-10 lg:mx-40 whitespace-pre-wrap' ref={displayTextRef}>
                    {displayText.map((item, index) => (
                        <p key={index} className={item.type === 'ai' ? 'text-yellow-300 text-sm lg:text-md' : 'text-white text-sm lg:text-md'}>
                            {item.text}
                        </p>
                    ))}
                    {isLoading && <BeatLoader color="#32CD32" />}
                </div>

                <div className='flex justify-center items-center px-4 mt-4 lg:px-10 lg:mt-6 lg:mx-40'>
                    <form
                        onSubmit={sendStoryPart}
                        className="flex items-center justify-between rounded-full bg-gray-800 w-full px-2 py-1 lg:px-4 lg:py-2"
                    >
                        <input
                            type="text"
                            placeholder="What do you want in the story...."
                            value={prompt}
                            onChange={handleChange}
                            className="flex-grow bg-transparent px-3 py-2 text-white placeholder-gray-400 focus:outline-none text-sm lg:text-base"
                            disabled={isLoading}
                        />
                        <button
                            type='submit'
                            className={`ml-2 text-sm lg:text-base rounded-full px-2 py-1 ${isTyping ? 'text-green-500' : 'text-gray-500'}`}
                            disabled={isLoading}
                        >
                            <IoMdSend size={"1.25rem"} />
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Writer;
