import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import men from "../assets/men.jpg";
import hi4 from "../assets/hi4.jpg";
import hi from "../assets/hi.webp";
import hi1 from "../assets/hi1.webp";
import hi2 from "../assets/hi2.jpg";
import hi3 from "../assets/hi3.jpg";
import ThreeJsAnimation from "./ThreeJsAnimation";
import "../styles/LandingPage.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CustomCursor from "./CursorAnimation";

const Capabilities = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS with your preferred options
  }, []);

  return (
    <>
      <Helmet>
        <title>Ideaverse | Capabilities</title>
        <meta
          name="description"
          content="Ideaverse unlocks tomorrow's potential, shaping the future through innovative AI models."
        />
        <meta
          name="keywords"
          content="AI, ChatGPT, Personalisation, Code interpreter, ideaverse, Image Generation, AI Voice Assistant"
        />
      </Helmet>
      <CustomCursor />
      <div className="capauu">
        <Navbar data-aos="fade-up" />
        <div className="animatedcontainer">
          <ThreeJsAnimation data-aos="fade-up" />
          <div className="home">
            <div className="home__container">
              <h1 className="loremheading" data-aos="fade-right">
                <span className="istbh">Ideaverse</span>
                unlocks tomorrow's potential, shaping the future through
                innovative AI models.
              </h1>
              <p className="belowtag" data-aos="fade-left">
                Embark on Your Journey: Click to Begin, Unleashing Possibilities
                with Ideaverse.
              </p>
              <div className="btnsgetgh" data-aos="fade-up">
                <Link to="/login">
                  {" "}
                  <button className="getstahrted">Get Started</button>
                </Link>{" "}
                <button className="jjgtth"> View pricing</button>
              </div>
            </div>
          </div>
        </div>
        <div className="capaohgh">
          <div className="ahggh">
            <img src={hi4} alt="men" className="jhsghj" data-aos="fade-up" />

            <div className="capa1">
              <h1 className="ytsftf" data-aos="fade-up">
                Capability 1 - Personalisation
              </h1>
              <div className="fsh">
                <p className="parath" data-aos="fade-up">
                  You AI is a pioneering chatbot designed to redefine user
                  interaction through hyperpersonalization, leveraging advanced
                  algorithms to learn from individual responses. Tailoring its
                  engagement strategy, You AI dynamically adjusts tone, humor,
                  and response length to match user preferences, ensuring a
                  unique conversational experience. With a robust memory module,
                  it not only remembers significant dates like birthdays but
                  also incorporates this information to enhance interaction
                  relevance and personal touch. Through continuous learning, You
                  AI optimizes its responses over time, achieving an impressive
                  adaptation accuracy rate exceeding 95%. Its architecture
                  supports seamless integration across platforms, enabling users
                  to experience consistent personalization whether on mobile,
                  web, or smart devices. You AI's sophisticated analytics engine
                  provides actionable insights based on user interactions,
                  facilitating a deeper understanding of user preferences and
                  behaviors. With a user satisfaction rate soaring above 90%,
                  You AI sets a new standard in personalized digital
                  communication.
                </p>
                <img src={hi} alt="" className="ahgng" data-aos="fade-up" />
              </div>
            </div>
            <div className="capa2">
              <h1 className="ytsftf" data-aos="fade-up">
                Capability 2 - Code interpreter
              </h1>
              <div className="fsh">
                <p className="parath3" data-aos="fade-up">
                  The AI code interpreter is a multifaceted platform that stands
                  out for its proficiency in processing and generating documents
                  with advanced natural language processing (NLP) techniques,
                  significantly aiding in data extraction and content
                  management. It further distinguishes itself by executing code
                  across a variety of programming languages, making it an
                  indispensable resource for software development, debugging,
                  and educational purposes. Its capabilities in analyzing data
                  are unmatched, turning complex datasets into actionable
                  insights, which is essential for data scientists and business
                  analysts aiming to make informed decisions. The interpreter's
                  automation features are a boon for enhancing productivity, as
                  they streamline routine tasks, thereby improving efficiency in
                  workflow automation and system administration. The inclusion
                  of machine learning model integration elevates applications by
                  incorporating predictive analytics and personalized user
                  experiences. Moreover, customizable APIs extend its utility,
                  facilitating easy integration with external services and
                  databases, which is crucial for app development and providing
                  comprehensive enterprise solutions. This platform, by
                  integrating advanced technologies and offering a wide range of
                  functionalities, caters to the evolving needs of professionals
                  in the tech industry, making it a cornerstone for those
                  seeking efficient, AI-driven solutions.
                </p>
              </div>
            </div>
            <div className="capa2">
              <h1 className="ytsftf" data-aos="fade-up">
                Capability 3 - Image Generation
              </h1>
              <div className="fsh">
                <p className="parath" data-aos="fade-up">
                  Within the innovative suite of You AI's functionalities lies
                  its remarkable image generation capability, powered by the
                  cutting-edge DALL-E 3 technology. This feature transforms
                  textual descriptions into visually stunning images, opening up
                  endless possibilities for creative design, marketing content
                  creation, and visual storytelling. It provides an intuitive
                  interface for users to bring their imaginative concepts to
                  life with high precision and creativity, making it an
                  indispensable tool for graphic designers, content creators,
                  and digital marketers aiming to captivate their audience. Your
                  AI leverages AI-driven artistry to generate unique visuals,
                  infusing projects with originality and flair that stand out in
                  digital media. This capability not only enhances user
                  engagement through visually appealing content but also
                  streamlines the creative process, offering a blend of
                  efficiency and innovation.
                </p>
                <img src={hi1} alt="" className="ahgng" data-aos="fade-up" />
              </div>
            </div>
            <div className="capa2">
              <h1 className="ytsftf" data-aos="fade-up">
                Capability 4 - AI Voice Assistant
              </h1>
              <div className="fsh">
                <p className="parath" data-aos="fade-up">
                  At the heart of You AI's expansive feature set lies its
                  state-of-the-art AI voice assistant, a groundbreaking
                  innovation that revolutionizes the way users interact with
                  technology. By harnessing advanced voice recognition and
                  natural language understanding technologies, this assistant
                  offers an unparalleled conversational experience, enabling
                  users to communicate with You AI using their voice. This
                  feature is a game-changer for accessibility, convenience, and
                  user engagement, making it an essential tool for busy
                  professionals, individuals with disabilities, and anyone
                  looking to streamline their digital interactions. Whether it's
                  executing commands, answering queries, or providing
                  companionship, the AI voice assistant understands and responds
                  with remarkable accuracy and human-like nuance.
                </p>
                <img src={hi2} alt="" className="ahgng" data-aos="fade-up" />
              </div>
            </div>
            <div className="capa2">
              <h1 className="ytsftf" data-aos="fade-up">
                Capability 5 - Web Browsing
              </h1>
              <div className="fsh">
                <p className="parath" data-aos="fade-up">
                  Your AI's innovative web browsing feature is a cutting-edge
                  enhancement that transforms how users access and interact with
                  information from the internet. By integrating real-time data
                  retrieval capabilities within its framework, Your AI enables
                  users to effortlessly search for and obtain current
                  information on a wide array of topics including breaking news,
                  financial market trends, weather conditions, and more. This
                  state-of-the-art functionality empowers users to make informed
                  decisions swiftly, stay updated with global events as they
                  unfold, and delve into research without ever needing to
                  navigate away from the platform. The technology behind this
                  feature is designed with precision and user convenience in
                  mind, ensuring that queries are not only understood but
                  answered with relevant, accurate, and timely information. From
                  professionals in need of the latest market data to students
                  researching for academic projects, Your AI's web browsing
                  capability enriches the user experience by providing a gateway
                  to the world's knowledge, packaged within an intuitive,
                  user-friendly interface. This direct access to the internet's
                  vast resources, combined with the AI's ability to understand
                  and process natural language queries, positions Your AI as a
                  pioneering solution in the realm of digital assistants,
                  setting a new standard for how we interact with information in
                  our increasingly connected world.
                </p>
                <img src={hi3} alt="" className="ahgng" data-aos="fade-up" />
              </div>
            </div>
          </div>
        </div>

        <Footer data-aos="fade-up" />
      </div>
    </>
  );
};

export default Capabilities;
