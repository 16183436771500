import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../styles/globalAiPopup.css";
import apiService from "../services/apiService";
import { RingLoader } from "react-spinners";
import baseUrl from "../baseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Popup = ({ isOpen, onClose, onSave, article }) => {
  const [title, setTitle] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setEditorContent(article.content);
      setImage(article.image);
    }
  }, [article]);

  const handleSave = async () => {
    if (title.length === 0) {
      toast.error("Title is required");
      return;
    }

    if (editorContent.length === 0) {
      toast.error("Content is required");
      return;
    }

    if (!image) {
      toast.error("Image is required");
      return;
    }

    setIsLoading(true);
    try {
      await apiService.put(`${baseUrl}/ai/updateAiNews/${article._id}`, {
        title,
        content: editorContent,
        image,
      });
      toast.success("Article updated successfully");
      onSave({ ...article, title, content: editorContent, image });
      resetFields();
      onClose();
    } catch (error) {
      toast.error("Failed to update the article");
    } finally {
      setIsLoading(false);
    }
  };

  const resetFields = () => {
    setTitle("");
    setEditorContent("");
    setImage(null);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup-overlay4">
      {isLoading ? (
        <div className="loader-container2">
          <RingLoader size={70} color={"#6cd97e"} loading={isLoading} />
        </div>
      ) : (
        <div className="popup-container4">
          <h1 className="popup-title">Edit News Article</h1>
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
          {image && (
            <div className="image-preview">
              <img src={image} alt="Selected" />
            </div>
          )}
          <ReactQuill
            className="quill-editor"
            value={editorContent}
            onChange={setEditorContent}
            placeholder="Write your article here..."
            modules={{ toolbar: true }}
            style={{ border: "none" }}
          />
          <div className="popup-buttons">
            <button onClick={handleSave} disabled={isLoading}>
              {isLoading ? "Saving..." : "Save"}
            </button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
