import React, { useState } from "react";
import WritingGenerator from "./WritingGenerator";
import { writingTones } from "../../constants/aiTools";

const WritingAssistant = () => {
  const tones = writingTones;
  const [selectedTone, setSelectedTone] = useState(tones[0]);

  const handleToneChange = (tone) => {
    setSelectedTone(tone);
  };

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen font-helvetica">
      <div className="flex-grow p-8">
        {/* Page title and catchy tagline */}
        <header className="text-left mb-8">
          <h1 className="text-lg lg:text-4xl font-bold text-white mb-4">
            Writing Assistant
          </h1>
        </header>

        {/* Tone selection row */}
        <div className="flex flex-wrap justify-center gap-3 mb-8">
          {tones.map((tone) => (
            <button
              key={tone}
              className={`py-2 px-4 lg:py-3 lg:px-8 text-sm lg:text-base text-white font-bold rounded-md shadow-lg cursor-pointer ${
                selectedTone === tone
                  ? "border-white bg-gray-700"
                  : "border-gray-500 bg-gray-600 hover:bg-gray-700"
              } border`}
              onClick={() => handleToneChange(tone)}
            >
              {tone}
            </button>
          ))}
        </div>

        {/* WritingComponent area */}
        <WritingGenerator tone={selectedTone} />
      </div>
    </div>
  );
};

export default WritingAssistant;