import React, { useState, useEffect } from "react";
import "../styles/createAvatar.css";
import baseUrl from "../baseUrl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAvatarId } from "../features/avatarSlice";
import apiService from "../services/apiService";
import RightPanel from "./RightPanelAvatar";
import { RingLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AvatarGenerator = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [instructions, setInstructions] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModels, setSelectedModels] = useState({
    text: null,
    image: null,
    speech: null,
    video: null,
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [rightPanelData, setRightPanelData] = useState({});
  const [loading, setLoading] = useState(false);
  const [rightPanelDisabled, setRightPanelDisabled] = useState(true);
  const [currentAvatarId, setCurrentAvatarId] = useState(null);
  const [activePanel, setActivePanel] = useState("left");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    apiService
      .get(`${baseUrl}/ai/getAiModels`)
      .then((response) => {
        setModels(response);
      })
      .catch((error) => {
        console.error("Error fetching models:", error);
        setError("Failed to fetch models.");
      });

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    dispatch(setAvatarId(currentAvatarId)); // Store the avatar ID in Redux
    navigate(`/aichat/avatar`); // Navigate to avatar route without session ID
  };

  const handleModelSelect = (type, model) => {
    console.log("Selected model:", type, model);

    setSelectedModels((prevSelectedModels) => {
      const updatedModels = {
        ...prevSelectedModels,
        [type]: model,
      };
      return updatedModels;
    });

    // After state update, submit the form
    handleSubmit({
      ...selectedModels,
      [type]: model,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFieldUpdateStart = () => {
    setRightPanelDisabled(true);
  };

  const handleSubmit = (updatedModels) => {
    // Use updatedModels if passed, otherwise use current selectedModels
    const modelsToSubmit = updatedModels || selectedModels;

    if (!name || !description || !instructions) {
      toast.error("Please fill in all the required fields!", {
        position: "top-right",
      });
      return;
    }

    const data = {
      id: currentAvatarId, // Include the current avatar ID for updating if it exists
      name,
      description,
      instructions,
      file: fileUrl,
      selectedModels: {
        text: modelsToSubmit.text ? modelsToSubmit.text.serviceName : null,
        image: modelsToSubmit.image ? modelsToSubmit.image.serviceName : null,
        speech: modelsToSubmit.speech
          ? modelsToSubmit.speech.serviceName
          : null,
        video: modelsToSubmit.video ? modelsToSubmit.video.serviceName : null,
      },
    };

    setLoading(true);
    console.log("Submitting data:", data);

    apiService
      .post(`${baseUrl}/avatar/selectedModelAvatar`, data)
      .then((response) => {
        setMessage(response.message);
        setError("");
        console.log("Response data:", response);

        // If the response contains a new _id, set it as the current avatar ID
        setCurrentAvatarId(response._id);

        return apiService.get(
          `${baseUrl}/avatar/getSelectedDataAvatar?id=${response._id}`
        );
      })
      .then((response) => {
        setRightPanelData(response);
        setLoading(false);

        setName(response.name || "");
        setDescription(response.description || "");
        setInstructions(response.instructions || "");
        setFileUrl(response.file || "");

        setSelectedModels({
          text:
            models.find(
              (model) => model.serviceName === response.selectedModels.text
            ) || null,
          image:
            models.find(
              (model) => model.serviceName === response.selectedModels.image
            ) || null,
          speech:
            models.find(
              (model) => model.serviceName === response.selectedModels.speech
            ) || null,
          video:
            models.find(
              (model) => model.serviceName === response.selectedModels.video
            ) || null,
        });

        setRightPanelDisabled(false);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setError("Failed to submit data. Please try again.");
        setMessage("");
        setLoading(false);
      });
  };

  const categorizedModels = models
    .filter((model) => model.responseType !== "general")
    .reduce((acc, model) => {
      if (!acc[model.responseType]) {
        acc[model.responseType] = [];
      }
      acc[model.responseType].push(model);
      return acc;
    }, {});

  return (
    <div className="avatar-generator">
      <ToastContainer />
      {isSmallScreen && (
        <div className="panel-toggle-buttons">
          <button
            className={`toggle-buttonw ${activePanel === "left" ? "active" : ""}`}
            onClick={() => setActivePanel("left")}
          >
            Left Panel
          </button>
          <button
            className={`toggle-buttonw1 ${activePanel === "right" ? "active" : ""}`}
            onClick={() => setActivePanel("right")}
          >
            Right Panel
          </button>
        </div>
      )}
      <div className="avatarr">
        {(!isSmallScreen || activePanel === "left") && (
          <div
            className={`left-panel ${activePanel === "left" ? "visible" : "hidden"}`}
          >
            <h1 className="kolk">Avatar Generator</h1>
            <div className="input-group">
              <label>Name</label>
              <input
                className="inpuiyyk"
                type="text"
                placeholder="Lorem Ipsum"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  handleFieldUpdateStart();
                }}
              />
            </div>
            <div className="input-group">
              <label>Description</label>
              <textarea
                placeholder="Add a Short Description"
                className="kjhhhtol"
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  handleFieldUpdateStart();
                }}
              />
            </div>
            <div className="input-group">
              <label>Instructions</label>
              <textarea
                placeholder="Add Short Instructions"
                value={instructions}
                className="opll"
                onChange={(e) => {
                  setInstructions(e.target.value);
                  handleFieldUpdateStart();
                }}
              />
            </div>
            <div className="input-group">
              <label>Upload File Button</label>
              <input
                type="file"
                className="jhjnj"
                onChange={handleFileChange}
              />
            </div>
            <div className="model-selection">
              {Object.keys(categorizedModels).map((category) => (
                <div key={category} className="model-category">
                  <h3>{category.toUpperCase()}</h3>
                  <div className="models">
                    {categorizedModels[category].map((model) => (
                      <button
                        key={model._id}
                        onClick={() => handleModelSelect(category, model)}
                        className={
                          selectedModels[category] &&
                          selectedModels[category]._id === model._id
                            ? "selected"
                            : ""
                        }
                      >
                        {model.icon} {model.modelName}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="butoj">
              <button className="submit-button" onClick={() => handleSubmit()}>
                Save
              </button>
              <button
                className="submit-button1"
                onClick={handleClick}
                disabled={rightPanelDisabled} // Disable the button when rightPanelDisabled is true
              >
                YOUAI
              </button>
            </div>
          </div>
        )}
        {(!isSmallScreen || activePanel === "right") && (
          <RightPanel
            rightPanelData={rightPanelData}
            disabled={rightPanelDisabled}
          />
        )}
        {loading && (
          <div className="spinner-overlay1">
            <RingLoader size={130} color={"#6cd97e"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarGenerator;
