import React, { useEffect, useState, useCallback } from "react";
import "../styles/socialFeatures/searchFeature.css";
import close from "../assets/close_small.png";
import baseUrl from "../baseUrl";
import { UserWidget } from "./Widgets/UserWidget";
import { debounce } from "lodash";
import apiService from "../services/apiService";
import { VscLoading } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

export const SearchModal = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchRes, setSearchRes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await apiService.get(
          `${baseUrl}/social/searchUser/?q=${query}`
        );
        if (response) {
          setSearchRes(response.searchResult);
        } else {
          setSearchRes([]);
        }
      } catch (err) {
        console.error("Error searching users:", err.message);
        setError("Failed to fetch search results. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSearchRes([]);
      return;
    }

    debouncedSearch(searchQuery);

    return () => {
      debouncedSearch.cancel();
    };
  }, [searchQuery, debouncedSearch]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/social/search?q=${searchQuery}`);
    }
  };

  return (
    <div className="search-modal-overlay">
      <div className="search-modal-box">
        <div className="search-modal-searchbox">
          <div className="modal-search-box">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="hit 'Enter' after searching keyword..."
            />
            <img src={close} alt="close" onClick={onClose} />
          </div>
        </div>

        {/* Search Results */}
        <div className="modal-search-result">
          {isLoading ? (
            <div className="w-full flex justify-center">
              <VscLoading
                className="loading-animation"
                size={30}
                color="white"
              />
            </div>
          ) : error ? (
            <p className="search-msg error">{error}</p>
          ) : searchQuery && searchRes.length === 0 ? (
            <p className="search-msg">No results found...</p>
          ) : searchRes.length > 0 ? (
            searchRes.map((result, id) => <UserWidget key={id} user={result} />)
          ) : (
            <h3 className="search-msg">Start searching...</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
