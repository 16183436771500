import React, { useEffect, useState } from "react";
import "../styles/socialFeatures/comments.css";
import "../styles/social_feature.css";
import send from "../assets/send.png";
import baseUrl from "../baseUrl";
import { Comment } from "./Widgets/Comments";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { MentionsInput, Mention } from "react-mentions";
import { useSelector } from "react-redux";
import users from "./users";
import apiService from "../services/apiService";
import { VscLoading } from "react-icons/vsc";

const CommentBox = ({ postId, handleCommentCount }) => {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const username = useSelector((state) => state.user.userName);
  const { imageUrl } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const hash = CryptoJS.MD5(username).toString();
  useEffect(() => {
    const fetchComments = async () => {
      try {
        setLoading(true);
        const response = await apiService.get(
          `${baseUrl}/social/getComments/${postId}`
        );
        if (response.data) {
          setComments(response.data);
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchComments();
  }, []);

  const handleSendComment = async () => {
    try {
      setComment("");
      if (!username) {
        throw new Error("username not found!");
      }
      const response = await apiService.post(
        `${baseUrl}/social/comment/${postId}`,
        {
          text: comment,
          username: username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        setComments(response.data);
        handleCommentCount();
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      <div className="comment-container">
        <div className="comment-box-wrapper">
          <div className="profile-img">
            {imageUrl ? (
              <img alt="user" src={imageUrl} className="boy_prof" />
            ) : (
              <Gravatar
                email={username}
                size={150}
                default="identicon"
                className="user_frien3"
                hash={hash}
              />
            )}
          </div>
          <MentionsInput
            className="comment-input"
            placeholder="Write a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{
              paddingLeft: "20px", // Add padding only to the left side
              outline: "none",
              borderRadius: "20px", // Add border radius for rounded corners
              boxShadow: "none",
              width: "85%",
            }}
          >
            <Mention
              trigger="@"
              data={users} // Array of user data to suggest when "@" is typed
              renderSuggestion={(suggestion, search, highlightedDisplay) => (
                <div className="user-suggestio1n">{highlightedDisplay}</div>
              )}
            />
          </MentionsInput>

          <div onClick={handleSendComment} className="cursor-pointer">
            <img src={send} alt="send" />
          </div>
        </div>

        <div className="comment-section">
          {loading ? (
            <div className="w-full flex justify-center">
              <VscLoading
                className="loading-animation"
                size={30}
                color="white"
              />
            </div>
          ) : comments && comments.length !== 0 ? (
            comments.map((comment, id) => (
              <Comment
                key={id}
                setComments={setComments}
                comment={comment}
                handleCommentCount={handleCommentCount}
              />
            ))
          ) : (
            <p className="p-4 text-center text-gray-600 text-medium font-semibold">
              No Comments found!
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default CommentBox;
