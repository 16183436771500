import React, { useEffect, useState } from "react";
import "../styles/social_feature.css";
import electric from "../assets/electric_bolt.png";
import SearchModal from "./SearchModal";
import baseUrl from "../baseUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { UserWidget } from "./Widgets/UserWidget";
import { useDispatch, useSelector } from "react-redux";
import { setFriends } from "../features/postSlice";
import { VscLoading } from "react-icons/vsc";
import apiService from "../services/apiService";
import { ClipLoader } from "react-spinners";

const SearchRight = () => {
  const [searchClicked, setSearchClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [followingTab, setFollowingTab] = useState(false);
  const username = useSelector((state) => state.user.userName);
  const dispatch = useDispatch();
  const following = useSelector((state) => state.posts.userFollowing);

  const getFollowing = async () => {
    try {
      if (!username) {
        console.log("username not found");
        return;
      }
      setLoading(true);
      const response = await apiService.get(`${baseUrl}/social/getFollowers`);
      if (response) {
        dispatch(setFriends({ friends: response.data }));
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await apiService.get(
        `${baseUrl}/social/get-user-suggestions`
      );
      if (response) {
        setUsers(response.users);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    getFollowing();
  }, []);

  return (
    <>
      <div className={`social_right_trending hidden md:flex `}>
        <div className="trending_social">
          <div className="flex items-center gap-2">
            <img src={electric} alt="trending" className="electric_img" />
            <p className="activity_name_socail">
              {followingTab ? "Following" : "Based on your Interests "}
            </p>
          </div>
          <FontAwesomeIcon
            icon={faUserGroup}
            color="white"
            className="cursor-pointer"
            title={followingTab ? "Suggested Users" : "Followers"}
            onClick={() => {
              setFollowingTab(!followingTab);
            }}
          />
        </div>
        <hr className="social_line" />
        <div className="follow_people">
          {loading ? (
            <div className="w-full flex justify-center">
              <ClipLoader size={30} color="white" />
            </div>
          ) : followingTab ? (
            following?.map((follower, id) => (
              <UserWidget key={id} user={follower} />
            ))
          ) : (
            users?.map((user, id) => <UserWidget key={id} user={user} />)
          )}
        </div>
        <div className=" flex-grow"></div>
        <hr className="social_line" />
        <div className="viewm" onClick={() => setSearchClicked(true)}>
          <p className="viemorew">View More</p>
        </div>
      </div>
      {searchClicked && (
        <SearchModal onClose={() => setSearchClicked(!searchClicked)} />
      )}
    </>
  );
};

export default SearchRight;
