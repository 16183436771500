const writingTones = [
  "Standard",
  "Casual",
  "Formal",
  "Creative",
  "Academic",
  "Simple",
];
// Data for each tool
const toolsData = [
  {
    heading: "AI Paraphrasing Tool",
    paragraph: "Generate paraphrased text using advanced AI algorithms.",
    link: "writing-assistant",
    iconName: "Paraphrase",
  },
  {
    heading: "Novel-writer",
    paragraph: "Craft your novel with ease using our intuitive writing tools.",
    link: "novel-writer",
    iconName: "Novel",
  },
  {
    heading: "Business Plan Generator",
    paragraph: "Create professional business plans quickly and efficiently.",
    link: "business-plan",
    iconName: "Business",
  },
  {
    heading: "Flash Card Generator",
    paragraph: "Generate flashcards effortlessly from PDFs and Word documents.",
    link: "flash_card",
    iconName: "flashCard",
  },
  {
    heading: "Hex Code Generator",
    paragraph: "Generate hexadecimal codes based on your prompt.",
    link: "hex_code_generator",
    iconName: "hexCode",
  },
  {
    heading: "Business AI",
    paragraph:
      "Empower your business decisions with AI-driven SWOT analysis and more.",
    link: "business_ai_tool",
    iconName: "businessAi",
  },
  {
    heading: "Interview AI",
    paragraph:
      "Ace your job interviews with tailored preparation for any role.",
    link: "interviewAi",
    iconName: "interview",
  },
  {
    heading: "Caption Generator",
    paragraph:
      "Get captions according to your ideas and feel, and enchance your productivity.",
    link: "caption",
    iconName: "caption",
  },
  {
    heading: "Logo Generator",
    paragraph:
      "Get AI generated clean logos for your company, projects and projects.",
    link: "logo",
    iconName: "logo",
  },
  {
    heading: "Workout Generator",
    paragraph:
      "Get a specific plan according to your needs. And keep a check of you workout routine.",
    link: "workout",
    iconName: "workout",
  },
  {
    heading: "Tutor AI",
    paragraph:
      "Get a personalized tutor for the topic that you want to learn. Use AI to learn at it's best.",
    link: "tutor",
    iconName: "tutor",
  },
];

const tutorTopics = [
  {
    title: "Artificial Intelligence",
    content:
      "Explore the fascinating field of AI, which focuses on creating intelligent machines capable of performing tasks that typically require human intelligence.",
  },
  {
    title: "Graphic Design",
    content:
      "Unleash your creativity with graphic design, where you'll learn how to communicate ideas visually using typography, imagery, color, and layout techniques.",
  },
  {
    title: "Science",
    content:
      "Explore the wonders of science, encompassing various disciplines such as physics, chemistry, biology, and more, to understand the natural world and its phenomena.",
  },
  {
    title: "Digital Marketing",
    content:
      "Master the art of promoting products or services using digital channels such as search engines, social media, email, and websites to reach and engage with target audiences.",
  },
  {
    title: "Cooking",
    content:
      "Explore the world of cooking and learn various culinary techniques, recipes, and cuisines to create delicious meals for yourself and others.",
  },
  {
    title: "Photography",
    content:
      "Dive into the art and science of photography, where you'll learn about composition, lighting, camera settings, and post-processing techniques to capture stunning images.",
  },
  {
    title: "Yoga and Meditation",
    content:
      "Discover the benefits of yoga and meditation for physical, mental, and emotional well-being, and learn different yoga poses and meditation techniques to cultivate inner peace and balance.",
  },
  {
    title: "Geography",
    content:
      "Embark on a journey to explore the wonders of our planet, from the highest peaks to the deepest oceans. Learn about diverse landscapes, ecosystems, and cultures that shape our world.",
  },
  {
    title: "Physics",
    content:
      "Dive into the fundamental laws governing the cosmos, from the tiniest particles to the vast expanse of space. Explore the principles of motion, energy, and matter that define our universe.",
  },
];

export { writingTones, toolsData, tutorTopics };
