import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import postReducer from "../features/postSlice";
import chatReducer from "../features/chatSlice";
import avatarReducer from "../features/avatarSlice";
import aiChallengeReducer from "../features/aiChallengeSlice"; // Correct import for aiChallengeReducer

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const userPersistConfig = { key: "user", storage, version: 1 };
const avatarPersistConfig = { key: "avatar", storage, version: 1 };
const aiChallengePersistConfig = { key: "aiChallenge", storage, version: 1 }; // Correct persistence config for aiChallenge

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedAvatarReducer = persistReducer(
  avatarPersistConfig,
  avatarReducer
);
const persistedAiChallengeReducer = persistReducer(
  aiChallengePersistConfig,
  aiChallengeReducer
);

const rootReducer = combineReducers({
  user: persistedUserReducer,
  posts: postReducer,
  socialChat: chatReducer,
  avatar: persistedAvatarReducer,
  aiChallenge: persistedAiChallengeReducer, // Add aiChallenge to the root reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
