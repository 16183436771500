import React, { useEffect, useState } from "react";
import { SocialLeft } from "./SocialLeft";
import SocialRight from "./SocialRight";
import "../styles/social_feature.css";
import baseUrl from "../baseUrl";
import { PostWidget } from "./Widgets/PostWidget";
import { RingLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { PostWidgetAI } from "./Widgets/PostWidgetAI";
import { PostWidgetConversation } from "./Widgets/PostWidgetConversation";
import apiService from "../services/apiService";

const SaveAssets = () => {
  const { userName } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await apiService.get(
          `${baseUrl}/social/get-saved-posts`
        );
        if (response) {
          setPosts(response.posts);
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, [userName]);

  return (
    <>
      <div className={`containe`}>
        <div className="social_feature_container">
          <SocialLeft />
          <div className="social_center_trending">
            <h1 className="mndccgcb">Saved</h1>
            <p className="jhabj">{`@${userName}`}</p>
            <div className="janzhj">
              {loading ? (
                <div className="w-full flex justify-center items-center">
                  <RingLoader color="white" />
                </div>
              ) : posts.length === 0 ? (
                <h1 className="nosahh">No Saved Assets Yet.</h1>
              ) : (
                <>
                  {posts.map((post, id) => {
                    if (post?.type === "ai") {
                      return (
                        <PostWidgetAI
                          key={`ai-${id}`}
                          index={id}
                          post={post}
                          setModalIsOpen={()=>{}}
                          setPostLikeVisible={()=>{}}
                          setSelectedImage={()=>{}}
                          setSelectedPost={()=>{}}
                        />
                      );
                    } else if (post?.type === "conversation") {
                      return (
                        <PostWidgetConversation
                          key={`conversation-${id}`}
                          index={id}
                          post={post}
                          setModalIsOpen={()=>{}}
                          setPostLikeVisible={()=>{}}
                          setSelectedImage={()=>{}}
                          setSelectedPost={()=>{}}
                        />
                      );
                    } else {
                      return (
                        <PostWidget
                          key={`default-${id}`}
                          index={id}
                          post={post}
                          setModalIsOpen={()=>{}}
                          setPostLikeVisible={()=>{}}
                          setSelectedImage={()=>{}}
                          setSelectedPost={()=>{}}
                        />
                      );
                    }
                  })}
                </>
              )}
            </div>
          </div>
          <SocialRight />
        </div>
      </div>
    </>
  );
};

export default SaveAssets;
