import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "../styles/HomeMainPage.css";

const CollectionCardHome = ({ path, title, description, type, image }) => {
  return (
    <>
      <div className="collectionscard w-64 h-96 px-5 pt-5 pb-4 bg-gray-800 rounded-3xl flex-col justify-start items-start gap-3 inline-flex">
        <div className="collectioncardimhage h-40">
          <Link
            to={`/collection/${type}`}
            className="no-default"
            title="Text to Text"
          >
            <img
              alt=""
              className={`collectionimahe w-52 h-60 rounded-3xl `}
              src={image}
            />
          </Link>
          <div className="self-stretch h-20 flex-col justify-start items-start flex mt-4 mb-4">
            <h4 className="collectiontitle text-stone-300 text-base font-bold font-['Helvetica'] leading-normal">
              <Link
                to={`/collection/${type}`}
                className="colletiontit no-default text-2xl"
              >
                {title}
              </Link>
            </h4>
            <p className="collectiondescription self-stretch text-slate-400 text-sm font-bold font-['Helvetica'] leading-tight">
              {description}
            </p>
            <p className="mb-1 text-[#00000073] text-sm"></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionCardHome;
