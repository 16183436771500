import React, { useEffect, useState } from "react";
import "../styles/promptLeaderboard.css";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";

const PromptLeaderboard = () => {
  const [prompts, setPrompts] = useState([]);

  useEffect(() => {
    const fetchAiPosts = async () => {
      try {
        const response = await apiService.get(
          `${baseUrl}/social/promptLeaderboard`
        );
        console.log("response: ", response);

        setPrompts(response);
      } catch (error) {
        console.error("Error fetching AI posts:", error);
      }
    };

    fetchAiPosts();
  }, []);

  return (
    <div className="promptLeaderboard">
      <div className="promptCollect">
        <h1 className="leaderboardTitle">Prompt leader board</h1>

        <div className="tabContainer">
          <button className="tabButton">Featured</button>
          <button className="tabButton active">All</button>
        </div>
        <div className="promptList">
          {prompts.map(({ conversation, caption, totalEngagement }, index) => (
            <div key={index} className="promptItem">
              <div className="promptInfo">
                <div className="promptAvatar">
                  {conversation[0].mediaUrls.length > 0 && (
                    <img
                      src={conversation[0].mediaUrls[0].url} // Display the first image in the conversation's mediaUrls
                      alt="avatar"
                      className="promptImage"
                    />
                  )}
                </div>
                <div className="promptText">
                  <p className="promptTitle">{conversation[0].prompt}</p>{" "}
                  {/* Display the prompt */}
                  <p className="promptSubtitle">{caption}</p>{" "}
                  {/* Display the caption */}
                </div>
              </div>
              <div className="promptEngagement">
                <span>{totalEngagement}</span>{" "}
                {/* Display the total engagement */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromptLeaderboard;
