import React from 'react';

export const ToggleButton = ({
  offIcon: OffIcon, 
  onIcon: OnIcon,
  active,
  setActive
}) => {
  return (
    <div
      onClick={() => setActive(!active)}
      title='Click to reveal prompt'
      className={`flex items-center cursor-pointer w-12 p-1 rounded-full ${active ? 'bg-green-500' : 'bg-gray-700'}`} 
    >
      <div
        className={`w-1/2 h-full flex justify-center items-center rounded-full transition-transform ${active ? 'transform translate-x-full bg-white' : 'bg-gray-800'}`}
      >
        {active ? <OnIcon  className="text-green-500" /> : <OffIcon className="text-gray-500" />}
      </div>
    </div>
  );
};
