import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../baseUrl";
import { PropagateLoader } from "react-spinners"; // Import the spinner component
import "../styles/hexCode.css";
import apiService from "../services/apiService";

const HexCodeGenerator = () => {
  const [inputText, setInputText] = useState(""); // State to hold input text
  const [generatedColor, setGeneratedColor] = useState(""); // State to hold generated color
  const [isLoading, setIsLoading] = useState(false); // State to track loading status for Generate button
  const [isLoadingPalette, setIsLoadingPalette] = useState(false); // State to track loading status for Generate Color Palette button
  const [errorMessage, setErrorMessage] = useState(""); // State to hold error message

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Check if inputText is empty
    if (!inputText.trim()) {
      setErrorMessage("Please enter text");
      return;
    }

    setIsLoading(true); // Set loading state to true while fetching data

    try {
      const response = await apiService.post(
        `${baseUrl}/ai/hashCodeGenerator`,
        {
          text: inputText,
        }
      );
      console.log("Server response:", response);
      setGeneratedColor(response); // Update state with generated color
      setInputText(""); // Clear input text
    } catch (error) {
      console.error("Error posting data:", error);
    } finally {
      setIsLoading(false); // Reset loading state after fetching data
    }
  };

  // Function to handle generating color palette
  const handleGeneratePalette = async () => {
    setIsLoadingPalette(true); // Set loading state to true while fetching data

    try {
      const response = await apiService.post(`${baseUrl}/ai/generatePalette`, {
        hexCode: generatedColor.response,
      });
      console.log("Palette generated:", response);

      // Split the response string into an array of individual color codes
      const colorsArray = response.response.split("\n");

      // Update state with the array of hex codes
      setGeneratedColor(colorsArray);
    } catch (error) {
      console.error("Error generating palette:", error);
    } finally {
      setIsLoadingPalette(false); // Reset loading state after fetching data
    }
  };

  // Function to update state with the input text
  const handleInputChange = (event) => {
    setInputText(event.target.value);
    // Clear error message when user starts typing
    setErrorMessage("");
  };

  return (
    <>
      <div className="flashh">
        <h1 className="flashCardname2">Hex Code Generator</h1>

        <div className="jhgjhhiij">
          <form onSubmit={handleSubmit} className="jkkj">
            <p className="nhjyj">Enter your text</p>
            <input
              type="text"
              id="color"
              name="color"
              className="jkkghhhj"
              value={inputText}
              onChange={handleInputChange}
            />
            {errorMessage && <p className="ehbrror">{errorMessage}</p>}
            {Array.isArray(generatedColor) ? (
              // Render color palette if generatedColor is an array
              <div className="colorPalettes">
                {generatedColor.map((color, index) => (
                  <div
                    key={index}
                    className="colorBoxs"
                    style={{ backgroundColor: color }}
                  >
                    <span className="hh">{color}</span>
                  </div>
                ))}
              </div>
            ) : (
              generatedColor && (
                // Render single color if generatedColor is not an array
                <div className="colorPalette">
                  <p className="colorHex">{generatedColor.response}</p>
                  <div
                    className="colorBox"
                    style={{ backgroundColor: generatedColor.response }}
                  ></div>
                </div>
              )
            )}
            {!generatedColor && ( // Render "Generate" button only when no generated color exists
              <button type="submit" className="jnkjhb" disabled={isLoading}>
                {isLoading ? <PropagateLoader color="#ffffff" /> : "Generate"}
              </button>
            )}
          </form>
          {generatedColor && generatedColor.response && (
            <button
              onClick={handleGeneratePalette}
              className="jnkjhbg"
              disabled={isLoadingPalette}
            >
              {isLoadingPalette ? (
                <PropagateLoader color="#ffffff" />
              ) : (
                "Generate Color Palette"
              )}
            </button>
          )}
          {generatedColor && ( // Conditionally render clear button if generatedColor exists
            <button
              onClick={() => setGeneratedColor("")}
              className="clearButton"
            >
              Clear
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default HexCodeGenerator;
