import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../../styles/avatarGenerator.css";
import baseUrl from "../../baseUrl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RingLoader } from "react-spinners";
import brain1 from "../../assets/brain1.png";
import brain2 from "../../assets/brain2.png";
import brain3 from "../../assets/brain3.png";
import brain4 from "../../assets/brain4.png";
import edit from "../../assets/edit.png";
import download from "../../assets/download.png";
import share from "../../assets/shares.png";

const AvatarGenerator = () => {
  const [selectedLLM, setSelectedLLM] = useState("GPT 3.5");
  const [selectedImageGeneration, setSelectedImageGeneration] = useState("");
  const [selectedVision, setSelectedVision] = useState("");
  const [selectedSpeech, setSelectedSpeech] = useState("");
  const [currentImage, setCurrentImage] = useState(brain1);
  const [loading, setLoading] = useState(false); // New loading state
  const username = useSelector((state) => state.user.userName);
  const navigate = useNavigate();

  const handleLLMClick = (llm) => {
    setSelectedLLM(llm);
    setCurrentImage(brain1);
  };

  const handleImageGenerationClick = (generation) => {
    setSelectedImageGeneration(generation);
    setCurrentImage(brain2);
  };

  const handleVisionClick = (vision) => {
    setSelectedVision(vision);
    setCurrentImage(brain3);
  };

  const handleSpeechClick = (speech) => {
    setSelectedSpeech(speech);
    setCurrentImage(brain4);
  };

  const handleGenerateClick = async () => {
    setLoading(true); // Set loading to true when generating avatar
    const configuration = {
      llm: selectedLLM,
      imageGeneration: selectedImageGeneration,
      vision: selectedVision,
      speech: selectedSpeech,
      username,
    };

    try {
      await fetch(`${baseUrl}/avatar/save-selected-models`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(configuration),
      });

      setTimeout(() => {
        setLoading(false); // Set loading to false after delay
        navigate("/avatar3d");
      }, 3000); // Delay for 3 seconds before redirecting
    } catch (error) {
      console.error("Error saving selected models:", error);
      setLoading(false); // Set loading to false on error
    }
  };

  return (
    <div className="avatarGenerator">
      <h1 className="leaderboardTitle">Avatar Generator</h1>
      {loading && (
        <div className="loader">
          <RingLoader color="#6cd97e" loading={loading} size={100} />
        </div>
      )}
      <div className={`kjhuugf ${loading ? "loading" : ""}`}>
        <div className="avatarGenerator-left">
          <div className="section">
            <h2>LLM (Brain):</h2>
            <div className="butthons">
              {[
                "GPT 4",
                "GPT 3.5",
                "Gemini 1.5",
                "Gemma 7B",
                "Llama 8B",
                "Llama 70B",
                "Mixtral 8x7B",
                "GPT4-o",
              ].map((llm) => (
                <button
                  key={llm}
                  className={selectedLLM === llm ? "active" : ""}
                  onClick={() => handleLLMClick(llm)}
                >
                  {llm}
                </button>
              ))}
            </div>
          </div>
          <div className="section">
            <h2>Choose Image Generation:</h2>
            <div className="butthons">
              {["Dall 2", "Dalle 3", "Stability Stable Diffusion Core"].map(
                (generation) => (
                  <button
                    key={generation}
                    className={
                      selectedImageGeneration === generation ? "active" : ""
                    }
                    onClick={() => handleImageGenerationClick(generation)}
                  >
                    {generation}
                  </button>
                )
              )}
            </div>
          </div>
          <div className="section">
            <h2>Vision:</h2>
            <div className="butthons">
              {["Gpt4-o", "LLava"].map((vision) => (
                <button
                  key={vision}
                  className={selectedVision === vision ? "active" : ""}
                  onClick={() => handleVisionClick(vision)}
                >
                  {vision}
                </button>
              ))}
            </div>
          </div>
          <div className="section">
            <h2>Speech:</h2>
            <div className="butthons">
              {["OpenAI TTS", "Elevenlabs TTS"].map((speech) => (
                <button
                  key={speech}
                  className={selectedSpeech === speech ? "active" : ""}
                  onClick={() => handleSpeechClick(speech)}
                >
                  {speech}
                </button>
              ))}
            </div>
          </div>
          <div className="section">
            <button onClick={handleGenerateClick} className="generateBugtton">
              Generate Avatar
            </button>
          </div>
        </div>
        <div className="avatarGenerator-right">
          <img
            src={currentImage}
            alt="Generated Avatar"
            className="placeholdernj"
          />
          <div className="actions">
            <img src={edit} alt="Edit" className="edit1" />
            <img src={download} alt="Download" className="edit" />
            <img src={share} alt="Share" className="edit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarGenerator;
