import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/LandingPage.css";
import logo_imgs from "../assets/mainLogo.png";

const Navbar = () => {
  const location = useLocation();
  const [activeIcon, setActiveIcon] = useState(null);

  const handleIconClick = (icon) => {
    setActiveIcon(icon);
  };

  return (
    <>
      <div className="navbarlanding">
        <div className="mainnavbar">
          <img src={logo_imgs} alt="mainlogo" className="mainlogo" />
          <ul className="navlistt">
            <Link
              to="/home"
              className={`nabv ${location.pathname === "/home" ? "highlight" : ""}`}
              onClick={() => handleIconClick("/home")}
            >
              <li>Home</li>
            </Link>
            <Link
              to="/capabilities"
              className={`nabv ${location.pathname === "/capabilities" ? "highlight" : ""}`}
              onClick={() => handleIconClick("/capabilities")}
            >
              <li>Capabilities</li>
            </Link>
            <Link
              to="/blog"
              className={`nabv ${location.pathname === "/blog" ? "highlight" : ""}`}
              onClick={() => handleIconClick("/blog")}
            >
              <li>Blog</li>
            </Link>
          </ul>
          <Link to="/">
            {" "}
            <button className="getsdfst">Get Started</button>
          </Link>{" "}
        </div>
      </div>
    </>
  );
};

export default Navbar;
