import React from "react";
import { Environment, OrbitControls } from "@react-three/drei";
import { Avatar } from "./Avatar";

const Experience = ({ isPlaying, setIsPlaying }) => {
  console.log("Received isPlaying in Experience:", isPlaying); // Debugging log

  return (
    <>
      {/* OrbitControls with restricted panning and zooming */}
      <OrbitControls
        enablePan={false}
        enableZoom={false}
        enableRotate={true}
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        target={[0, -1.5, 0]} // Adjust the target to center the avatar
      />
      <Avatar
        position={[0, -9, 0]}
        scale={5.4}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />{" "}
      {/* Adjust position and scale */}
      <Environment preset="sunset" />
    </>
  );
};

export default Experience;
