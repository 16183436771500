import React, { useEffect, useRef } from "react";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { VscLoading } from "react-icons/vsc";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

const fetchPosts = async ({ pageParam = 1 }) => {
  try {
    const response = await apiService.get(
      `${baseUrl}/social/get-trending-posts?page=${pageParam}`
    );
    return {
      posts: response.data,
      nextPage: response.data.length > 0 ? pageParam + 1 : undefined,
      hasMore: response.data.length > 0,
    };
  } catch (e) {
    console.error(e.message);
    throw new Error("Error fetching posts");
  }
};

// Helper function to randomize grid spans
const getRandomSpan = (index) => {
  const spanPattern = [
    { rowSpan: 1, colSpan: 1 }, // Small block
    { rowSpan: 1, colSpan: 2 }, // Wide block
    { rowSpan: 2, colSpan: 1 }, // Tall block
    { rowSpan: 2, colSpan: 2 }, // Large block
  ];

  return spanPattern[index % spanPattern.length];
};

export const Explore = () => {
  const hasFetchedFirstPage = useRef(false);
  const navigate = useNavigate();
  const { userName } = useSelector((state) => state.user);
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const queryClient = useQueryClient();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["explore-posts"],
      queryFn: fetchPosts,
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.nextPage : undefined,
    });

  useEffect(() => {
    if (hasFetchedFirstPage.current && inView && hasNextPage) {
      fetchNextPage();
    }
    hasFetchedFirstPage.current = true;
  }, [inView, hasNextPage, fetchNextPage]);

  const appendNewPost = (newPost) => {
    queryClient.setQueryData(["posts", userName], (oldData) => {
      if (!oldData) return { pages: [{ posts: [newPost] }], pageParams: [1] }; // Handle case where there is no existing data
      return {
        ...oldData,
        pages: oldData.pages.map((page, index) => {
          if (index === 0) {
            return {
              ...page,
              posts: [newPost, ...page.posts],
            };
          }
          return page;
        }),
      };
    });
  };

  const handleViewPost = async (media) => {
    appendNewPost(media);
    navigate("/social", {
      state: {
        fromExplore: true,
      },
    });
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div
        className="grid grid-cols-4 gap-2 overflow-x-auto justify-stretch"
        style={{ gridAutoFlow: "dense", gridAutoRows: "40vh" }}
      >
        {isLoading ? (
          <div className="mt-6 w-full flex justify-center items-center">
            <ClipLoader color="white" />
          </div>
        ) : (
          data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={`page-${pageIndex}`}>
              {page?.posts?.map((media, index) => {
                const { rowSpan, colSpan } = getRandomSpan(index);
                return (
                  <div
                    key={`post-${index}`}
                    className={`bg-white rounded-sm cursor-pointer overflow-hidden`}
                    style={{
                      gridColumn: `span ${colSpan}`,
                      gridRow: `span ${rowSpan}`,
                    }}
                    onClick={() => {
                      handleViewPost(media);
                    }}
                  >
                    {media?.mediaUrls[0]?.type === "video" ? (
                      <ReactPlayer
                        url={media?.mediaUrls[0]?.url}
                        width="auto"
                        height="auto"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                        className="object-cover"
                        controls={true}
                        playing={true}
                        muted
                      />
                    ) : (
                      <img
                        src={media?.mediaUrls[0]?.url}
                        alt={media.caption || "Post image"}
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                );
              })}
            </React.Fragment>
          ))
        )}
      </div>
      <div
        ref={ref}
        className="min-h-[5vh] p-4 w-full justify-center items-center"
      >
        {isFetchingNextPage ? (
          <div className="w-full flex justify-center items-center ">
            <VscLoading className="loading-animation" size={30} color="white" />
          </div>
        ) : !hasNextPage && !isLoading ? (
          <p className=" font-normal text-base text-white text-center">
            No more posts left {":("}
          </p>
        ) : null}
      </div>
    </div>
  );
};
