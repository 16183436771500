import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import RegisterPopup from "../components/RegisterPopup";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setDisplayRegister } from "../features/userSlice";
import { getCookie } from "../utils/methods";

const ProtectedRoute = () => {
  const token = getCookie("token");
  const { isAuth, userName, userCredits, displayRegister } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const finalCredits = parseInt(userCredits, 10);

    const shouldDisplayRegister =
      finalCredits <= 0 ||
      !userName ||
      (userName && !userName.startsWith("guest_") && !token);
    if (shouldDisplayRegister) {
      dispatch(setAuth(false));
      dispatch(setDisplayRegister(true));
    } else {
      dispatch(setAuth(true));
      dispatch(setDisplayRegister(false));
    }
  }, [userCredits, userName]);

  if (userCredits <= 0) {
    return <Navigate to="/payment" />;
  }

  return (
    <>
      {isAuth ? <Outlet /> : displayRegister ? <RegisterPopup /> : <Outlet />}
    </>
  );
};

export default ProtectedRoute;
