import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import "../styles/flash_Card.css";

// Parse the flash card data to extract questions and answers
const parseResponse = (flashCardData) => {
  console.log("Parsing flash card data:", flashCardData);
  const response = flashCardData.response;
  const questionsAndAnswers = [];
  const regex = /Question (\d+): ([^\n]+)\s+Answer \1: ([^\n]+)/gs;
  let match;
  while ((match = regex.exec(response)) !== null) {
    questionsAndAnswers.push({
      question: match[2].trim(),
      answer: match[3].trim(),
    });
  }
  console.log("Parsed questions and answers:", questionsAndAnswers);
  return questionsAndAnswers;
};

const FlashCardResults = () => {
  const location = useLocation();
  const [flashCardData, setFlashCardData] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (location && location.state && location.state.flashCardData) {
      setFlashCardData(location.state.flashCardData);
      const parsedData = parseResponse(location.state.flashCardData);
      setQuestionsAndAnswers(parsedData);
    }
  }, [location]);

  useEffect(() => {
    setFlipped(false);
  }, [currentIndex]);

  if (!flashCardData || questionsAndAnswers.length === 0) {
    return <div>Loading or no data available...</div>;
  }

  const handleNextClick = () => {
    if (currentIndex < questionsAndAnswers.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  const speakText = (text) => {
    const utterance = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(utterance);
  };

  return (
    <div className="flashh">
      <h1 className="flashCardname">Flashcards</h1>
      <div className="flash-card-container">
        {questionsAndAnswers.length > 0 && (
          <div className={`flash-card ${flipped ? "flip" : ""}`}>
            <div className="front" onClick={handleCardClick}>
              <FontAwesomeIcon
                icon={faVolumeUp}
                className="sound-icon"
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation
                  speakText(questionsAndAnswers[currentIndex].question);
                }}
              />
              <p>{questionsAndAnswers[currentIndex].question}</p>
            </div>
            <div className="back" onClick={handleCardClick}>
              <FontAwesomeIcon
                icon={faVolumeUp}
                className="sound-icon"
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation
                  speakText(questionsAndAnswers[currentIndex].answer);
                }}
              />
              <p>{questionsAndAnswers[currentIndex].answer}</p>
            </div>
          </div>
        )}
        <div className="ygthhyt">
          <button
            onClick={handlePreviousClick}
            disabled={currentIndex === 0}
            className="jhuyoo"
          >
            Previous
          </button>
          <div className="currentbgg">
            {currentIndex + 1} / {questionsAndAnswers.length}
          </div>
          <button
            className="jhuyoo"
            onClick={handleNextClick}
            disabled={currentIndex === questionsAndAnswers.length - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlashCardResults;
