const Card = ({ title, content, onClickCall }) => {
    
    return (
        <div 
            className="bg-gray-800 hover:bg-gray-700 rounded-xl overflow-hidden mb-4 lg:w-full md:w-full p-2 cursor-pointer"
            onClick={() => onClickCall()}    
        >
            <div className="lg:p-8 md:p-8 p-4">
                <div className="text-white font-bold text-base mb-2">{title}</div>
                <p className="text-gray-300 text-sm">{content}</p>
            </div>
        </div>
    );
};

export default Card;
