export const collections = [
  {
    title: "Text-to-Text",
    description:
      "6 open-source models finely tuned on a paraphrase corpus of 6.3 million unique sentences.",
    type: "text2text",
    image: require("../assets/Ideaverse/texttotext/text_to_text.jpg"),
  },
  {
    title: "Text-to-Image",
    description:
      "Unlock a latent text-to-image diffusion model that excels at crafting photo-realistic visuals",
    type: "text2img",
    image: require("../assets/Ideaverse/texttoimage/4.jpg"),
  },
  {
    title: "Text-to-Audio",
    description:
      "Transform text into lifelike audio with the power of advanced text-to-speech technology",
    type: "text2audio",
    image: require("../assets/Ideaverse/texttoaudio/texttoaudio.jpg"),
  },
  {
    title: "Image-to-Video",
    description:
      "Get an AI generated video for images, and experience the magic of technology",
    type: "imageToVideo",
    image: require("../assets/Ideaverse/imagetoimage/Image to Image Model 1.png"),
  },
  {
    title: "Art",
    description: "Get your desired images with a touch of different art form",
    type: "artCollection",
    image: require("../assets/Ideaverse/artCollection/artCollectionsm.jpg"),
  },
  {
    title: "Future",
    description: "Explore your ideas and connect with future",
    type: "future",
    image: require("../assets/Ideaverse/future/futureSm.jpg"),
  },
  {
    title: "Nature",
    description:
      "Get close to nature with the vast varitety of image generated",
    type: "nature",
    image: require("../assets/Ideaverse/nature/natureSm.jpg"),
  },
  {
    title: "Experimentation",
    description:
      "Get close to nature with the vast varitety of image generated",
    type: "experiment",
    image: require("../assets/Ideaverse/experiment/experimentSm.jpg"),
  },
];
