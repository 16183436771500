import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IoMdCreate, IoMdCloudUpload } from "react-icons/io"; // Import React Icons
import { setChallengeId } from "../features/aiChallengeSlice"; // Import action
import "../styles/AIChallengeDetail.css";

const ChallengeDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fallback in case location state is not provided
  const challenge = location.state?.challenge;

  if (!challenge) {
    return <p>No challenge details available.</p>;
  }

  const handleGenerateNow = () => {
    // Dispatch challenge ID to Redux store
    dispatch(setChallengeId(challenge._id));

    // Navigate to the AI chat challenge route
    navigate("/aichat/aichallenge");
  };

  return (
    <div className="challenge-detail-container">
      <h1 className="challenge-detail-title">AI Challenge</h1>
      <p className="challenge-detail-subtitle">
        Model for generating text to image
      </p>
      <div className="challenge-detail-card">
        <img
          src={challenge.mediaUrls[0]?.url || ""}
          alt="Challenge Detail"
          className="challenge-detail-image"
        />
        <div className="challenge-detail-content">
          <div className="flexcohnt">
            <h3 className="challenge-detail-name">
              {challenge.name || "Challenge Name"}
            </h3>
            <p className="challenge-detail-description">
              {challenge.caption ||
                "Lorem ipsum dolor sit amet consectetur. Massa aenean varius nisl malesuada etiam dignissim accumsan purus."}
            </p>
          </div>
          <div className="challenge-detail-actions">
            <div></div>
            <div className="lkiuhybgv">
              <button
                className="generate-now-button"
                onClick={handleGenerateNow}
              >
                <IoMdCreate size={20} style={{ marginRight: "8px" }} />{" "}
                {/* Icon with margin */}
                Generate Now
              </button>
              <button className="upload-now-button">
                <IoMdCloudUpload size={20} style={{ marginRight: "8px" }} />{" "}
                {/* Icon with margin */}
                Upload Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeDetail;
