import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faPause } from "@fortawesome/free-solid-svg-icons";
import "../styles/interviewAI.css";
import baseUrl from "../baseUrl";
import { PropagateLoader } from "react-spinners";
import apiService from "../services/apiService";

const parseResponse = (interviewQuestions) => {
  const response = interviewQuestions.response;
  const questionsAndAnswers = [];
  const regex = /Question (\d+): ([^\n]+)(?=\n\n|$)/gs;
  let match;
  while ((match = regex.exec(response)) !== null) {
    questionsAndAnswers.push({
      question: match[2].trim(),
    });
  }
  return questionsAndAnswers;
};

const extractFeedbackAndSampleResponse = (response) => {
  // Use regular expressions to find the sections. This regex is case-insensitive (`i`) and captures multiline text.
  const feedbackPattern = /Feedback:\s*([\s\S]*?)\n(?=Sample response:)/i;
  const sampleResponsePattern = /Sample response:\s*([\s\S]*)$/i;

  const feedbackMatch = feedbackPattern.exec(response);
  const sampleResponseMatch = sampleResponsePattern.exec(response);

  const feedback = feedbackMatch
    ? feedbackMatch[1].trim()
    : "Feedback not found";
  const sampleResponse = sampleResponseMatch
    ? sampleResponseMatch[1].trim()
    : "Sample response not found";

  return { feedback, sampleResponse };
};

const InterviewQuestion = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");
  const finalTranscriptRef = useRef("");
  const recognitionRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [timer, setTimer] = useState(0);
  const timeoutRef = useRef(null);
  const [feedback, setFeedback] = useState("");
  const [sampleResponse, setSampleResponse] = useState("");
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status

  useEffect(() => {
    if (location && location.state && location.state.interviewQuestions) {
      const parsedData = parseResponse(location.state.interviewQuestions);
      setQuestions(parsedData);
    }
  }, [location]);

  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
      console.log("Stopped listening.");
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setTimer(0);
  };

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording]);

  const startRecording = () => {
    if (!isListening) {
      setText("");
      finalTranscriptRef.current = "";
      setTimer(0);
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognitionRef.current = recognition;
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onstart = () => {
        setIsListening(true);
        console.log("Listening started");
        timeoutRef.current = setTimeout(() => {
          console.log("Time limit reached. Stopping recording.");
          setIsRecording(false);
        }, 120000); // Stop after 120000 milliseconds (2 minutes)
      };

      recognition.onend = () => {
        console.log("Listening stopped");
        clearTimeout(timeoutRef.current);
      };

      recognition.onresult = (event) => {
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscriptRef.current += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        setText(finalTranscriptRef.current + interimTranscript);
      };

      recognition.start();
    } else {
      recognitionRef.current.stop();
      console.log("Listening stopped");
    }
  };

  const toggleRecording = () => {
    setIsRecording(!isRecording);
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
      setShowSubmitButton(true);
    }
  };

  useEffect(() => {
    console.log("Current Question Index Updated to:", currentQuestionIndex);
  }, [currentQuestionIndex]);

  const goToNextQuestion = () => {
    console.log(
      "Current Index:",
      currentQuestionIndex,
      "Questions Length:",
      questions.length
    );
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      console.log("Incremented Index:", currentQuestionIndex + 1);
      setIsRecording(false);
      setShowFeedback(false); // Reset feedback display when moving to next question
      setShowSubmitButton(false);
      setText(""); // Optionally clear the recorded text if applicable
    } else {
      console.log("Reached the last question.");
    }
  };

  useEffect(() => {
    let interval = null;
    if (isListening && isRecording) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isListening, isRecording]);

  const handleRetryRecording = () => {
    setIsRecording(false);
    setShowSubmitButton(false);
  };

  const handleFeedbackSubmit = async () => {
    setIsLoading(true); // Start loading
    try {
      const question = questions[currentQuestionIndex].question;
      const recording = text;
      console.log("Submitting feedback for question:", question);
      console.log("Recorded text:", recording);

      const response = await apiService.post(
        `${baseUrl}/ai/interviewQuestion`,
        {
          question,
          recording,
        }
      );

      console.log("Response from server:", response);
      const { feedback, sampleResponse } = extractFeedbackAndSampleResponse(
        response.response
      );
      setFeedback(feedback);
      setSampleResponse(sampleResponse);
      setShowFeedback(true); // Show feedback only after submission
      setIsLoading(false); // End loading
    } catch (error) {
      console.error("Error sending text to server:", error);
      setIsLoading(false); // End loading
    }
  };

  return (
    <div className="flashh">
      <h1 className="flashCardname3">Interview Questions</h1>
      <div className="quehhstion-card">
        <div className="oityu">
          <p className="questionnumber">{`Question ${currentQuestionIndex + 1}`}</p>
        </div>
        <div className="kjhcc">
          <p className="jhhjggii">{` ${
            questions[currentQuestionIndex]?.question || ""
          }`}</p>

          {!showFeedback ? (
            <>
              <div className="jkhg">
                <span
                  className={`timer-display ${isListening ? "listening" : ""}`}
                >
                  {Math.floor(timer / 60)}:
                  {timer % 60 < 10 ? `0${timer % 60}` : timer % 60} / 2:00
                </span>
              </div>
              <div className="kjhg">
                {showSubmitButton ? (
                  <>
                    <button
                      className="submit-feedback"
                      onClick={handleFeedbackSubmit}
                    >
                      Submit for feedback
                    </button>
                    <button
                      className="retry-recording"
                      onClick={handleRetryRecording}
                    >
                      Retry
                    </button>
                  </>
                ) : (
                  <div className="microphonhe-icon" onClick={toggleRecording}>
                    <FontAwesomeIcon
                      icon={isListening ? faPause : faMicrophone}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="kjhhou">
                <button
                  className="ertyr"
                  onClick={goToNextQuestion}
                  disabled={currentQuestionIndex === questions.length - 1}
                >
                  Next Question
                </button>
              </div>
            </>
          )}
          {isLoading && (
            <div className="bkjertt">
              <PropagateLoader color="#4caf50" />
            </div>
          )}
          {showFeedback && (
            <>
              <hr className="kjhg" />
              <div className="jhgfd">
                <div className="njhgfd">
                  <p className="jjjjj">Feedback</p>
                  <p className="feedback">{feedback}</p>
                </div>
                <hr className="kjghg" />
                <div className="njhgfd">
                  <p className="jjjjj">Sample Response</p>
                  <p className="feedback1">{sampleResponse}</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterviewQuestion;
