import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const ThreeJsAnimation = () => {
  const containerRef = useRef();

  useEffect(() => {
    let scene, camera, renderer, stars, lines;

    const init = () => {
      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
      camera.position.z = 5;

      stars = new THREE.Group();
      scene.add(stars);

      lines = new THREE.Group();
      scene.add(lines);

      const starMaterial = new THREE.PointsMaterial({
        color: 0x00aaff,
        size: 0.1,
      });
      const lineMaterial = new THREE.LineBasicMaterial({
        color: 0x00aaff,
        transparent: true,
        opacity: 0.3,
      });

      const numStars = 10;

      for (let i = 0; i < numStars; i++) {
        const geometry = new THREE.BufferGeometry();
        const position = new Float32Array(3);

        position[0] = (Math.random() - 0.5) * 10;
        position[1] = (Math.random() - 0.5) * 10;
        position[2] = (Math.random() - 0.5) * 10;

        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(position, 3)
        );

        const star = new THREE.Points(geometry, starMaterial);
        stars.add(star);

        // Connect stars with lines
        for (let j = i + 1; j < numStars; j++) {
          const lineGeometry = new THREE.BufferGeometry();
          const linePositions = new Float32Array(6);

          linePositions[0] = position[0];
          linePositions[1] = position[1];
          linePositions[2] = position[2];

          linePositions[3] = (Math.random() - 0.5) * 10;
          linePositions[4] = (Math.random() - 0.5) * 10;
          linePositions[5] = (Math.random() - 0.5) * 10;

          lineGeometry.setAttribute(
            "position",
            new THREE.BufferAttribute(linePositions, 3)
          );

          const line = new THREE.Line(lineGeometry, lineMaterial);
          lines.add(line);
        }
      }

      renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      containerRef.current.appendChild(renderer.domElement);

      animate();
    };

    const animate = () => {
      requestAnimationFrame(animate);

      stars.rotation.x += 0.001;
      stars.rotation.y += 0.001;
      lines.rotation.x += 0.001;
      lines.rotation.y += 0.001;

      renderer.render(scene, camera);
    };

    window.addEventListener("resize", () => {
      const newHeight = window.innerHeight;
      camera.aspect = 1;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, newHeight);
    });

    init();

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        height: "128vh",
        overflow: "hidden",
        background: "linear-gradient(to bottom, #0D1525, #02040a)",
      }}
    />
  );
};

export default ThreeJsAnimation;
