import React, { useState } from "react";
import send from "../../assets/send.png";
import baseUrl from "../../baseUrl";
import apiService from "../../services/apiService";

export const Comment = ({ comment, setComments, handleCommentCount }) => {
  function formatRelative(date, baseDate) {
    const diffInMs = baseDate - date;
    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  }

  const [replyingTo, setReplyingTo] = useState("");
  const [isReplying, setIsReplying] = useState(false);
  const [reply, setReply] = useState("");

  const handleSendReply = async (commentId) => {
    try {
      const response = await apiService.post(
        `${baseUrl}/social/replyComment/${commentId}`,
        {
          text: reply,
        }
      );
      if (response.success) {
        setReply("");
        const comments = await apiService.get(
          `${baseUrl}/social/getComments/${comment.postId}`
        );
        if (comments.data) {
          setComments(comments.data);
          handleCommentCount();
        }
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="comment-div">
      <div className="comment-profile">
        <div className="w-8 h-8 rounded-full bg-white"></div>
      </div>
      <div className="comment-content">
        <span className="comment-details">
          <h5>{comment.commentator.username}</h5>
          <p>
            {comment.createdAt
              ? formatRelative(new Date(comment.createdAt), new Date())
              : "now"}
          </p>
        </span>
        <p>{comment.text}</p>
        <div className="comment-btns">
          <button
            className="comment-btn"
            onClick={() => {
              setReplyingTo(comment.commentator.username);
              setIsReplying(!isReplying);
            }}
          >
            Reply
          </button>
        </div>
        {isReplying && replyingTo.length !== 0 && (
          <div className=" rounded-md p-2 w-full flex justify-start gap-2 border-b-2">
            <p>
              {"@"}
              {replyingTo}
            </p>
            <input
              type="text"
              value={reply}
              onChange={(e) => setReply(e.target.value)}
              className="bg-transparent w-[90%] outline-none"
              placeholder="Write a reply"
            />
            <div
              onClick={() => handleSendReply(comment._id)}
              className="cursor-pointer"
            >
              <img src={send} alt="send" />
            </div>
          </div>
        )}

        {comment.replies &&
          comment.replies.map((reply, id) => (
            <div className="comment-div reply" key={id}>
              <div className="comment-profile">
                <div className="w-6 h-6 rounded-full bg-white "></div>
              </div>
              <div className="comment-content ">
                <span className="comment-details">
                  <h5>{reply.commentator.username}</h5>
                  <p>
                    {reply.createdAt
                      ? formatRelative(new Date(reply.createdAt), new Date())
                      : "now"}
                  </p>
                </span>
                <p>{reply.text}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
