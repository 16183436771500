import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate from react-router-dom
import { handleLogout, setDisplayRegister } from "../features/userSlice";
import { deleteCookie } from "../utils/methods";
import { motion, AnimatePresence } from "framer-motion";
import { FaSignInAlt, FaHistory, FaHome } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoIosPricetags } from "react-icons/io";
import { SiGravatar } from "react-icons/si";
import { MdOutlineLeaderboard } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { AiFillWechat } from "react-icons/ai"; // Add missing import
import { TbTools } from "react-icons/tb";
import { SlSocialDribbble } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import logo_imgs from "../assets/mainLogo.webp";

export const Sidebar = ({
  showMenu,
  handleMenuClick,
  activeLink,
  handleMenuLinkClick,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { credits, userName, isAuth } = useSelector((state) => state.user);
  const handleLogoutButton = (e) => {
    e.preventDefault();
    deleteCookie("token");
    dispatch(handleLogout());
    dispatch(setDisplayRegister(true));
    handleMenuClick();
  };

  return (
    <AnimatePresence>
      {showMenu && (
        <>
          <motion.div
            className="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={handleMenuClick} // Close sidebar when backdrop is clicked
          />
          <motion.div
            className="hamburger"
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <IoClose
              color="#fff"
              size={40}
              className="close_btn_ham"
              onClick={handleMenuClick}
              whileHover={{ scale: 1.1 }}
            />
            <div className="logoings">
              <img src={logo_imgs} alt="Main logo" className="mainLogoIPs" />
              <h1 className="namingLogos">Ideaverse</h1>
            </div>
            {isAuth ? (
              <Link
                to="/subscription"
                className="creditAvailable"
                onClick={handleMenuClick} // Call the function directly without `navigate`
              >
                <p className="mlm">
                  <span className="dollarsign">$</span>
                  Your Credits:
                </p>
                <span className="credit_numbers">{credits}</span>
              </Link>
            ) : null}
            <div className="hamburger_btns">
              <Link
                to="/"
                className={`exploreing ${activeLink === "/" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/")}
              >
                <FaHome className="lool" />{" "}
                <span className="hmaicon">Home</span>
              </Link>
              <Link
                to="/aichat/youai"
                className={`exploreing ${activeLink === "/aichat/youai" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/aichat/youai")}
              >
                <AiFillWechat className="lool" />{" "}
                <span className="hmaicon">AI Chat</span>
              </Link>
              <Link
                to="/tools"
                className={`exploreing ${activeLink === "/tools" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/tools")}
              >
                <TbTools className="lool" />{" "}
                <span className="hmaicon">AI Tools</span>
              </Link>
              <Link
                to="/social"
                className={`exploreing ${activeLink === "/social" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/social")}
              >
                <SlSocialDribbble className="lool" />{" "}
                <span className="hmaicon">Social</span>
              </Link>
              <Link
                to="/history"
                className={`exploreing ${activeLink === "/history" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/history")}
              >
                <FaHistory className="lool" />{" "}
                <span className="hmaicon">History</span>
              </Link>
              <Link
                to="/createAvatar"
                className={`exploreing ${activeLink === "/createAvatar" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/createAvatar")}
              >
                <SiGravatar className="lool" />{" "}
                <span className="hmaicon">Create Avatar</span>
              </Link>
              <Link
                to="/promptLeaderboard"
                className={`exploreing ${activeLink === "/promptLeaderboard" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/promptLeaderboard")}
              >
                <MdOutlineLeaderboard className="lool" />{" "}
                <span className="hmaicon">Prompt Leaderboard</span>
              </Link>
              {isAuth ? (
                <Link
                  to="/subscription"
                  className={`pricee ${activeLink === "/subscription" ? "active" : ""}`}
                  onClick={() => handleMenuLinkClick("/subscription")}
                >
                  <IoIosPricetags className="lool" />{" "}
                  <span className="hmaicon">Pricing</span>
                </Link>
              ) : null}
              <Link
                to="/profile"
                className={`exploreing ${activeLink === "/profile" ? "active" : ""}`}
                onClick={() => handleMenuLinkClick("/profile")}
              >
                <CgProfile className="lool" />{" "}
                <span className="hmaicon">Profile</span>
              </Link>
              <Link
                to="#"
                className="sign_inout"
                onClick={(e) => {
                  handleLogoutButton(e);
                }}
              >
                <FaSignInAlt className="lool" />
                <span className="hmaicon">
                  {userName && userName.startsWith("guest_")
                    ? "Sign in"
                    : "Sign out"}{" "}
                </span>
              </Link>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};
