import React, { useEffect, useState } from "react";
import "../styles/LandingPage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import baseUrl from "../baseUrl";
import ThreeJsAnimation from "./ThreeJsAnimation";
import parse from "html-react-parser";
import CustomCursor from "./CursorAnimation";
import apiService from "../services/apiService";

const BlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    const fetchBlogDetail = async () => {
      try {
        const response = await apiService.get(
          `${baseUrl}/social/fetchBlogId/${id}`
        );
        const formattedDate = new Date(response.createdAt).toLocaleDateString(
          "en-US",
          {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        );
        response.createdAt = formattedDate;
        setBlog(response);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };

    fetchBlogDetail();
  }, [id]);

  return (
    <>
      <CustomCursor />
      <div className="maing">
        <Navbar data-aos="fade-up" />
        <div className="homhe">
          <div className="kjaeh">
            {loading ? (
              <div className="janjmjhn">
                <div className="bhnbhn1">
                  <DotLoader color={"#6cd97e"} loading={loading} size={70} />{" "}
                </div>
              </div>
            ) : (
              <>
                <div className="mhsba">
                  <h1 className="mnmnnh">{blog.blogTitle}</h1>
                  <p className="jbbnhbj">{blog.createdAt}</p>
                  <div className="mhdsbh">
                    <img src={blog.blogImageUrl} alt="" className="msbdh" />
                  </div>
                  <div className="djhh">
                    <div className="bhghhjbc">{parse(blog.blogContent)}</div>
                  </div>
                  <hr className="kljj" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPage;
