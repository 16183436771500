// CustomCursor.js
import React, { useState, useEffect } from "react";
import "../styles/LandingPage.css";

const CustomCursor = () => {
  const [ripples, setRipples] = useState([]);

  useEffect(() => {
    const onMouseMove = (e) => {
      const { clientX, clientY } = e;
      setRipples((prevRipples) => [
        ...prevRipples,
        { id: Date.now(), x: clientX, y: clientY },
      ]);
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRipples((prevRipples) => {
        // Remove ripples older than 1 second
        return prevRipples.filter((ripple) => Date.now() - ripple.id < 1000);
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [ripples]);

  return (
    <div className="custom-cursor">
      {ripples.map((ripple) => (
        <div
          key={ripple.id}
          className="ripple"
          style={{ top: ripple.y, left: ripple.x }}
        />
      ))}
    </div>
  );
};

export default CustomCursor;
