export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function setCookie(name, value, days) {
  let date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  let expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function generateOutput(response, service, type, model) {
  switch (service) {
    case "chatgpt":
      if (
        (type === "text2text" && model === "type1") ||
        (type === "speech2text" && model === "type1")
      ) {
        // console.log(response);
        if (
          response &&
          response.data &&
          response.data.output &&
          response.data.output[0]
        ) {
          return {
            outputType: "text",
            value: response.data.output[0].message.content,
          };
        } else {
          return null;
        }
      }
      if (type === "text2text" && model === "gpt4") {
        if (
          response &&
          response.data &&
          response.data.output &&
          response.data.output[0]
        ) {
          return {
            outputType: "text",
            value: response.data.output[0].message.content,
          };
        } else {
          return null;
        }
      }
      if (type === "text2img" && model === "dolly_3") {
        if (response && response.data && response.data) {
          return { outputType: "img", value: response.data[0].url };
        }
        {
          return null;
        }
      }
      if (type === "text2audio") {
        // console.log("Text 2 audio",response);
        if (response && response.data && response.data.url) {
          return { outputType: "audio", value: response.data.url };
        }
        {
          return null;
        }
      }
      if (type === "text2img" && model === "type1") {
        if (
          response &&
          response.data &&
          response.data.status === "processing"
        ) {
          return null;
        }
        if (
          response &&
          response.data &&
          response.data.output &&
          response.data.output[0]
        ) {
          return { outputType: "img", value: response.data.output[0].url };
        }
        {
          return null;
        }
      }
      if (type === "image2text" && model === "type1") {
        // console.log(
        //   "this is output",
        //   response &&
        //   response.data &&
        //   response.data.output &&
        //   response.data.output[0],
        // );
        if (
          response &&
          response.data &&
          response.data.output &&
          response.data.output[0]
        ) {
          return {
            outputType: "text",
            value: response.data.output[0].message.content,
          };
        }
        {
          return null;
        }
      }
      if (
        type === "artCollection" ||
        type === "future" ||
        type === "nature" ||
        type === "experiment"
      ) {
        if (response) {
          return { outputType: "img", value: response.data[0].url };
        }
      }

    case "stable_fusion":
      if (response && response.data) {
        if (response.data.status === "failed") {
          let errorArr = ["Something went wrong"];
          if (response.data.messege) {
            errorArr = [response.data.messege];
          }
          return { outputType: "error", value: errorArr };
        }
        if (response.data.status === "error") {
          let errorArr = ["Something went wrong"];
          if (response.data.messege) {
            errorArr = Object.keys(response.data.messege).map(
              (errData) => response.data.messege[errData]
            );
          } else if (response.data.message) {
            errorArr = [response.data.message];
          }
          console.log("errorArr", errorArr, response.data.messege);
          return { outputType: "error", value: errorArr };
        }
        if (response.data.status === "success") {
          if (
            (type === "text2img" && model === "type1") ||
            (type === "img2img" && model === "type1")
          ) {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "img", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
          if (type === "img_to_3d" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "img", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
          if (type === "text_to_3d" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "img", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
          if (type === "text2video" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "video", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
          if (type === "text2voice" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "voice", value: response.data.output[0] };
            }
            {
              return null;
            }
          }

          if (type === "text_to_speach" && model === "type1") {
            // console.log("T2S:",response.data.output[0])
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "audio", value: response.data.output[0] };
            } else {
              return null;
            }
          }

          if (type === "text2audio" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output[0]
            ) {
              return { outputType: "audio", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
        } else if (
          response.data.status === "starting" ||
          response.data.status === "processing"
        ) {
          return { outputType: "refetch", value: response.data.fetch_result };
        } else {
          return null;
        }
      } else {
        return null;
      }
    case "replicate":
      if (response.data.status === "failed") {
        let errorArr = ["Something went wrong"];
        if (response.data.messege) {
          errorArr = [response.data.messege];
        }
        return { outputType: "error", value: errorArr };
      }
      if (response && response.data) {
        if (response.data.status === "succeeded") {
          if (type === "text2video" && model === "type1") {
            if (
              response &&
              response.data &&
              response.data.output &&
              response.data.output
            ) {
              return { outputType: "img", value: response.data.output[0] };
            }
            {
              return null;
            }
          }
          if (type === "image2text" && model === "type1") {
            if (response && response.data && response.data.output) {
              return { outputType: "text", value: response.data.output };
            }
            {
              return null;
            }
          }
          if (type === "video2video" && model === "type1") {
            if (response && response.data && response.data.output) {
              return { outputType: "img", value: response.data.output };
            }
            {
              return null;
            }
          }
          if (type === "video2video" && model === "foreground") {
            if (response && response.data && response.data.output) {
              return { outputType: "video", value: response.data.output };
            }
            {
              return null;
            }
          }
          if (type === "frames_to_video" && model === "type1") {
            if (response && response.data && response.data.output) {
              return { outputType: "video", value: response.data.output };
            }
            {
              return null;
            }
          }
          if (type === "text_to_speach" && model === "type1") {
            // console.log("T2S:", response);
            if (response && response.data && response.data.output) {
              return { outputType: "audio", value: response.data.output };
            }
            {
              return null;
            }
          }
        } else if (
          response.data.status === "starting" ||
          response.data.status === "processing"
        ) {
          return { outputType: "refetch", value: response.data.urls.get };
        } else {
          return null;
        }
      } else {
        return null;
      }
    case "googlepalm":
      if (type === "text2text" && model === "type1") {
        // console.log("Goolle plan", response);
        if (response && response.data) {
          return { outputType: "text", value: response.data };
        } else {
          return null;
        }
      }

    case "stability":
      // console.log(`Stability \n response: ${response} \n,  service: ${service} \n,type: ${type} \n,model: ${model}`)
      if (type === "text2img" && (model = "type1")) {
        if (response && response.data) {
          return { outputType: "img", value: response.data.data[0].output };
        } else {
          return null;
        }
      }
      if (type === "img2img" && (model = "type1")) {
        if (response && response.data) {
          // console.log("Response for stability:", response.data.data[0].output);
          return { outputType: "img", value: response.data.data[0].output };
        } else {
          return null;
        }
      }

    case "huggingface":
      // console.log(`Hugging face \n response: ${response} \n,  service: ${service} \n,type: ${type} \n,model: ${model}}}`)
      if (type === "text2text" && model === "type1") {
        if (response && response.data && response.data.sequence) {
          return { outputType: "text", value: response.data.sequence };
        } else {
          return null;
        }
      }
      if (model === "hg_textTotext_apache" && type === "text2text") {
        if (response) {
          return { outputType: "text", value: response.data[0].generated_text };
        }
      }
      if (model === "hg_textTotext_llama2_70" && type === "text2text") {
        // console.log("Laama response:", response);
        if (response) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (model === "hg_textTotext_Zephyr" && type === "text2text") {
        // console.log("Zephyr response:", response);
        if (response && response.data) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (model === "hg_textTotext_llama2_7" && type === "text2text") {
        // console.log("Mistral response:", response.data.generated_text);
        if (response && response.data) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (model === "hg_textTotext_openchat" && type === "text2text") {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (model === "hg_textTotext_googleGemma" && type === "text2text") {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (model === "hg_textTotext_tinyLlama" && type === "text2text") {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "text", value: response.data.generated_text };
        }
      }
      if (
        model === "hg_textToimage_stablediffusion_xl_base" &&
        type === "text2img"
      ) {
        // console.log("Hugging image midjourney",response.data.url);
        if (response && response.data) {
          return { outputType: "img", value: response.data.url };
        }
      }
      if (model === "hg_textToimage_stablediffusion" && type === "text2img") {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "img", value: response.data.url };
        }
      }
      if (
        model === "hg_textToimage_stablediffusion_21" &&
        type === "text2img"
      ) {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "img", value: response.data.url };
        }
      }
      if (model === "hg_textToimage_stablediffusion_2" && type === "text2img") {
        // console.log("stable diffusion",response.data.url);
        if (response && response.data) {
          return { outputType: "img", value: response.data.url };
        }
      }

    default:
      console.log(`Service ${service} and type ${type} don't exist in UI`);
      break;
  }
}
