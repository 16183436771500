import React, { useState } from "react";
import { useSelector } from "react-redux";

export const Message = ({ message }) => {
  const sender = message.sender;
  const [showDate, setShowDate] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  function formatDate(dateString) {
    const date = new Date(dateString);
    let hour = date.getHours();
    const mins = date.getMinutes();
    let period = "AM";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    return `${hour}:${mins < 10 ? "0" : ""}${mins} ${period}`;
  }

  return (
    <div className="w-full flex flex-col px-2 my-2">
      <div
        className={`flex flex-col ${sender === userId ? "justify-center items-end" : "justify-center items-start"}`}
      >
        <div
          className={`w-auto min-w-[8%] flex gap-2 rounded-lg max-w-[75%] ${sender === "user" ? "bg-[#1B2333]" : "bg-[#1f2634]"}`}
          onClick={() => setShowDate(!showDate)}
        >
          <p className="text-[#CDCDCD] text-left p-2 text-sm">
            {message.content}
          </p>
          {message.createdAt && (
            <p className="text-[10px] font-bold pr-2 pb-1 items-end flex text-[#797474] ">
              {formatDate(message.createdAt)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
