import React, { useEffect, useState } from "react";
import apiService from "../../../services/apiService";
import baseUrl from "../../../baseUrl";
import { MessageUserWidget } from "./MessageUserWidget";

export const LeftPanel = () => {
  const [conversations, setConversations] = useState([]);
  const getConversations = async () => {
    try {
      const response = await apiService.get(`${baseUrl}/messaging/`);
      if (response) {
        setConversations(response);
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  useEffect(() => {
    getConversations();
  }, []);

  return (
    <div>
      {conversations?.map((user, id) => (
        <MessageUserWidget
          item={user.users}
          latestMessage={user.latestMessage[0]}
          key={id}
        />
      ))}
    </div>
  );
};
