import React, { useState } from "react";
import close from "../../assets/close_small.png";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import { VscLoading } from "react-icons/vsc";

const SharePostPopup = ({ onClose, sharingPost, handleSharePost, loading }) => {
  const [text, setText] = useState("");
  const username = useSelector((state) => state.user.userName);
  const imageUrl = useSelector((state) => state.user.imageUrl);
  const hash = CryptoJS.MD5(username).toString();

  return (
    <>
      <div className="post_popup">
        <div className="social_popup_box">
          <div className="popup_propf">
            <div className="name_propgj">
              {imageUrl ? (
                <img src={imageUrl} alt="user" className="post_own_profile" />
              ) : (
                <Gravatar
                  email={username}
                  size={150}
                  default="identicon"
                  className="post_own_profile0"
                  hash={hash}
                />
              )}{" "}
              <p className="post_namet">{username}</p>
            </div>
            <img src={close} alt="" className="close_small" onClick={onClose} />
          </div>
          <div className="w-full py-4 ">
            <input
              className="bg-transparent outline-none text-[#c6c6c6] placeholder:text-[#8d8c8c] text-base p-2 w-full"
              placeholder="Share your thoughts about the post..."
              onChange={(e) => setText(e.target.value)}
              type="text"
              value={text}
            />

            <div className="mt-2 bg-gray-800 rounded-xl p-2 flex gap-2 justify-start items-center">
              {sharingPost?.mediaUrls?.length !== 0 && (
                <div className="rounded-xl w-[18%] h-24 overflow-hidden">
                  <img
                    src={sharingPost?.mediaUrls?.[0].url}
                    alt=""
                    className="object-fit h-full w-full"
                  />
                </div>
              )}

              <div className="w-4/5">
                <b className="text-base text-white px-2">
                  @{sharingPost?.username}
                </b>
                <p className="text-sm text-white p-2">
                  {sharingPost?.caption &&
                    sharingPost.caption.split(" ").slice(0, 20).join(" ") +
                      "..."}
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end items-end">
            <div className="btns_cobine">
              <button className="disocard" onClick={() => onClose()}>
                Discard
              </button>
              <button
                className="postpost"
                onClick={() => {
                  handleSharePost(text);
                }}
              >
                {loading ? (
                  <VscLoading
                    className="loading-animation"
                    size={20}
                    color="white"
                  />
                ) : (
                  "Post"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharePostPopup;
