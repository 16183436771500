import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: null,
  userId: null,
  userEmail: null,
  isAuth: false,
  ipAddress: null,
  guestAccount: true,
  credits: 0,
  displayRegister: false,
  imageUrl: null,
  userInterests: [],
  notificationCount: 0,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIpAddress: (state, action) => {
      state.ipAddress = action.payload;
    },
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    setGuestAccount: (state, action) => {
      state.guestAccount = action.payload;
    },
    handleLogout: (state, action) => {
      state.userName = null;
      state.userId = null;
      state.userEmail = null;
      state.isAuth = false;
      state.credits = 0;
      state.ipAddress = null;
      state.userInterests = [];
      state.imageUrl = null;
    },
    setDisplayRegister: (state, action) => {
      state.displayRegister = action.payload;
    },
    setUserInterests: (state, action) => {
      state.userInterests = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    updateNotification: (state, action) => {
      if (action.payload !== undefined) {
        state.notificationCount = action.payload;
      } else {
        state.notificationCount += 1;
      }
    },
  },
});
export const {
  setUserEmail,
  setUserName,
  setUserId,
  setAuth,
  setIpAddress,
  setCredits,
  handleLogout,
  setGuestAccount,
  setDisplayRegister,
  setUserInterests,
  setImageUrl,
  updateNotification,
} = userSlice.actions;
export default userSlice.reducer;
