import { useEffect, useState } from "react";
import { Search } from "react-feather";
import ToolCard from "./ToolCard";
import apiService from "../services/apiService";
import MobileNavBar from "../components/MobileNavbar";
import baseUrl from "../baseUrl";

const ToolsHome = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [tools, setTools] = useState([]);
  const [filteredTools, setFilteredTools] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 437);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 437);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedTools = localStorage.getItem("toolsData");
        if (cachedTools) {
          const parsedTools = JSON.parse(cachedTools);
          setTools(parsedTools);
          setFilteredTools(parsedTools);
        } else {
          const response = await apiService.get(`${baseUrl}/ai/get-tool`);
          console.log("Response:", response);

          if (Array.isArray(response)) {
            // Cache the data in local storage
            localStorage.setItem("toolsData", JSON.stringify(response));
            setTools(response);
            setFilteredTools(response);
          } else {
            console.error("Unexpected response format:", response);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFilteredTools(
      tools.filter((tool) =>
        tool.heading.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tools]);

  return (
    <div className="flex w-full">
      <div className="w-full lg:px-16 md:px-16 px-5">
        <div className="py-10">
          <h1 className="text-white font-semibold text-xl pb-4">AI Tools</h1>
        </div>

        <div className="flex items-center justify-center w-full mb-8">
          <div className="flex w-full bg-[#1c2331] rounded-full border-2 border-gray-500">
            <input
              type="text"
              placeholder="Search Anything..."
              className="flex-grow rounded-l-full py-2 px-4 focus:outline-none bg-transparent text-white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="px-4 rounded-r-full">
              <Search color="white" />
            </button>
          </div>
        </div>

        <div
          className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-6"
          style={{
            marginBottom: "90px",
          }}
        >
          {filteredTools.map((tool, index) => (
            <div key={index} className="bg-[#1c2331] rounded-xl shadow-lg p-4">
              <ToolCard
                heading={tool.heading}
                paragraph={tool.paragraph}
                link={tool.link}
                iconSvg={tool.iconSvg}
              />
            </div>
          ))}
        </div>
      </div>
      {isMobile && <MobileNavBar />}
    </div>
  );
};

export default ToolsHome;
