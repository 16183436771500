import React, { useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../styles/flash_Card.css";
import featured from "../assets/upload.png";
import selcetdImg from "../assets/selectedimg.png";
import trash from "../assets/trash.png";
import { PropagateLoader } from "react-spinners";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";

const LoadingSpinner = () => (
  <div className="modal-overlay">
    <div className="modal">
      <p className="juhyj">Generating</p>
      <div className="spinner-container">
        <PropagateLoader color="#4caf50" />
      </div>
      <p className="ngvfgc">Your flashcards are being created</p>
    </div>
  </div>
);

const FlashCard = () => {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [response, setResponse] = useState("");
  const navigate = useNavigate();

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    console.log("File selected:", file);

    if (
      file &&
      (file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword")
    ) {
      setSelectedFile({ file, progress: 0 });
      setUploadProgress(0);
      fileInputRef.current.setAttribute("disabled", true);
      event.target.value = null;

      const interval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            setShowGenerateButton(true);
            return 100;
          } else {
            return prevProgress + 10;
          }
        });
      }, 500);
    } else {
      alert("Please select a valid PDF or Word document.");
    }
  };

  const sendFileToServer = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    setShowLoadingPopup(true);
    console.log("formData:", formData.get("file")); // This should log the actual File object

    try {
      const response = await apiService.post(
        `${baseUrl}/ai/flashCardModel`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Response from server:", response);
      // Uncomment to navigate or handle the response
      // navigate("/tools/flash_card_results", { state: { flashCardData: response.data } });
    } catch (error) {
      console.error("Error sending file to server:", error);
    } finally {
      setShowLoadingPopup(false);
    }
  };

  const handleGenerateClick = () => {
    console.log("selectedFile:", selectedFile);
    if (selectedFile) {
      setShowLoadingPopup(true);
      sendFileToServer(selectedFile.file); // Pass the actual file object
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      const file = event.dataTransfer.files[0];
      handleFileInputChange({ target: { files: [file] } });
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    fileInputRef.current.removeAttribute("disabled");
    setShowGenerateButton(false);
  };

  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes >= 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (sizeInBytes >= 1024) {
      return (sizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  };

  return (
    <div className="flashh">
      <h1 className="flashCardname">Flash Card Generator</h1>
      <div className="uploadflash">
        <div className="hjj">
          <p className="uploadand">Upload and attach files</p>
          <div
            className="bpundary"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className="bouninside">
              <div className="jkhhb">
                <img src={featured} alt="upload" className="lofiimg" />
              </div>
              <p className="uploadfile">
                <span
                  className="hshjjh"
                  onClick={() => fileInputRef.current.click()}
                >
                  Click to upload{" "}
                </span>
                or drag and drop
              </p>
              <p className="hbghjb">PDF or MS Word files only</p>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                accept=".pdf,.doc,.docx"
                disabled={selectedFile !== null}
              />
            </div>
          </div>
          <div className="kuhjbg">
            {selectedFile && (
              <div className="selcetdFile">
                <img src={selcetdImg} alt="img" className="hbghoo" />
                <div className="jhjg">
                  <p className="selectedFileName">{selectedFile.file.name}</p>
                  <p className="selectedFileSize">
                    {formatFileSize(selectedFile.file.size)}
                  </p>
                  <div className="jhgth">
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                    <div className="progress-text">{`${uploadProgress}%`}</div>
                  </div>
                </div>
                {uploadProgress === 100 && (
                  <img
                    src={trash}
                    alt="delete"
                    className="hjbkk"
                    onClick={handleRemoveFile}
                  />
                )}
              </div>
            )}
          </div>
          {showGenerateButton && (
            <div className="hjyghh">
              <button className="generatet" onClick={handleGenerateClick}>
                Generate
              </button>
            </div>
          )}
        </div>
      </div>
      {showLoadingPopup && <LoadingSpinner />}
      {response && (
        <div className="response-output">
          <h3>Generated Questions and Answers:</h3>
          <pre>{response}</pre>
        </div>
      )}
    </div>
  );
};

export default FlashCard;
