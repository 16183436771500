import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../baseUrl";
import Navbar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "../services/apiService";

const DeleteAccountPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleDeleteAccount = async (event) => {
    event.preventDefault(); // Prevent default form submission
    // Validate that all fields are filled
    if (!username || !email || !password) {
      toast.error("Please fill in all fields before proceeding.");
      return;
    }
    try {
      const response = await apiService.post(`${baseUrl}/auth/deleteAccount`, {
        username,
        email,
        password,
      });

      const data = response;

      // If the account is deleted successfully, clear input fields
      if (data.success) {
        setUsername("");
        setEmail("");
        setPassword("");
        toast.success("Account deleted successfully.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Handle the case when the user is not found
        toast.error("User not found");
      } else {
        console.error("Error deleting account:", error);
      }
    }
  };

  return (
    <div className="maing">
      <Navbar data-aos="fade-up" />
      <div className="homhe">
        <div className="jdhbjksh">
          <h1 className="shjj">Account</h1>
          <form action="" className="bhbnj">
            {/* Add onChange handlers for input fields */}
            <div className="dgsvh">
              <label htmlFor="" className="jnsjx">
                Username
              </label>
              <input
                type="text"
                className="hjbjb"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="dgsvh">
              <label htmlFor="" className="jnsjx">
                Email
              </label>
              <input
                type="email"
                className="hjbjb"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="dgsvh">
              <label htmlFor="" className="jnsjx">
                Password
              </label>
              <input
                type="password"
                className="hjbjb"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="ksjkan">
              <button className="jdbjj" onClick={handleDeleteAccount}>
                Delete Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPage;
