import React from "react";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { selectChat } from "../../../features/chatSlice";

export const MessageUserWidget = ({ item, latestMessage }) => {
  const hash = CryptoJS.MD5(item.username).toString();
  const dispatch = useDispatch();
  const selectedChat = useSelector((state) => state.socialChat.selectedChat);
  const isActive = selectedChat?._id === item._id ? true : false;
  const handleClick = () => {
    dispatch(selectChat(item));
  };
  return (
    <div
      className={`flex justify-between gap-1 p-2 my-2 cursor-pointer items-center rounded-lg ease-in-out hover:bg-[#222c3f] ${isActive && "bg-[#1B2333] border-[0.5px]"} `}
      onClick={() => handleClick()}
    >
      <div className=" rounded-full bg-white ">
        {item.imageUrl ? (
          <img src={item.imageUrl} alt="logo.png" className="boy_prof" />
        ) : (
          <Gravatar
            email={item.username}
            size={150}
            default="identicon"
            className="boy_prof"
            hash={hash}
          />
        )}
      </div>
      <div className="text-left w-3/4">
        <p className="text-base text-[#CDCDCD] truncate">{item.username}</p>
        <p className="text-sm text-[#CDCDCD] truncate">
          {latestMessage?.content}
        </p>
      </div>
    </div>
  );
};
