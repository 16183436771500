import React, { useState } from "react";
import axios from "axios";
import baseUrl from "../baseUrl";
import "../styles/businessAiTool.css";
import apiService from "../services/apiService";

const BusinessAiTool = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    businessName: "",
    description: "",
    reportType: "",
    businessLocation: "",
    investment: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    setStep(step + 1);
  };

  const handleSubmit = async (formData) => {
    try {
      const response = await apiService.post(
        `${baseUrl}/ai/businessPlan`,
        formData
      );
      console.log("Server response:", response);
      // Handle success response from server
    } catch (error) {
      console.error("Error occurred:", error);
      // Handle error
    }
  };

  const handleGenerateClick = async (event) => {
    event.preventDefault();
    // Merge formData from both steps
    const allFormData = { ...formData };

    // Handle form submission here
    console.log("All Form data:", allFormData);

    // Call API to send form data to server
    await handleSubmit(allFormData);
  };

  return (
    <>
      <div className="flashh">
        <h1 className="flashCardname1">Evaluate Business AI Tool</h1>

        <div className="jhgjhhiij">
          <p className="jhuygfc">Answer the questions below to get started</p>

          {step === 1 && (
            <form className="jkkjggv" onSubmit={handleNextClick}>
              <p className="nhbvjyj">Enter the name of the business</p>
              <input
                type="text"
                name="businessName"
                className="kihjh"
                placeholder="Enter business name"
                value={formData.businessName}
                onChange={handleInputChange}
              />
              <p className="nhbvjyj">Describe your business</p>
              <textarea
                name="description"
                className="kihhhjh"
                placeholder="Describe your business"
                value={formData.description}
                onChange={handleInputChange}
              ></textarea>
              <div className="jhbg">
                <div className="nhhjk">
                  <p className="nhbvjyj">Business Name</p>
                  <input
                    type="text"
                    name="businessShortName"
                    className="smallinput"
                    placeholder="Enter business shortname"
                    value={formData.businessShortName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="nhhjk">
                  <p className="nhbvjyj">Report Type</p>
                  <input
                    type="text"
                    name="reportType"
                    className="smallinput"
                    placeholder="Enter report type"
                    value={formData.reportType}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <button type="submit" className="xhsakj">
                Next
              </button>
            </form>
          )}

          {step === 2 && (
            <form className="jkkjggv" onSubmit={handleGenerateClick}>
              <p className="nhbvjyj">
                {" "}
                Use the website URL to extract information
              </p>
              <input
                type="text"
                name="busineddInfo"
                className="kihjh"
                placeholder="Enter business location"
                value={formData.busineddInfo}
                onChange={handleInputChange}
              />
              <p className="nhbvjyj">Describe your business income</p>
              <textarea
                name="income"
                className="kihhhjh"
                placeholder="Describe your business"
                value={formData.income}
                onChange={handleInputChange}
              ></textarea>
              <div className="jhbg">
                <div className="nhhjk">
                  <p className="nhbvjyj">Business location</p>
                  <input
                    type="text"
                    name="businessLocation"
                    className="smallinput"
                    placeholder="Enter business location"
                    value={formData.businessLocation}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="nhhjk">
                  <p className="nhbvjyj">Investment</p>
                  <input
                    type="text"
                    name="investment"
                    className="smallinput"
                    placeholder="Enter investment"
                    value={formData.investment}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <button type="submit" className="xhsakj">
                Generate
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default BusinessAiTool;
