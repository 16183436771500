import React, { useState, useRef, useEffect } from "react";
import "../styles/VoiceAssistant.css";
import baseUrl from "../baseUrl";
import { DotLoader } from "react-spinners";
import { PulseLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faPause } from "@fortawesome/free-solid-svg-icons";
import apiService from "../services/apiService";

const VoiceAssistant = () => {
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");
  const finalTranscriptRef = useRef("");
  const [speaking, setSpeaking] = useState(false);
  const recognitionRef = useRef(null);
  const silenceTimerRef = useRef(null);
  const [visual, setVisual] = useState("idle"); // New visual state
  const [barHeights, setBarHeights] = useState([70, 110, 90, 130, 80]); // Initial heights of bars

  useEffect(() => {
    stopSpeaking();
  }, []);

  const stopSpeaking = () => {
    window.speechSynthesis.cancel(); // This stops the speech synthesis
    setSpeaking(false); // Update the speaking state to reflect the change
  };

  const handleListen = () => {
    setVisual("listening"); // Change visual state to 'listening' when it actually starts
    if (!isListening) {
      setText("");
      finalTranscriptRef.current = "";
      setVisual("starting"); // This sets the visual to the 'starting' state where the circle will start to scale
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognitionRef.current = recognition;
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onstart = () => {
        setIsListening(true);
        setVisual("listening"); // Change visual state to 'listening' when it actually starts
        console.log("Listening started");
        resetSilenceTimer();
      };

      recognition.onend = () => {
        setIsListening(false);
        if (finalTranscriptRef.current.trim().length > 0) {
          setVisual("processing"); // Set to processing if there is text
          console.log("Processing text.");
          sendTextToServer(finalTranscriptRef.current);
        } else {
          setVisual("idle"); // Set back to idle if there is no text
          console.log("Listening stopped without input.");
        }
      };

      recognition.onresult = (event) => {
        resetSilenceTimer();
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscriptRef.current += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        setText(finalTranscriptRef.current + interimTranscript);
      };

      recognition.start();
    } else {
      recognitionRef.current.stop();
      setIsListening(false);
      setVisual("idle");
    }
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimerRef.current);
    silenceTimerRef.current = setTimeout(() => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        setIsListening(false);
        console.log("Stopped listening due to silence.");
      }
    }, 3000); // Stop after 3 seconds of silence
  };

  const sendTextToServer = async (text) => {
    if (text.trim().length === 0) {
      console.log("No text to send to server.");
      return;
    }

    try {
      console.log("Sending text to server:", text);
      const response = await apiService.post(`${baseUrl}/ai/voiceAssistant`, {
        text,
      });
      const responseText = response.response || "No response text provided";
      speakText(responseText);
      setText("");
    } catch (error) {
      console.error("Error sending text to server:", error);
    }
  };

  const speakText = (text) => {
    if (!window.speechSynthesis) {
      alert("Speech synthesis not supported in this browser.");
      return;
    }
    setVisual("processing"); // Ensure visual state is set to processing when preparing to speak
    const duration = calculateDuration(text);
    const utterance = new SpeechSynthesisUtterance(text);
    let intervalId;

    utterance.onstart = () => {
      setSpeaking(true);
      setVisual("speaking"); // Change visual to speaking state when speech starts
      const startTime = Date.now();

      intervalId = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const progress = elapsed / duration;
        const heights = barHeights.map(
          (baseHeight) => baseHeight + Math.sin(progress * Math.PI * 2) * 20
        );
        setBarHeights(heights);

        if (elapsed >= duration) {
          clearInterval(intervalId);
        }
      }, 100); // Update heights every 100 ms
    };

    utterance.onend = () => {
      setVisual("idle"); // Reset to initial idle state
      clearInterval(intervalId);
      setSpeaking(false);
    };

    window.speechSynthesis.speak(utterance);
  };
  const calculateDuration = (text) => {
    const words = text.split(" ");
    const wordCount = words.length;
    return (wordCount / 150) * 60 * 1000; // returns duration in milliseconds
  };

  const handlePause = () => {
    stopSpeaking();
    setVisual("idle");
  };

  return (
    <div className="VoiceAssistant">
      <div className="visualContainer">
        {visual === "idle" && <div className="idleVisual"></div>}
        {visual === "starting" && <div className="startingVisual"></div>}
        {visual === "listening" && <div className="listeningVisual"></div>}
        {visual === "processing" && (
          <div className="processingVisual">
            <div className="processingCircle"></div>
          </div>
        )}
        {/* Processing visual */}
        {visual === "speaking" && (
          <div className="speakingVisual">
            {barHeights.map((height, index) => (
              <div
                key={index}
                className="bar"
                style={{ height: `${height}px` }}
              ></div>
            ))}
          </div>
        )}
      </div>
      <div className="voinj">
        <div className="knjhgg">
          <div className="jhgjjk">
            {visual === "idle" && <div className="promptjbh">Speak now...</div>}
            {visual === "listening" && (
              <div className="visualizer">Listening...</div>
            )}
          </div>
          <div className="mkjnhb">
            {visual === "idle" && (
              <div className="jhjjj">
                <FontAwesomeIcon icon={faMicrophone} onClick={handleListen} />
              </div>
            )}
            {visual === "listening" && (
              <div className="rtyu">
                <PulseLoader color="#FFFFFF" loading={true} size={8} />
              </div>
            )}

            {visual === "processing" && (
              <div className="lkjiuo">
                <DotLoader color="#FFFFFF" loading={true} size={28} />
              </div>
            )}
            {visual === "speaking" && (
              <div className="jhjjj">
                <FontAwesomeIcon icon={faPause} onClick={handlePause} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VoiceAssistant;
