import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../../services/apiService";
import baseUrl from "../../baseUrl";
import { PostWidget } from "../Widgets/PostWidget";
import { InteractScreen } from "./InteractScreen";
export const InteractPost = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(false);

  const getPostById = async () => {
    try {
      if (!postId) {
        return;
      }
      const response = await apiService.get(
        `${baseUrl}/social/post-details/${postId}`
      );
      if (response) {
        setPost(response.post);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getPostById();
  }, []);
  return (
    <div className="flex w-full px-4 justify-between items-center">
      <div className="hidden md:block w-1/3 h-[90vh] overflow-y-auto">
        <PostWidget
          setPostLikeVisible={() => {}}
          setSelectedPost={() => {}}
          post={post}
        />
      </div>
      <div className="w-full md:w-3/5 h-[83vh]">
        {post && <InteractScreen post={post}></InteractScreen>}
      </div>
    </div>
  );
};
