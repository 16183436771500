import { Link } from "react-router-dom";

const ToolCard = ({ heading, paragraph, link, iconSvg }) => {
  return (
    <Link to={`/tools/${link}`}>
      <div className="bg-[#1c2331] rounded-xl flex flex-col md:flex-row p-4 md:p-6 space-y-4 md:space-y-0 md:space-x-4">
        <div
          className="flex items-center justify-center rounded-full bg-[#6CD97E] p-5"
          style={{ width: "60px", height: "60px" }}
        >
          <div
            className="w-8 h-8 flex items-center justify-center"
            dangerouslySetInnerHTML={{ __html: iconSvg }}
            style={{ width: "18px", height: "19px" }}
          />
        </div>
        <div className="flex-grow flex flex-col justify-center">
          <p className="text-white font-semibold text-sm truncate">{heading}</p>
          <p
            className="text-gray-400 text-sm md:text-sm mt-1 max-h-16 overflow-hidden text-ellipsis"
            style={{
              wordWrap: "break-word",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {paragraph}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ToolCard;
