import React, { useEffect, useState } from "react";
import "../styles/social_feature.css";
import icon2 from "../assets/icon2.png";
import baseUrl from "../baseUrl";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import SearchModal from "./SearchModal";
import apiService from "../services/apiService";
import { ClipLoader } from "react-spinners";

export const SocialLeft = () => {
  const [trendingTags, setTrendingTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const fetchTrendingTags = async () => {
    setLoading(true);
    try {
      const response = await apiService.get(`${baseUrl}/social/trendingTags`);
      if (response) {
        setTrendingTags(response.tags);
      } else {
        console.log("Something went wrong");
      }
    } catch (e) {
      console.log("Something went wrong", e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrendingTags();
  }, []);

  return (
    <>
      <div className={` social_left_trending hidden md:block `}>
        <div
          className="trending_social"
          style={{ justifyContent: "space-between", gap: "1rem" }}
        >
          <span className="flex gap-[1rem]">
            <img src={icon2} alt="trending" className="ten_img" />
            <p className="trending_name_socail">Trending</p>
          </span>
          <div
            className={`flex rounded-md py-2 sm:px-2 bg-gray-700 items-center cursor-pointer`}
          >
            <FaSearch
              color="rgb(156, 163, 175"
              onClick={() => setSearchClicked(true)}
            />
          </div>
        </div>
        <hr className="social_line" />
        <div className="trending_topics">
          {loading ? (
            <div className="w-full flex justify-center">
              <ClipLoader size={30} color="white" />
            </div>
          ) : trendingTags?.length === 0 ? (
            <div className="w-full flex justify-center text-[#8d8d8d]">
              Nothing is trending right now
            </div>
          ) : (
            trendingTags?.map((tag, id) => (
              <div className="hsgt" key={id}>
                <Link to={`/toptrends/${tag._id.substring(1)}`}>
                  <p className="trending_topics_name">{tag._id}</p>
                  <p className="haspots">{tag.count} Post</p>
                </Link>
              </div>
            ))
          )}
        </div>
        <div className=" flex-grow"></div>
      </div>
      {searchClicked && (
        <SearchModal onClose={() => setSearchClicked(!searchClicked)} />
      )}
    </>
  );
};
