import React, { useEffect } from "react";
import "../styles/LandingPage.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ThreeJsAnimation from "./ThreeJsAnimation";
import CustomCursor from "./CursorAnimation";

const PrivacyPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
      <CustomCursor />
      <div className="maing">
        <Navbar data-aos="fade-up" />
        <div className="animatedcontainer1">
          <ThreeJsAnimation />
          <div className="home1">
            <h1 className="pkijuh" data-aos="fade-up">
              Privacy Policy
            </h1>
            <div className="jsahbx">
              <p className="ijhygh" data-aos="fade-up">
                Introduction
              </p>
              <p className="hbxb" data-aos="fade-up">
                Welcome to Ideaverse by Tensorblue Technologies Private Limited
                Company We are committed to protecting your personal information
                and your right to privacy. If you have any questions or concerns
                about this privacy policy, or our practices with regards to your
                personal information, please contact us at
                contact@tensorblue.com
              </p>
              <p className="hbxb" data-aos="fade-up">
                When you visit our Ideaverse platform ("Platform"), and more
                generally, use any of our services (the "Services", which
                include the Platform), we appreciate that you are trusting us
                with your personal information. We take your privacy very
                seriously. In this privacy policy, we seek to explain to you in
                the clearest way possible what information we collect, how we
                use it, and what rights you have in relation to it. We hope you
                take some time to read through it carefully.
              </p>{" "}
            </div>
            <div className="jsahbx">
              <p className="ijhygh" data-aos="fade-up">
                Information We Collect
              </p>
              <p className="hbxb" data-aos="fade-up">
                We collect personal information that you voluntarily provide to
                us when you register on the Platform, express an interest in
                obtaining information about us or our products and Services,
                when you participate in activities on the Platform, or otherwise
                when you contact us.
              </p>
            </div>
          </div>
        </div>
        <div className="hokhu">
          <p className="hbxb" data-aos="fade-up">
            The personal information we collect can include the following:
          </p>{" "}
          <ul className="jku" data-aos="fade-up">
            <li className="hbhj">
              Name and Contact Data: Your first and last name, email address,
              postal address, phone number, and other similar contact data.
            </li>
            <li className="hbhj">
              Credentials: Passwords, password hints, and similar security
              information used for authentication and account access.
            </li>
            <li className="hbhj">
              Social Media Login Data: We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter, or other social media account.
            </li>
            <li className="hbhj">
              Content: Information about the content you create, upload, or
              share on the Platform, including any text, images, speech and
              videos.
            </li>
          </ul>
          <div className="jsahbx">
            <p className="ijhygh" data-aos="fade-up">
              How We Use Your Information
            </p>
            <p className="hbxb" data-aos="fade-up">
              We use personal information collected via our Platform for a
              variety of business purposes described below:
            </p>
            <ul className="jku" data-aos="fade-up">
              <li className="hbhj">
                To facilitate account creation and the logon process.
              </li>
              <li className="hbhj">To manage user accounts.</li>
              <li className="hbhj">
                To send administrative information to you.
              </li>
              <li className="hbhj">
                To fulfil and manage your orders and your use of our Services.
              </li>
              <li className="hbhj">
                To enforce our terms, conditions, and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </li>
              <li className="hbhj">
                To respond to legal requests and prevent harm.
              </li>
              <li className="hbhj">
                For other business purposes such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns, and to evaluate and improve our Platform, products,
                services, marketing, and your experience.
              </li>
            </ul>
          </div>
          <div className="jsahbx">
            <p className="ijhygh" data-aos="fade-up">
              Data Encryption and Security
            </p>
            <p className="hbxb" data-aos="fade-up">
              We use advanced encryption methods to ensure that your data is
              securely stored and transmitted. We implement and maintain
              technical, administrative, and physical security measures designed
              to protect your personal information from unauthorised access,
              disclosure, use, and modification. However, despite our safeguards
              and efforts to secure your information, no electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorised third parties
              will not be able to defeat our security and improperly collect,
              access, steal, or modify your information.
            </p>
          </div>
          <div className="jsahbx">
            <p className="ijhygh" data-aos="fade-up">
              Sharing Your Information
            </p>
            <p className="hbxb" data-aos="fade-up">
              We may share your information with third parties in certain
              situations. These include:
            </p>
            <ul className="jku" data-aos="fade-up">
              <li className="hbhj">
                With service providers: We may share your personal information
                with service providers to monitor and analyse the use of our
                Platform, to contact you.{" "}
              </li>
              <li className="hbhj">To manage user accounts.</li>
              <li className="hbhj">
                For business transfers: We may share or transfer your personal
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
              <li className="hbhj">
                With your consent: We may disclose your personal information for
                any other purpose with your consent.
              </li>
            </ul>
          </div>
          <div className="jsahbx">
            <p className="ijhygh" data-aos="fade-up">
              Your Data Protection Rights
            </p>
            <p className="hbxb" data-aos="fade-up">
              You have the following data protection rights:
            </p>
            <ul className="jku" data-aos="fade-up">
              <li className="hbhj">
                The right to access, update, or delete the information we have
                on you.
              </li>
              <li className="hbhj">The right of rectification.</li>
              <li className="hbhj">The right to object.</li>
              <li className="hbhj">The right of restriction.</li>
              <li className="hbhj">The right to data portability..</li>
              <li className="hbhj">The right to withdraw consent.</li>
            </ul>
            <p className="hbxb" data-aos="fade-up">
              Please note that we may ask you to verify your identity before
              responding to such requests.{" "}
            </p>
          </div>
          <div className="jsahbx">
            <p className="ijhygh" data-aos="fade-up">
              Your Data Protection Rights
            </p>
            <p className="hbxb" data-aos="fade-up">
              We may update our privacy policy from time to time. We will notify
              you of any changes by posting the new privacy policy on this page
              and updating the "Last updated" date.
            </p>
            <p className="hbxb" data-aos="fade-up">
              For any questions or concerns about our privacy practices, please
              contact us at contact@tensorblue.com
            </p>
          </div>
        </div>
        <hr className="nkjdnc" data-aos="fade-up" />
        <div className="hokhu">
          <div className="jhjb">
            <h1 className="jhbj" data-aos="fade-up">
              Terms of Use for Ideaverse by Tensorblue Technologies Private
              Limited
            </h1>
            <p className="hjjkou" data-aos="fade-up">
              Agreement to Terms
            </p>
            <p className="hbxb" data-aos="fade-up">
              By using our Platform, you agree to be bound by these Terms of Use
              and our Privacy Policy. If you do not agree to these Terms, you
              may not use the Platform.
            </p>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              Platform Use
            </h1>
            <p className="hjjkou" data-aos="fade-up">
              You may use the Platform only for lawful purposes and in
              accordance with these Terms. You agree not to use the Platform:
            </p>
            <ul className="jku" data-aos="fade-up">
              <li className="hbhj">
                The right to access, update, or delete the information we have
                on you.
              </li>
              <li className="hbhj">
                In any way that violates any applicable national or
                international law or regulation.
              </li>
              <li className="hbhj">
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way.
              </li>
              <li className="hbhj">
                To transmit, or procure the sending of, any advertising or
                promotional material, including any "junk mail", "chain letter,"
                "spam," or any other similar solicitation.
              </li>
              <li className="hbhj">
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user, or any other person or entity.
              </li>
            </ul>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              How We Use Your Information
            </h1>
            <p className="hbxb" data-aos="fade-up">
              We use personal information collected via our Platform for a
              variety of business purposes described below:
            </p>
            <ul className="jku" data-aos="fade-up">
              <li className="hbhj">
                To facilitate account creation and the logon process.
              </li>
              <li className="hbhj">To manage user accounts.</li>
              <li className="hbhj">
                To send administrative information to you.
              </li>
              <li className="hbhj">
                To fulfil and manage your orders and your use of our Services.
              </li>
              <li className="hbhj">
                To respond to legal requests and prevent harm.
              </li>

              <li className="hbhj">
                For other business purposes such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns, and to evaluate and improve our Platform, products,
                services, marketing, and your experience.
              </li>
            </ul>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              {" "}
              Intellectual Property Rights
            </h1>
            <p className="hbxb" data-aos="fade-up">
              The Platform and its entire contents, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned by the Company, its licensors, or
              other providers of such material and are protected by
              international copyright, trademark, patent, trade secret, and
              other intellectual property or proprietary rights laws.
            </p>
          </div>

          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              {" "}
              Termination
            </h1>
            <p className="hbxb" data-aos="fade-up">
              We may terminate or suspend your account and bar access to the
              Platform immediately, without prior notice or liability, under our
              sole discretion, for any reason whatsoever and without limitation,
              including but not limited to a breach of the Terms.
            </p>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              Governing Law
            </h1>
            <p className="hbxb" data-aos="fade-up">
              These Terms shall be governed and construed in accordance with the
              laws of [Your Country], without regard to its conflict of law
              provisions.
            </p>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              Changes to Terms
            </h1>
            <p className="hbxb" data-aos="fade-up">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. By continuing to access or use our
              Platform after any revisions become effective, you agree to be
              bound by the revised terms. If you do not agree to the new terms,
              you are no longer authorised to use the Platform.
            </p>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              Changes to Terms
            </h1>
            <p className="hbxb" data-aos="fade-up">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. By continuing to access or use our
              Platform after any revisions become effective, you agree to be
              bound by the revised terms. If you do not agree to the new terms,
              you are no longer authorised to use the Platform.
            </p>
          </div>
          <div className="jhjb">
            <h1 className="hjjkou" data-aos="fade-up">
              Contact Us
            </h1>
            <p className="hbxb" data-aos="fade-up">
              If you have any questions about these Terms, please contact us at
              contact@tensorblue.com
            </p>
          </div>
          {/* Amir Changes begin here */}
          {/* 1. Restricted Content*/}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">1. Restricted Content</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse does not feature content or services that endanger children, strictly adhering to protections against exploitation or harm.</p>
              <p className="hbxb" data-aos="fade-up">The platform ensures all content is respectful and prohibits material that is offensive, promotes hate, violence, or is sexually explicit.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse offers financial services with clarity and transparency, fully compliant with applicable laws to guard against fraud.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse does not host or promote any real-money gambling or contests, ensuring a safe and ethical environment for all users.</p>
              <p className="hbxb" data-aos="fade-up">The platform firmly stands against promoting or facilitating illegal activities.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse actively monitors, reviews, and removes harmful or illegal user-generated content, maintaining a safe community environment.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse does not offer health-related content or services, avoiding the risks of misinformation or inappropriate health advice.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse does not provide blockchain services or content, focusing on technology and experiences outside of the blockchain spectrum.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse ensures AI-generated content is monitored to prevent misinformation, impersonation, or misleading information.</p>
            </section>

            {/* 2. Impersonation Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">2. Impersonation Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse avoids any form of impersonation with clear app titles, icons, and descriptions, ensuring users are well-informed of the app's ownership and purpose.</p>
            </section>

            {/* 3. Intellectual Property Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">3. Intellectual Property Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse respects intellectual property rights, ensuring all content is authorized or original, protecting creators and users alike.</p>
            </section>

            {/* 4. Privacy, Deception, and Device Abuse Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">4. Privacy, Deception, and Device Abuse Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse ensures the secure and private handling of user data, protecting it from unauthorized access.</p>
              <p className="hbxb" data-aos="fade-up">The app requests only necessary permissions, upholding user privacy and ensuring consent.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse protects against harmful practices to user devices or networks, maintaining a secure environment.</p>
              <p className="hbxb" data-aos="fade-up">The platform is transparent in its functionality and data collection, avoiding any deceptive practices.</p>
              <p className="hbxb" data-aos="fade-up">Ideaverse accurately represents its features and services, providing truthful information to users.</p>
            </section>
            {/* 5. Monetization and Ads Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">5. Monetization and Ads Policy</h2>
              <p className="hbxb" data-aos="fade-up">Transactions within Ideaverse are transparent and fair, using Google Play's payment systems.</p>
              <p className="hbxb" data-aos="fade-up">Ads are displayed appropriately, focusing on enhancing user experience and complying with family-targeted programs.</p>
            </section>

            {/* 6. Store Listing and Promotion Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">6. Store Listing and Promotion Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse offers accurate and clear app listings, steering clear of spammy promotions and prioritizing quality content.</p>
            </section>

            {/* 7. Spam and Minimum Functionality Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">7. Spam and Minimum Functionality Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse provides meaningful functionality and value, ensuring a high-quality user experience.</p>
            </section>

            {/* 8. Malware Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">8. Malware Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse employs stringent security measures to prevent any form of malware, prioritizing the safety of users and their data.</p>
            </section>

            {/* 9. Mobile Unwanted Software (MUwS) Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">9. Mobile Unwanted Software (MUwS) Policy</h2>
              <p className="hbxb" data-aos="fade-up">Ideaverse opposes ad fraud, system mimicry, or social engineering, creating a secure and trustworthy platform for users.</p>
            </section>

            {/* 10. Families Policy */}
            <section>
              <h2 className="hjjkou" data-aos="fade-up">10. Families Policy</h2>
              <p className="hbxb" data-aos="fade-up">For apps targeting children, Ideaverse maintains a safe and appropriate environment, following Google Play's certification for ad SDKs.</p>
            </section>
            {/* Amir Changes end here */}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPage;
