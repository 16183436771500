import React, { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners'
import apiService from '../../services/apiService';

const Result = () => {
    const location = useLocation();
    const { response, formObj } = location.state || { response: null, formObj: {} };  

    const [topic, setTopic] = useState('');
    const [lines, setLines] = useState(2);
    const [emotion, setEmotion] = useState('');
    const [literaryDevice, setLiteraryDevice] = useState('');
    const [language, setLanguage] = useState('');
    const [captions, setCaptions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const totalCaptions = 10;

    useEffect(() => {
        if (formObj) {
            setTopic(formObj.topic || '');
            setLines(formObj.lines || '');
            setEmotion(formObj.emotion || '');
            setLiteraryDevice(formObj.literaryDevice || '');
            setLanguage(formObj.language || '');
        }
        if (response && response.caption) {
            setCaptions([response.caption])
        }
    }, [formObj, response]);

    const handleNext = () => {
        if (currentIndex <= totalCaptions - 1) {
            setCurrentIndex(currentIndex + 1);
            fetchCaption(currentIndex)
        }
    };

    const handlePrev = () => {
        if (currentIndex >= 2) {
            setCurrentIndex(currentIndex - 1);
            fetchCaption(currentIndex);
        }
    };

    const fetchCaption = async (index) => {
        if (captions[index]) return;

        try {
            const response = await apiService.post('/ai/generate-caption', {
                topic,
                lines,
                emotion,
                literaryDevice,
                language,
            });

            if (response.status === 'success') {
                const newCaptions = [...captions];
                newCaptions[index] = response.caption;
                setCaptions(newCaptions);
            } else {
                throw new Error("Failed to fetch caption");
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="text-white p-4">
            <h2 className="text-lg mb-4 font-bold text-center">Caption Generator</h2>

            <div className='p-4'>
                <div className="flex flex-wrap justify-around items-center space-y-3">
                    {/* Select topic */}
                    <div className="flex flex-row justify-between lg:w-auto md:w-auto w-full">
                        <p className='text-gray-400 flex justify-center items-center w-1/2 mr-2'>Select a topic</p>
                        <select
                            className="bg-transparent text-white rounded-lg px-4 py-2 border border-gray-700 lg:w-auto md:w-auto w-1/2"
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                        >
                            <option value="nature" className='bg-gray-700 text-white'>Nature</option>
                            <option value="technology" className='bg-gray-700 text-white'>Technology</option>
                            <option value="arts" className='bg-gray-700 text-white'>Arts</option>
                            <option value="science" className='bg-gray-700 text-white'>Science</option>
                            <option value="history" className='bg-gray-700 text-white'>History</option>
                            <option value="sports" className='bg-gray-700 text-white'>Sports</option>
                        </select>
                    </div>

                    {/* Lines to generate */}
                    <div className="flex flex-row justify-between lg:w-auto md:w-auto w-full">
                        <p className='text-gray-400 flex justify-center items-center lg:w-auto md:w-auto w-1/2 mr-2'>Lines</p>
                        <select
                            className="bg-transparent text-white rounded-lg px-4 py-2 border border-gray-700 lg:w-auto md:w-auto w-1/2"
                            value={lines}
                            onChange={(e) => setLines(e.target.value)}
                        >
                            <option value={2} className='bg-gray-800 text-white'>2</option>
                            <option value={3} className='bg-gray-800 text-white'>3</option>
                            <option value={4} className='bg-gray-800 text-white'>4</option>
                        </select>
                    </div>

                    {/* Emotion */}
                    <div className="flex flex-row justify-between lg:w-auto md:w-auto w-full">
                        <p className='text-gray-400 flex justify-center items-center w-1/2 mr-2'>Emotion</p>
                        <select
                            className="bg-transparent text-white rounded-lg px-4 py-2 border border-gray-700 lg:w-auto md:w-auto w-1/2"
                            value={emotion}
                            onChange={(e) => setEmotion(e.target.value)}
                        >
                            <option value="happy" className='bg-gray-700 text-white'>Happy</option>
                            <option value="sad" className='bg-gray-700 text-white'>Sad</option>
                            <option value="excited" className='bg-gray-700 text-white'>Excited</option>
                            <option value="angry" className='bg-gray-700 text-white'>Angry</option>
                            <option value="thoughtful" className='bg-gray-700 text-white'>Thoughtful</option>
                        </select>
                    </div>

                    {/* Literary Device */}
                    <div className="flex flex-row justify-between lg:w-auto md:w-auto w-full">
                        <p className='text-gray-400 flex justify-center items-center w-1/2 mr-2'>Literary Device</p>
                        <select
                            className="bg-transparent text-white rounded-lg px-4 py-2 border border-gray-700 lg:w-auto md:w-auto w-1/2"
                            value={literaryDevice}
                            onChange={(e) => setLiteraryDevice(e.target.value)}
                        >
                            <option value="metaphor" className='bg-gray-700 text-white'>Metaphor</option>
                            <option value="simile" className='bg-gray-700 text-white'>Simile</option>
                            <option value="alliteration" className='bg-gray-700 text-white'>Alliteration</option>
                            <option value="personification" className='bg-gray-700 text-white'>Personification</option>
                            <option value="hyperbole" className='bg-gray-700 text-white'>Hyperbole</option>
                            <option value="onomatopoeia" className='bg-gray-700 text-white'>Onomatopoeia</option>
                        </select>
                    </div>

                    {/* Language */}
                    <div className="flex flex-row justify-between lg:w-auto md:w-auto w-full">
                        <p className='text-gray-400 flex justify-center items-center w-1/2 mr-2'>Language</p>
                        <select
                            className="bg-transparent text-white rounded-lg px-4 py-2 border border-gray-700 lg:w-auto md:w-auto w-1/2"
                            value={language}
                            onChange={(e) => setLanguage(e.target.value)}
                        >
                            <option value="english" className='bg-gray-700 text-white'>English</option>
                            <option value="spanish" className='bg-gray-700 text-white'>Spanish</option>
                            <option value="french" className='bg-gray-700 text-white'>French</option>
                            <option value="german" className='bg-gray-700 text-white'>German</option>
                            <option value="german" className='bg-gray-700 text-white'>Hindi</option>
                        </select>
                    </div>
                </div>

                <div className=''>
                    <div className='flex justify-center items-center mt-8'>

                        <div className='bg-gray-800 lg:py-40 py-24 px-10 rounded-lg text-center border border-white w-full md:w-1/2 lg:w-1/3 lg:h-[370px] md:h-[300px] h-[280px]'>
                            <p>
                                {
                                    captions[currentIndex - 1] ?
                                        <p>{captions[currentIndex - 1]}</p> :
                                        <BeatLoader color="#6cd97e" />
                                }
                            </p>
                        </div>

                    </div>
                    <div className="text-center mt-7 flex items-center justify-center">
                        <button
                            onClick={handlePrev}
                            className='text-white mr-4 border border-gray-400 rounded-lg p-2 hover:border-white hover:text-white'
                        >
                            <ArrowLeft size={18} className='text-gray-400 hover:text-white' />
                        </button>
                        <span>{currentIndex} of {totalCaptions}</span>
                        <button
                            onClick={handleNext}
                            className='text-white ml-4 border border-gray-400 rounded-lg p-2 hover:border-white hover:text-white'
                        >
                            <ArrowRight size={18} className='text-gray-400 hover:text-white' />
                        </button>
                    </div>

                </div>
            </div>


        </div>
    );
};

export default Result;
