import React, { useEffect, Suspense } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Header from "./components/Header";
import ProtectedRoute from "./routes/ProtectedRoute";
import { getCookie, setCookie } from "./utils/methods";
import { getCredits, getNotifications, updateFcmToken } from "./services/auth";
import Subscription from "./pages/Subscription";
import "./App.css";
import { HashLoader } from "react-spinners";
import Capabilities from "./landingPages/Capabilities";
import Blog from "./landingPages/Blog";
import BlogPage from "./landingPages/blogsPage";
import Privacy from "./landingPages/privacyPage";
import DeleteAccount from "./landingPages/deleteAccountPage";
import SaveAssets from "./socialFeature/SaveAssets";
import NotificationPage from "./socialFeature/NotificationPage";
import RegisterPopup from "./components/RegisterPopup";
import baseUrl from "./baseUrl";
import ToolsHome from "./tools/ToolsHome";
import WritingAssistant from "./tools/writingTool/WritingAssistant";
import PlanGenerator from "./tools/businessPlan/PlanGenerator";
import { ViewPost } from "./socialFeature/socialPages/ViewPost";
import Flash_card from "./tools/FlashCard";
import HexCodeGenerator from "./tools/hexCodeGenerator";
import FlashCardResult from "./tools/FlashCardResults";
import BusinessAiTool from "./tools/businessAiTool";
import InterviewAi from "./tools/InterviewAi";
import InterviewQuestion from "./tools/interviewQuestion";
import VoiceAssistant from "./pages/VoiceAssistant";
import TutorHome from "./tools/tutor/TutorHome";
import WorkoutForm from "./tools/workout/WorkoutForm";
import WorkoutList from "./tools/workout/WorkoutList";
import Study from "./tools/tutor/Study";
import CaptionForm from "./tools/caption/CaptionForm";
import Result from "./tools/caption/Result";
import LogoMaker from "./tools/logoMaker/LogoMaker";
import { MessagePage } from "./socialFeature/socialPages/MessagePage";
import VoiceAssistant2 from "./pages/VoiceAssi2.0";
import PromptLeaderboard from "./socialFeature/promptLeaderboard";
import AvatarGenerator from "./tools/AI_Tools/AvatarGenerator";
import Avatar3D from "./tools/AI_Tools/3dAvatar";
import HomeMainPage from "./components/HomeMainPage";
import GlobalAiNews from "./tools/GlobalAiNews";
import GlobalAiNewsDetail from "./tools/GlobalAiNewsDetail";
import CreateAvatar from "./pages/CreateAvatar";
import AvatarYouAi from "./components/AvatarYouAiChat";
import AIChallenge from "./pages/AIChallenge";
import AIChallengeDetail from "./pages/AIChallengeDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuth,
  setCredits,
  setIpAddress,
  setUserEmail,
  setUserId,
  setUserName,
  updateNotification,
} from "./features/userSlice";
import { InteractPost } from "./socialFeature/socialPages/InteractPost";
import Writer from "./tools/novelWriting/Writer";
import AvatarStore from "./pages/AvatarStore";
import { Explore } from "./socialFeature/Explore";
import { SearchResults } from "./socialFeature/socialPages/SearchResults";
import { generatePermission, messaging, onMessage } from "./firebase-config";
import { toast } from "react-toastify";

// Lazy load the components
const ChangePassword = React.lazy(() => import("./pages/change-password"));
const Collection = React.lazy(() => import("./pages/collection"));
const Model = React.lazy(() => import("./pages/model"));
const Payments = React.lazy(() => import("./Payments/PaymentHome"));
const NotFound = React.lazy(() => import("./pages/404"));
const VoiceAssi = React.lazy(() => import("./pages/TTSpage"));
const Main = React.lazy(() => import("./account/AccountMain"));
const HistoryStorage = React.lazy(
  () => import("./History_Components/History_main")
);
const MainChatbot = React.lazy(() => import("./chatbot/MainChatbot"));
const SocialMain = React.lazy(() => import("../src/socialFeature/SocialMain"));
const SocialProfile = React.lazy(
  () => import("../src/socialFeature/SocialProfile")
);
const TrendingPage = React.lazy(
  () => import("../src/socialFeature/TrendingPage")
);
const LandingHomePage = React.lazy(() => import("../src/landingPages/Home"));

function App() {
  const token = getCookie("token");
  const { userName, userEmail, displayRegister } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();

  const getUserCredits = async (source) => {
    const response = await getCredits(source);
    if (response?.data !== undefined) {
      dispatch(setCredits(response.data));
    }
  };
  const fetchNotifications = async () => {
    const response = await getNotifications();
    if (response && typeof response.count === "number") {
      dispatch(updateNotification(response.count));
    } else {
      console.error("Invalid count in response:", response);
    }
  };

  useEffect(() => {
    if (userName !== null && window.location.href !== "/") {
      getUserCredits();
      fetchNotifications();
    }
  }, [userName]);

  useEffect(() => {
    const shouldFetchAuth =
      !userName || (userName && userName.startsWith("guest_") && !token);

    if (!displayRegister) {
      if (shouldFetchAuth) {
        fetchAuth();
      } else {
        dispatch(setAuth(true));
      }
    }
  }, [userName, userEmail, token]);

  // GUEST USER CREATION
  const fetchIpAddress = async (signal) => {
    try {
      const ipv6Response = await fetch("https://api6.ipify.org?format=json", {
        signal,
      });
      if (ipv6Response.ok) {
        const ipv6Data = await ipv6Response.json();
        return ipv6Data.ip;
      }
    } catch (e) {
      console.log("Failed to fetch IPv6 address, trying IPv4", e);
    }
    try {
      const ipv4Response = await fetch("https://api.ipify.org?format=json", {
        signal,
      });
      if (ipv4Response.ok) {
        const ipv4Data = await ipv4Response.json();
        return ipv4Data.ip;
      }
    } catch (e) {
      console.log("Failed to fetch IPv4 address", e);
      throw new Error("Unable to fetch IP address");
    }

    throw new Error("No IP address available");
  };
  const fetchAuth = async (signal) => {
    try {
      const ip_address = await fetchIpAddress(signal);
      dispatch(setIpAddress(ip_address));

      const guestResponse = await fetch(`${baseUrl}/auth/guestAuth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ip_address }),
        signal,
      });

      const { username, token, userId, error } = await guestResponse.json();

      if (error) {
        console.log("Error with guest login", error);
        return;
      }

      dispatch(setUserName(username));
      dispatch(setUserEmail(userEmail));
      dispatch(setUserId(userId));
      dispatch(setAuth(true));
      setCookie("token", token, 7);
    } catch (error) {
      console.log("Fetch error:", error);
    }
  };

  useEffect(() => {
    const requestPermissionAndUpdateToken = async () => {
      const fcmToken = await generatePermission();
      const storedToken = localStorage.getItem("fcmToken");
      if (fcmToken !== storedToken) {
        await updateFcmToken({ fcmToken });
      }
    };
    requestPermissionAndUpdateToken();
    onMessage(messaging, (payload) => {
      if (
        payload.notification.data &&
        payload.notification.data.sender === "admin"
      ) {
        toast.info(payload.notification.title, {
          position: "bottom-left",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          style: {
            borderRadius: "8px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
        });
      } else {
        dispatch(updateNotification());
      }
    });
  }, []);

  const isHeaderVisible =
    !window.location.pathname.includes("/blog") &&
    !window.location.pathname.includes("/home") &&
    !window.location.pathname.includes("/capabilities") &&
    !window.location.pathname.includes("/privacy") &&
    !window.location.pathname.includes("/deleteAccount");

  return (
    <div className="App">
      <BrowserRouter>
        {isHeaderVisible && <Header />}

        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "75vh",
              }}
            >
              <HashLoader color="#6cd97e" size={50} />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<ProtectedRoute />}>
              <Route path="/collection" element={<Collection />} />
              <Route path="/collection/:collection" element={<Model />} />
              <Route path="/social" element={<SocialMain />} />
              <Route path="/history" element={<HistoryStorage />} />
              <Route path="/aichat/avatar" element={<MainChatbot />} />
              <Route path="/aichat/youai" element={<MainChatbot />} />
              <Route path="/aichat/aichallenge" element={<MainChatbot />} />
              <Route
                path="/aichat/avatar/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route
                path="/aichat/youai/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route
                path="/aichat/aichallenge/:prevSessionId"
                element={<MainChatbot />}
              />
              <Route path="/social_profile" element={<SocialProfile />} />
              <Route path="/saveAssets" element={<SaveAssets />} />
              <Route path="/notification" element={<NotificationPage />} />
              <Route path="/profile" element={<Main />} />
              <Route path="/subscription" element={<Payments />} />
              <Route path="/payment" element={<Payments />} />
              <Route path="/registerPopup" element={<RegisterPopup />} />
              <Route path="/VoiceAssi" element={<VoiceAssi />} />
              <Route
                path="/social/interact/:postId"
                element={<InteractPost />}
              />
              <Route path="/social/explore" element={<Explore />} />
              <Route path="/social/search" element={<SearchResults />} />
              <Route path="/view-post" element={<ViewPost />} />
              <Route
                path="/promptLeaderboard"
                element={<PromptLeaderboard />}
              />
              <Route path="/" element={<HomeMainPage />} />
              <Route
                path="/globalAiNewsDetail/:id"
                element={<GlobalAiNewsDetail />}
              />
              <Route path="/globalAiNews" element={<GlobalAiNews />} />
              <Route path="/createAvatar" element={<CreateAvatar />} />
              <Route path="/avatarStore" element={<AvatarStore />} />
              <Route
                path="/aiChallengeDetail"
                element={<AIChallengeDetail />}
              />
              <Route path="/aiChallenge" element={<AIChallenge />} />

              <Route path="/avatarYouAi/:id" element={<AvatarYouAi />} />
              <Route
                path="/avatarYouAi/:id/:sessionId"
                element={<AvatarYouAi />}
              />
              <Route path="/avatarGenerator" element={<AvatarGenerator />} />
              <Route path="/avatar3d" element={<Avatar3D />} />
              <Route path="/toptrends/:tag" element={<TrendingPage />} />
              <Route
                path="/social_profile/:username"
                element={<SocialProfile />}
              />
              <Route path="/messaging" element={<MessagePage />} />
              <Route path="/profile" element={<Main />} />
              <Route path="/MainChatbot" element={<MainChatbot />} />
              <Route path="/collection/:collection" element={<Model />} />
              <Route exact path="/payment" element={<Payments />} />
              <Route path="/VoiceAssi" element={<VoiceAssi />} />
              {/* ######################### AI TOOLS #################### */}
              {/* <Route path="/tools" element={<ToolsHome />} />  */}
              <Route path="/tools" element={<ToolsHome />} />
              {/* Paraphraser */}
              <Route
                path="/tools/writing-assistant"
                element={<WritingAssistant />}
              />
              {/* Novel assistant */}
              <Route path="/tools/novel-writer" element={<Writer />} />
              {/* Business plan generator */}
              <Route path="/tools/business-plan" element={<PlanGenerator />} />
              {/* Tutor AI */}
              <Route path="/tools/tutor" element={<TutorHome />} />
              <Route path="/tools/tutor-study" element={<Study />} />
              {/* Workout plan generator */}
              <Route path="/tools/workout" element={<WorkoutForm />} />
              <Route path="/tools/workout-list" element={<WorkoutList />} />
              <Route path="/tools/flash_card" element={<Flash_card />} />
              <Route
                path="/tools/flash_card_results"
                element={<FlashCardResult />}
              />
              <Route
                path="/tools/hex_code_generator"
                element={<HexCodeGenerator />}
              />
              <Route
                path="/tools/business_ai_tool"
                element={<BusinessAiTool />}
              />
              {/* Caption generator */}
              <Route path="/tools/caption" element={<CaptionForm />} />
              <Route path="/tools/caption-result" element={<Result />} />
              {/* Logo maker */}
              <Route path="/tools/logo" element={<LogoMaker />} />
              <Route path="/voice_assistant" element={<VoiceAssistant />} />
              <Route path="/voice2" element={<VoiceAssistant2 />} />
              <Route path="/tools/interviewAi" element={<InterviewAi />} />
              <Route
                path="/tools/interviewQuestion"
                element={<InterviewQuestion />}
              />
            </Route>
            <Route path="/collection" element={<Collection />} />
            <Route
              path="/change-password/:reset_token?"
              element={<ChangePassword />}
            />
            <Route path="/*" element={<NotFound />} />
            <Route path="/Home" element={<LandingHomePage />} />
            <Route path="/capabilities" element={<Capabilities />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blogPage/:id" element={<BlogPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/deleteAccount" element={<DeleteAccount />} />
            <Route path="/404" element={<NotFound />} />
            {/* 
              Don't know we need to keep this feature or not
              <Route path="/DashBoard" element={<DashBoard />} />
              <Route path="/myspace" element={<MySpace />} />
              <Route path="/dailyrecap" element={<Recap />} />
              <Route path="/goals" element={<DailyGoals />} />
              <Route path="/dailyrecord" element={<DailyRecord />} />
              
            */}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
