import React, { useState, useEffect } from "react";
import { MainScreen } from "./MessageComponents/MainScreen";
import { LeftPanel } from "./MessageComponents/LeftPanel";
import { useSelector } from "react-redux";

export const MessagePage = () => {
  const selectedUser = useSelector((state) => state.socialChat.selectedChat);
  const isUserSelected = Object.keys(selectedUser).length > 0;
  const [isScreenWidthLessThanMd, setIsScreenWidthLessThanMd] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenWidthLessThanMd(window.innerWidth < 768);
    };

    // Check the screen width on initial render
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex w-full flex-col gap-2 h-[86vh] p-2">
      <div className="w-full flex justify-start gap-[2rem]">
        <div
          className={` ${isUserSelected ? "hidden " : "flex w-full"} md:w-[20%] md:flex flex-col px-4 py-1 gap-4 `}
        >
          <div className="w-full justify-start items-center px-4 py-2 text-[#CDCDCD] text-h4 font-semibold">
            Messages
          </div>
          <div className="flex bg-[#1B2333] rounded-3xl px-4 py-2  text-[#3d4247]">
            <input
              type="text"
              className="bg-transparent w-full px-2 outline-none text-sm text-[#CDCDCD]"
              placeholder="Search"
            />
          </div>
          <LeftPanel />
        </div>
        {isScreenWidthLessThanMd && isUserSelected && <MainScreen />}
        {!isScreenWidthLessThanMd && (
          <>
            <div className="hidden md:block h-[80vh] mt-4 w-[1px] bg-[#3d4247]"></div>
            <MainScreen />
          </>
        )}
      </div>
    </div>
  );
};
