import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import apiService from '../../services/apiService';

const loaderContainerStyle = {
    minHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '40px',
};

const WritingGenerator = ({ tone }) => {
    const [text, setText] = useState('');
    const [paraphrasedText, setParaphrasedText] = useState('');
    const [isParaphrasing, setIsParaphrasing] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const handleGeneratewithAIClick = async () => {
        setIsGenerating(true);
        try {
            const body = { 
                text: ' ', //Empty text
                tone,
                flag: 'assistant'
            };
            
            const data = await apiService.post('/ai/generate-writing', body);

            if (data.status === 'success') {
                setText(data.response);
                setParaphrasedText('');
            } else {
                setText('Error occurred during text generation with AI.');
            }
        } catch (error) {
            alert('Failed to generate text.');
        }
        setIsGenerating(false);
    };

    const handleParaphraseClick = async () => {
        if (!text.trim()) {
            alert('Please enter some text to paraphrase.');
            return;
        }

        setIsParaphrasing(true);
        try {
            const body = {
                text, 
                tone,
                flag: 'assistant',
            }
            const data = await apiService.post('/ai/generate-writing', body);

            if (data.status === 'success') {
                setParaphrasedText(data.response);
            } else {
                setParaphrasedText('Error occurred.');
            }
        } catch (error) {
            setParaphrasedText('Failed to paraphrase.');
            console.error('Paraphrase error:', error);
        }
        setIsParaphrasing(false);
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
        // Reset paraphrased text if the user edits the original text
        if (paraphrasedText) {
            setParaphrasedText('');
        }
    };

    return (
        <div className="flex lg:flex-row md:flex-row flex-col gap-4 text-white font-helvetica">
            <div className="flex flex-col w-full lg:w-1/2 px-4 py-2 bg-[#252e41] rounded-lg border border-white" style={{ minHeight: '250px' }}>
                <div style={{ minHeight: '160px', position: 'relative' }}>
                    {isGenerating && (
                        <div style={loaderContainerStyle}>
                            <BeatLoader colxor={"#37c640"} />
                        </div>
                    )}
                    <textarea
                        className={`w-full lg:h-full md:h-full h-[100px] p-4 text-white bg-[#252e41] focus:outline-none mb-4 ${isGenerating ? 'opacity-50' : ''}`}
                        placeholder="Enter text to paraphrase here or generate with AI..."
                        value={text}
                        onChange={handleTextChange}
                        disabled={isGenerating}
                    />
                </div>
                <div className="flex justify-center gap-10 mt-5">
                    <button
                        className="p-2 bg-green-500 hover:bg-green-700 border-2 border-white rounded text-white"
                        onClick={handleGeneratewithAIClick}
                        disabled={isParaphrasing}
                    >
                        Generate with AI
                    </button>
                    <button
                        className="p-2 bg-green-500 hover:bg-green-700 border-2 border-white rounded text-white"
                        onClick={handleParaphraseClick}
                        disabled={isGenerating}
                    >
                        Paraphrase
                    </button>
                </div>
            </div>
            <div className="w-full lg:w-1/2 px-4 py-2 bg-[#252e41] rounded-lg border border-white">
                <div className="w-full h-full px-4 py-6 overflow-auto">
                    {isParaphrasing ? (
                        <div className="flex justify-center items-center h-full">
                            <BeatLoader color={"#37c640"} />
                        </div>
                    ) : (
                        <div>
                            {paraphrasedText ? (
                                <p className='text-white'>{paraphrasedText}</p>
                            ) : (
                                <p className='text-gray-400'>Your paraphrased text will appear here...</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default WritingGenerator;
