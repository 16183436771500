import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

const CaptionForm = () => {
  const [topic, setTopic] = useState('');
  const [lines, setLines] = useState('');
  const [emotion, setEmotion] = useState('');
  const [literaryDevice, setLiteraryDevice] = useState('');
  const [language, setLanguage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!topic || !lines || !emotion || !literaryDevice || !language) {
      toast.error("Please fill out all fields.");
      setLoading(false);
      return;
    }

    try {
      const formObj = { topic, lines, emotion, literaryDevice, language };
      console.log("Obj: ",formObj);

      const response = await apiService.post('/ai/generate-caption', formObj);

      if (response.status === 'success') {
        navigate('/tools/caption-result', { state: { response, formObj } });
        console.log("Response: ",response);
      } else {
        throw new Error("Unknown error occurred");
      }

    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6">
      <div className="rounded-lg shadow-lg p-8 text-white w-full max-w-lg lg:max-w-xl">
        <h1 className="text-lg text-center font-bold text-white mb-4">Caption Generator</h1>
        <p className="text-center text-sm mb-6">Answer the questions below to get started</p>

        {loading ? (
          <div className='flex justify-center items-center'>
            <BeatLoader color={"#6cd97e"} loading={loading} size={20} />
          </div>
        ) : (
          <form className="w-full">
            {/* Select topic */}
            <div className="mb-4">
              <label className="block text-white text-sm mb-2">
                Select a topic
              </label>
              <select
                className="block w-full border border-gray-200 bg-transparent text-white py-3 px-4 pr-8 rounded-lg leading-tight focus:outline-none"
                value={topic}
                onChange={e => setTopic(e.target.value)}
              >
                <option value="" className='bg-gray-700 text-white'>Choose...</option>
                <option value="nature" className='bg-gray-700 text-white'>Nature</option>
                <option value="technology" className='bg-gray-700 text-white'>Technology</option>
                <option value="arts" className='bg-gray-700 text-white'>Arts</option>
                <option value="science" className='bg-gray-700 text-white'>Science</option>
                <option value="history" className='bg-gray-700 text-white'>History</option>
                <option value="sports" className='bg-gray-700 text-white'>Sports</option>
              </select>
            </div>

            {/* Lines for generation */}
            <div className="mb-4">
              <label className="block text-white text-sm mb-2">
                How many lines do you want to generate?
              </label>
              <select
                className="block w-full border border-gray-200 bg-transparent text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none"
                value={lines}
                onChange={e => setLines(e.target.value)}
              >
                <option value="" className='bg-gray-700 text-white'>Select number</option>
                <option value="2" className='bg-gray-700 text-white'>2</option>
                <option value="3" className='bg-gray-700 text-white'>3</option>
                <option value="4" className='bg-gray-700 text-white'>4</option>
              </select>
            </div>

            {/* Emotion */}
            <div className="mb-4">
              <label className="block text-white text-sm mb-2">
                Select an emotion
              </label>
              <select
                className="block w-full border border-gray-200 bg-transparent text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none"
                value={emotion}
                onChange={e => setEmotion(e.target.value)}
              >
                <option value="" className='bg-gray-700 text-white'>Choose...</option>
                <option value="happy" className='bg-gray-700 text-white'>Happy</option>
                <option value="sad" className='bg-gray-700 text-white'>Sad</option>
                <option value="excited" className='bg-gray-700 text-white'>Excited</option>
                <option value="angry" className='bg-gray-700 text-white'>Angry</option>
                <option value="thoughtful" className='bg-gray-700 text-white'>Thoughtful</option>
              </select>
            </div>

            <div className="flex gap-6 justify-between">
              {/* Literary Device */}
              <div className="flex-1 mb-4">
                <label className="block text-white text-sm mb-2">
                  Literary Device
                </label>
                <select
                  className="block w-full border border-gray-200 bg-transparent text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none"
                  value={literaryDevice}
                  onChange={e => setLiteraryDevice(e.target.value)}
                >
                  <option value="" className='bg-gray-700 text-white'>Choose...</option>
                  <option value="metaphor" className='bg-gray-700 text-white'>Metaphor</option>
                  <option value="simile" className='bg-gray-700 text-white'>Simile</option>
                  <option value="alliteration" className='bg-gray-700 text-white'>Alliteration</option>
                  <option value="personification" className='bg-gray-700 text-white'>Personification</option>
                  <option value="hyperbole" className='bg-gray-700 text-white'>Hyperbole</option>
                  <option value="onomatopoeia" className='bg-gray-700 text-white'>Onomatopoeia</option>
                </select>
              </div>

              {/* Language */}
              <div className="flex-1 mb-4">
                <label className="block text-white text-sm mb-2">
                  Language
                </label>
                <select
                  className="block w-full border border-gray-200 bg-transparent text-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500"
                  value={language}
                  onChange={e => setLanguage(e.target.value)}
                >
                  <option value="" className='bg-gray-700 text-white'>Choose...</option>
                  <option value="english" className='bg-gray-700 text-white'>English</option>
                  <option value="spanish" className='bg-gray-700 text-white'>Spanish</option>
                  <option value="french" className='bg-gray-700 text-white'>French</option>
                  <option value="german" className='bg-gray-700 text-white'>German</option>
                  <option value="german" className='bg-gray-700 text-white'>Hindi</option>
                </select>
              </div>
            </div>

            {/* Submit */}
            <div
              className="flex items-center justify-center mt-6 w-full bg-[#6cd97e] hover:bg-[#4fd565] py-3 px-1 rounded-full text-gray-900 font-bold cursor-pointer"
              onClick={handleSubmit}
            >

              <button
                className=""
                type="button"
              >
                Next
              </button>
            </div>
          </form>
        )}



        <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
};

export default CaptionForm;
