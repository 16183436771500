import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import SyntaxHighlighter from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from "react-copy-to-clipboard";
import { LuCopy } from "react-icons/lu";
import logo_imgs from "../../assets/mainLogo-transformed.webp";
import { PostFooter } from "../Components/PostFooter";
import { MdDelete } from "react-icons/md";

export const PostWidgetConversation = ({
  post,
  index,
  saved = false,
  inProfile,
  onDelete,
}) => {
  const username = useSelector((state) => state.user.userName);
  const hash = CryptoJS.MD5(username).toString();
  const hash2 = post?.username ? CryptoJS.MD5(post.username) : null;

  useEffect(() => {
    console.log("post: ", post.conversation);
  }, []);

  function formatRelative(date, baseDate) {
    const diffInMs = baseDate - date;
    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
      return `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    }
  }

  function formatChatResponse(response) {
    if (!response) {
      return "";
    }

    const boldTextRegex = /\*\*(.*?)\*\*/g;
    response = response.replace(boldTextRegex, "<strong>$1</strong>");

    const codeBlockRegex = /```([\s\S]*?)```/g;
    let match;
    let lastIndex = 0;
    const contentBlocks = [];

    while ((match = codeBlockRegex.exec(response)) !== null) {
      const codeBlockStart = match.index;
      const codeBlockEnd = codeBlockStart + match[0].length;
      const nonCodeBlockText = response.substring(lastIndex, codeBlockStart);

      if (nonCodeBlockText.trim().length > 0) {
        const paragraphs = nonCodeBlockText.split(/\n\s*\n/);
        const mergedParagraphs = paragraphs
          .filter((p) => p.trim().length > 0)
          .join("<br><br>");
        if (mergedParagraphs.trim().length > 0) {
          contentBlocks.push(
            <p
              key={contentBlocks.length}
              className=" pl-[3rem] text-base font-normal"
              dangerouslySetInnerHTML={{ __html: mergedParagraphs }}
            />
          );
        }
      }

      contentBlocks.push(
        <div
          key={contentBlocks.length}
          className="code-block-container1"
          style={{
            maxWidth: "475px",
            marginLeft: "49px",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <CopyToClipboard text={match[1]}>
            <span className="copy_icon3">
              <LuCopy className="kklp" /> Copy Code
            </span>
          </CopyToClipboard>
          <SyntaxHighlighter language="python" style={prism}>
            {match[1]}
          </SyntaxHighlighter>
        </div>
      );

      lastIndex = codeBlockEnd;
    }

    const remainingText = response.substring(lastIndex);
    if (remainingText.trim().length > 0) {
      const paragraphs = remainingText.split(/\n\s*\n/);
      const formattedParagraphs = paragraphs
        .map((paragraph) => {
          return paragraph
            .split("\n")
            .map((line, index) =>
              line.trim().startsWith("-") && index > 0
                ? `- ${line.trim()}`
                : line.trim()
            )
            .join("<br>");
        })
        .join("<br><br>");

      if (formattedParagraphs.trim().length > 0) {
        contentBlocks.push(
          <p
            key={contentBlocks.length}
            className=" pl-[3rem] text-base font-normal"
            dangerouslySetInnerHTML={{ __html: formattedParagraphs }}
          />
        );
      }
    }

    return <div>{contentBlocks}</div>;
  }

  return (
    <div className="mt-[10px]">
      <div className="post_displaying">
        <div className="post_person">
          <div className="post_img_name">
            {post.profile?.imageUrl ? (
              <img
                src={post.profile.imageUrl}
                alt="boy"
                className="post_own_profile"
              />
            ) : (
              <Gravatar
                email={post.username}
                size={150}
                default="identicon"
                className="user_frien3"
                hash={hash}
              />
            )}

            <div className="name_time">
              <Link to={`/social_profile/${post.username}`}>
                <p className="name_postig">{post.username}</p>
              </Link>{" "}
              <p className="time_post">
                {post.createdAt
                  ? formatRelative(new Date(post.createdAt), new Date())
                  : "now"}
              </p>
            </div>
          </div>
          <div className="flex gap-2 justify-center items-center">
            {inProfile && (
              <MdDelete
                onClick={onDelete}
                size={"1.5rem"}
                cursor={"pointer"}
                color="rgb(107 114 128)"
              />
            )}
          </div>
        </div>
        <p className="posttweet mb-4">{post.caption}</p>

        <div className="w-full md:w-[95%] bg-[#0d1525] max-h-[58vh] overflow-y-auto md:p-4 rounded-lg mx-auto">
          {post.conversation?.map((chat, id) => (
            <div className=" h-auto gap-1 text-white" key={id}>
              <div className="p-2 px-4 flex flex-col justify-start items-start">
                <div className="flex justify-start items-center gap-4">
                  {post?.profile?.imageUrl ? (
                    <img
                      src={post.profile.imageUrl}
                      className="user_frien3"
                      style={{ width: "34px" }}
                    />
                  ) : (
                    <Gravatar
                      email={post?.username}
                      size={150}
                      default="identicon"
                      className="user_frien3"
                      style={{ width: "2rem", height: "2rem" }}
                      hash={hash2}
                    />
                  )}

                  <h3 className=" text-[18px] font-bold ">{post.username}</h3>
                </div>
                <div>
                  <p className=" pl-[3rem] text-base font-normal">
                    {chat.prompt}
                  </p>
                </div>
              </div>
              <div className="p-2 px-4 flex flex-col justify-start items-start">
                <div className="flex justify-start items-center gap-1">
                  <div className="w-[2.5rem] h-[2rem] pl-[-0.5rem] rounded-full object-cover">
                    <img src={logo_imgs} alt="logo" className="h-full w-full" />
                  </div>

                  <h3 className=" text-[18px] font-bold ">YouAI</h3>
                </div>
                <div>{formatChatResponse(chat.response)}</div>
                {chat.mediaUrls &&
                  chat.mediaUrls.length > 0 &&
                  (chat.mediaUrls[0]?.type === "image" ? (
                    <img
                      src={chat.mediaUrls[0]?.url}
                      alt={chat.mediaUrls[0]?.type}
                    />
                  ) : chat.mediaUrls[0]?.type === "video" ? (
                    <video
                      className="video-player"
                      src={chat.mediaUrls[0]?.url}
                      alt={"video"}
                      controls
                    />
                  ) : (
                    <audio src={chat.mediaUrls[0]?.url} alt="audio" controls />
                  ))}
              </div>
            </div>
          ))}
        </div>
        <PostFooter post={post} index={index} />
      </div>
    </div>
  );
};
