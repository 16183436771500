import { createSlice } from "@reduxjs/toolkit";

const aiChallengeSlice = createSlice({
  name: "aichallenge",
  initialState: {
    challengeId: null,
  },
  reducers: {
    setChallengeId: (state, action) => {
      state.challengeId = action.payload;
    },
    clearChallengeId: (state) => {
      state.challengeId = null;
    },
  },
});

// Export the correct actions from aiChallengeSlice
export const { setChallengeId, clearChallengeId } = aiChallengeSlice.actions;

export default aiChallengeSlice.reducer;
