import React, { useState, useEffect } from "react";
import "../styles/popup.css";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import Input from "../components/common/Input";
import { register, login } from "../services/auth";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import baseUrl from "../baseUrl";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserEmail,
  setUserName,
  setAuth,
  setUserId,
  setDisplayRegister,
  setImageUrl,
} from "../features/userSlice";
import { LoginComponent } from "./LoginComponent";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const RegisterLoginPopup = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    email: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [showGoogleAuth, setShowGoogleAuth] = useState(true);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { userName, displayRegister } = useSelector((state) => state.user);

  useEffect(() => {
    if (!userName || displayRegister) {
      setHasRegistered(false);
      return;
    }
    if (pathname !== "/social") {
      setHasRegistered(true);
      return;
    }
    if (userName.startsWith("guest_")) {
      setHasRegistered(false);
    }
    setHasRegistered(false);
  }, [userName, location, setHasRegistered]);

  async function checkEmailExists(email) {
    try {
      const response = await axios.get(`${baseUrl}/auth/checkUserEmail`, {
        params: { email },
      });
      return response.data;
    } catch (error) {
      console.error("Error checking email:", error);
      throw error;
    }
  }

  const handleGoogleLoginSuccess = async (response) => {
    const tokens = response.credential.split(".");
    const user = JSON.parse(atob(tokens[1]));
    const checkEmailResponse = await checkEmailExists(user.email);
    if (!checkEmailResponse.exists) {
      setShowGoogleAuth(false);
      setShowLoginForm(true);
      setCredentials({ email: user.email });
    } else {
      setCredentials({
        username: checkEmailResponse.username,
        password: checkEmailResponse.password,
      });
      const loginResponse = await login({
        username: checkEmailResponse.username,
        password: checkEmailResponse.password,
      });
      dispatch(setUserName(checkEmailResponse.username));
      dispatch(setUserEmail(checkEmailResponse.email));
      dispatch(setUserId(loginResponse.id));
      dispatch(setAuth(true));
      dispatch(setDisplayRegister(false));
      dispatch(setImageUrl(loginResponse.imageUrl));
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    if (
      !credentials.username ||
      !credentials.email ||
      !credentials.password ||
      !credentials.confirmPassword
    ) {
      setErrorMsg("All fields are required.");
      return;
    }

    if (credentials.password !== credentials.confirmPassword) {
      setErrorMsg("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const response = await register(credentials);
      if (response && response.error) {
        setErrorMsg(response.error);
      } else {
        setErrorMsg("");
        dispatch(setUserName(credentials.username));
        dispatch(setUserEmail(credentials.email));
        dispatch(setAuth(true));
        // dispatch(setDisplayRegister(false));
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMsg("An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setCredentials((prevCreds) => ({
      ...prevCreds,
      [e.target.name]: e.target.value,
    }));
  };

  const GoogleLoginComponent = () => {
    return (
      <div>
        <h1 className="loginregister">Login or Register</h1>
        <div className="kjdsh">
          <button className="ljkn">
            <GoogleOAuthProvider
              className="bhcbmshn"
              href="#!"
              role="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              style={{ width: "100%", maxWidth: "700px" }}
            >
              <GoogleLogin
                client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                scope={"profile email"}
                onSuccess={handleGoogleLoginSuccess}
                onFailure={(error) => {
                  console.error("Google Login Failure:", error);
                }}
              />
            </GoogleOAuthProvider>
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="popup-backdrop"></div>
      <div className="popup-container">
        <div className="popup">
          {showGoogleAuth && (
            <>
              <GoogleLoginComponent />
              <hr className="hrlines" />
              <LoginComponent />
            </>
          )}

          {showLoginForm && (
            <>
              <h2 className="reegistehg">Register</h2>
              {credentials.email && (
                <div className="emaillkp">
                  <p className="email-display">
                    {credentials.email.length > 30
                      ? `${credentials.email.substring(0, 27)}...`
                      : credentials.email}
                  </p>
                </div>
              )}

              <form className="hjjwej" onSubmit={handleRegisterSubmit}>
                <Input
                  name="username"
                  type="text"
                  placeholder="Enter Username"
                  onChange={handleChange}
                  required
                  className="inputBar1"
                />
                <div className="floeuy">
                  <Input
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={handleChange}
                    required
                    className="inputBar1"
                  />
                  <div onClick={togglePasswordVisibility}>
                    {passwordShown ? (
                      <EyeOffIcon className="eyeIcpn" />
                    ) : (
                      <EyeIcon className="eyeIcpn" />
                    )}
                  </div>
                </div>
                <div className="floeuy">
                  <Input
                    name="confirmPassword"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    required
                    className="inputBar1"
                  />
                  <div onClick={togglePasswordVisibility}>
                    {passwordShown ? (
                      <EyeOffIcon className="eyeIcpn" />
                    ) : (
                      <EyeIcon className="eyeIcpn" />
                    )}
                  </div>
                </div>
                {errorMsg && <p className="error-message">{errorMsg}</p>}
                <button type="submit" className="buttonkjk">
                  {loading ? (
                    <div className="sdhbc">
                      <DotLoader
                        color={"#fff"}
                        loading={loading}
                        css={override}
                        size={20}
                      />
                    </div>
                  ) : (
                    <span>Register</span>
                  )}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterLoginPopup;
